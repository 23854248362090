import { ReactElement } from 'react';
import { formatDateTime } from '@localstack/services';
import { DataGrid } from '@mui/x-data-grid';
import { Pipeline } from '@localstack/types';
import { Link } from '@mui/material';

import { PipelineQueryResult } from '../types';

import { PipelineNameToggle } from './PipelineNameToggle';
import { StatusLabel } from './StatusLabel';

export interface CurrentlyFailingPipelinesTableProps {
  pipelines: Record<string, Pipeline>;
  currentlyFailingPipelines: PipelineQueryResult[];
  onShowSession?: (projectId: string, pipelineId: string, sessionId: string) => unknown;
}

export const CurrentlyFailingPipelinesTable = (
  { pipelines, currentlyFailingPipelines, onShowSession }: CurrentlyFailingPipelinesTableProps,
): ReactElement => (
  <DataGrid
    autoHeight
    density="compact"
    columns={[
      {
        field: 'pipeline_id',
        headerName: 'Pipeline',
        minWidth: 300,
        renderCell: ({ value }) => (
          <PipelineNameToggle
            pipelineId={value}
            pipelineName={pipelines[value]?.name}
          />
        ),
      },
      {
        field: 'latest_session_id',
        headerName: 'Session',
        minWidth: 300,
        renderCell: ({ value, row }) => (
          <Link
            href="#"
            onClick={(e) => {e.preventDefault(); onShowSession?.(row.project_id, row.pipeline_id, value);}}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'latest_session_time_start',
        headerName: 'Started At',
        minWidth: 200,
        valueFormatter: (params) => formatDateTime(params.value as number),
      },
      {
        field: 'pipeline_state',
        headerName: 'Status',
        minWidth: 100,
        renderCell: ({ value }) => (
          <StatusLabel status={value} />
        ),
      },
    ]}
    rows={currentlyFailingPipelines ?? []}
    getRowId={(row) => `${row.pipeline_id}-${row.latest_session_id}`}
  />
);
