import { ReactElement, useState } from 'react';
import { CodeSnippetViewer } from '@localstack/ui';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ProjectsService, useApiGetter } from '@localstack/services';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import { SessionLayout } from '../layouts';
import { SessionsLogQueryResult } from '../types';
import { LevelLabel } from '../components';

import { StepLogsContainer } from './StepLogsContainer';

export interface StepOverviewContainerProps {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  stepId: string;
  onClose?: () => unknown;
}

export const StepDetailedLogsContainer = ({
  projectId,
  pipelineId,
  sessionId,
  stepId,
}: StepOverviewContainerProps): ReactElement => {
  const [selectedLog, setSelectedLog] = useState<SessionsLogQueryResult | null>(null);

  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <SessionLayout
      details={
        selectedLog && (
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Log Details
              </AccordionSummary>
              <AccordionDetails>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Level
                      </TableCell>
                      <TableCell>
                        <LevelLabel level={selectedLog.level} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Time
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedLog.time}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Origin
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedLog.origin}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <CodeSnippetViewer disableParsing data={selectedLog.message} />
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      }
    >
      <StepLogsContainer
        pipelineId={pipelineId}
        sessionId={sessionId}
        stepId={stepId}
        tinybirdToken={tinybirdToken}
        onSelect={(log) => setSelectedLog(log)}
      />
    </SessionLayout>
  );
};
