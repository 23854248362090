import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  EventBridgePipesTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { EventBridgeProps } from './types';

export const EventBridgePipes = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: pipes, isLoading, mutate } = useAwsGetter('Pipes', 'listPipes', [], { clientOverrides });

  const { deletePipe } = useAwsEffect(
    'Pipes',
    ['deletePipe'],
    { revalidate: ['listPipes'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Name) => deletePipe({ Name }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="EventBridge: Pipes"
      title={
        <PageTitle
          title="EventBridge Pipes"
          onMutate={mutate}
          breadcrumbs={[
            ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
            ['Pipes', null],
          ]}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_PIPES_CREATE)}>
            Create Pipe
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Pipe(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Pipe(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <EventBridgePipesTable
          pipes={pipes?.Pipes ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewPipe={({ Name }) => goto(routes.RESOURCES_EVENT_BRIDGE_PIPE, { pipeName: Name })}
        />
      </Card>
    </Layout>
  );
};
