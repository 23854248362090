import { useState, ReactElement } from 'react';
import { Pipeline } from '@localstack/types';
import { ProjectsService, useApiEffect, useApiGetter } from '@localstack/services';
import { CardActions, CardContent, CardHeader, Skeleton } from '@mui/material';
import { ConfirmableButton, ProgressButton } from '@localstack/ui';

import { PipelineForm } from '../components';

export interface PipelineEditorProps {
  projectId: string;
  pipelineId?: string;
  onPipelineDeleted?: () => void;
  onPipelineCreated?: (pipeline: Pipeline) => void;
}

export const LoadingPipelineEditor = (): ReactElement => (
  <>
    <CardHeader title="Pipeline Details" />
    <CardContent>
      <Skeleton variant="rectangular" height={150} />
    </CardContent>
  </>
);

export const PipelineEditor = ({
  projectId,
  pipelineId,
  onPipelineDeleted,
  onPipelineCreated,
}: PipelineEditorProps): ReactElement => {
  const [isValid, setIsValid] = useState(false);

  const { data: pipeline } = useApiGetter(
    ProjectsService,
    'getPipeline',
    [projectId, pipelineId],
    { enable: !!pipelineId, swrOverrides: { suspense: !!pipelineId } },
  );

  const { createPipeline, updatePipeline, deletePipeline, isLoading: isMutating } = useApiEffect(
    ProjectsService,
    ['createPipeline', 'updatePipeline', 'deletePipeline'],
    { revalidate: ['listPipelines', 'listProjects'] },
  );

  const onCreatePipeline = async (projId: string, data: Pipeline) => {
    const createdPipeline = await createPipeline(projId, data);
    onPipelineCreated?.(createdPipeline);
  };

  return (
    <>
      <CardHeader title="Pipeline Details" />
      <CardContent>
        <PipelineForm
          formId="pipeline-form"
          projectId={projectId}
          pipeline={pipeline}
          onSubmitCreate={onCreatePipeline}
          onSubmitUpdate={updatePipeline}
          onFormValidationChange={setIsValid}
        />
      </CardContent>
      <CardActions>
        {pipeline && (
          <ConfirmableButton
            componentType="Button"
            title="Are you sure you want to delete the pipeline?"
            text="Are you sure you want to delete the pipeline?"
            disabled={isMutating}
            onClick={async () => {
              await deletePipeline(projectId, pipeline.id);
              onPipelineDeleted?.();
            }}
          >
            Delete Pipeline
          </ConfirmableButton>
        )}
        <ProgressButton
          type='submit'
          variant='contained'
          color='primary'
          form="pipeline-form"
          loading={isMutating}
          disabled={!isValid || isMutating}
        >
          Save
        </ProgressButton>
      </CardActions>
    </>
  );
};
