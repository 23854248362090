import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { UsageLimit } from '@localstack/ui';
import { Pipeline } from '@localstack/types';

import { PipelineStabilityQueryResult } from '../types';

export interface PipelinesStabilityProps {
  pipelines: Record<string, Pipeline>;
  pipelinesStability: PipelineStabilityQueryResult[];
}

export const PipelinesStability = (
  { pipelines, pipelinesStability }: PipelinesStabilityProps,
): ReactElement => (
  <>
    {(pipelinesStability ?? []).map((stability) => (
      <Box key={stability.pipeline_id}>
        <Typography variant="caption">
          {pipelines[stability.pipeline_id]?.name || stability.pipeline_id}
        </Typography>
        <UsageLimit
          reverse
          value={stability.stability_rate * 100}
          displayValue={`${(stability.stability_rate * 100).toFixed(2)}%`}
          greenRange={[80, 100]}
          orangeRange={[50, 80]}
          redRange={[0, 50]}
        />
      </Box>
    ))}
  </>
);
