import { ReactElement, Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Project as ProjectType } from '@localstack/types';
import { useRoutes } from '@localstack/services';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { ErrorBoundary } from './components';

import {
  ProjectStatisticsContainer,
  LoadingProjectStatisticsContainer,
} from './containers';

export const Project = (): ReactElement => {
  const { goto } = useRoutes();

  const { projectId } = useParams<'projectId'>() as { projectId: string };
  const [project, setProject] = useState<ProjectType | null>(null);

  // TODO: merge active session and latest
  return (
    <CustomerLayout fullScreen title={`Project ${project?.name ?? ''} Overview`}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingProjectStatisticsContainer />}>
          <ProjectStatisticsContainer
            projectId={projectId}
            onProjectReady={setProject}
            onShowPipeline={(projectId_, pipelineId) => goto(AppRoute.PIPELINE, { projectId: projectId_, pipelineId })}
          />
        </Suspense>
      </ErrorBoundary>
    </CustomerLayout>
  );
};
