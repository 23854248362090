import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Box, Typography } from '@mui/material';
import { SQSMessage as SQSMessageType } from '@localstack/types';

import {
  refineSqsUrl,
  useAwsGetter,
  useRoutes,
  useEndpoint,
  getSQSBackdoorMessagesEndpoint,
  useLocalstackStatus,
  checkSQSInternalEndpointSupported,
} from '@localstack/services';

import {
  Breadcrumbs,
  SQSMessageDetails,
  LoadingFragment,
} from '@localstack/ui';

import { DEFAULT_SQS_ROUTES } from '@localstack/constants';

import { SQSProps } from './types';

export const SQSMessage = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SQS_ROUTES,
}: SQSProps): ReactElement => {
  const { goto } = useRoutes();

  const { endpoint } = useEndpoint('SQS');
  const sqsEndpoint = clientOverrides?.endpoint ?? endpoint;
  const { name, messageId } = useParams<'name' | 'messageId'>();
  const { version } = useLocalstackStatus(clientOverrides);
  const internalEndpointSupported = checkSQSInternalEndpointSupported(version || '');

  const { data: url } = useAwsGetter('SQS', 'getQueueUrl', [{ QueueName: name }], { clientOverrides });
  const { data: messages, isLoading } = useAwsGetter(
    'SQS', 'receiveMessage', [{
      QueueUrl: refineSqsUrl(url?.QueueUrl, sqsEndpoint),
      AttributeNames: ['All'],
      MaxNumberOfMessages: 10,
    }],
    {
      defaultValue: { Messages: [] },
      clientOverrides: {
        ...clientOverrides,
        endpoint: internalEndpointSupported ? getSQSBackdoorMessagesEndpoint(sqsEndpoint) : undefined,
      },
    });

  const message = messages?.Messages.find((mex: SQSMessageType) => mex.MessageId === messageId);

  return (
    <>
      <Layout
        documentTitle="SQS: Message Details"
        title={
          <Box>
            <Typography variant="h4">Message Details</Typography>
            <Breadcrumbs
              mappings={[
                ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
                [name, () => goto(routes.RESOURCES_SQS_QUEUE, { name })],
                ['Messages', () => goto(routes.RESOURCES_SQS_QUEUE_MESSAGES, { name })],
                [messageId, null],
              ]}
            />
          </Box>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading}>
            <SQSMessageDetails message={message} />
          </LoadingFragment >
        </Card>
      </Layout>
    </>
  );
};
