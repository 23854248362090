export const SERVICE_CODES = {
  accessanalyzer: 'Access Analyzer',
  account: 'Account',
  'acm-pca': 'Private Certificate Authority',
  acm: 'Certificate Manager',
  alexaforbusiness: 'Alexa For Business',
  amp: 'Prometheus Service',
  amplify: 'Amplify',
  amplifybackend: 'Amplify Backend',
  amplifyuibuilder: 'Amplify UI Builder',
  apigateway: 'API Gateway',
  apigatewaymanagementapi: 'API Gateway Management Api',
  apigatewayv2: 'API Gateway V2',
  appconfig: 'AppConfig',
  appconfigdata: 'AppConfig Data',
  appfabric: 'AppFabric',
  appflow: 'Appflow',
  appintegrations: 'App Integrations Service',
  'application-autoscaling': 'Application Auto Scaling',
  'application-insights': 'Cloud Watch Application Insights',
  applicationcostprofiler: 'Application Cost Profiler',
  appmesh: 'App Mesh',
  apprunner: 'App Runner',
  appstream: 'AppStream',
  appsync: 'AppSync',
  'arc-zonal-shift': 'ARC-Zonal Shift',
  athena: 'Athena',
  auditmanager: 'Audit Manager',
  'autoscaling-plans': 'Auto Scaling Plans',
  autoscaling: 'Auto Scaling',
  'backup-gateway': 'Backup Gateway',
  backup: 'Backup',
  backupstorage: 'Backup Storage',
  batch: 'Batch',
  'bedrock-runtime': 'Bedrock Runtime',
  bedrock: 'Bedrock',
  billingconductor: 'Billing Conductor',
  braket: 'Braket',
  budgets: 'Budgets',
  ce: 'Cost Explorer',
  'chime-sdk-identity': 'Chime SDK Identity',
  'chime-sdk-media-pipelines': 'Chime SDK Media Pipelines',
  'chime-sdk-meetings': 'Chime SDK Meetings',
  'chime-sdk-messaging': 'Chime SDK Messaging',
  'chime-sdk-voice': 'Chime SDK Voice',
  chime: 'Chime',
  cleanrooms: 'Clean Rooms Service',
  cloud9: 'Cloud9',
  cloudcontrol: 'Cloud Control API',
  clouddirectory: 'CloudDirectory',
  cloudformation: 'CloudFormation',
  'cloudfront-keyvaluestore': 'Cloud Front Key Value Store',
  cloudfront: 'CloudFront',
  cloudhsm: 'CloudHSM',
  cloudhsmv2: 'Cloud H S M V2',
  cloudsearch: 'CloudSearch',
  cloudsearchdomain: 'Cloud Search Domain',
  'cloudtrail-data': 'Cloud Trail Data Service',
  cloudtrail: 'CloudTrail',
  cloudwatch: 'CloudWatch',
  codeartifact: 'CodeArtifact',
  codebuild: 'CodeBuild',
  codecatalyst: 'CodeCatalyst',
  codecommit: 'CodeCommit',
  codedeploy: 'CodeDeploy',
  'codeguru-reviewer': 'Code Guru Reviewer',
  'codeguru-security': 'Code Guru Security',
  codeguruprofiler: 'Code Guru Profiler',
  codepipeline: 'CodePipeline',
  'codestar-connections': 'Code Star ',
  'codestar-notifications': 'Code Star Notifications',
  codestar: 'CodeStar',
  'cognito-identity': 'Cognito Identity',
  'cognito-idp': 'Cognito',
  'cognito-sync': 'Cognito Sync',
  comprehend: 'Comprehend',
  comprehendmedical: 'Comprehend Medical',
  'compute-optimizer': 'Compute Optimizer',
  config: 'Config',
  'connect-contact-lens': 'Connect Contact Lens',
  connect: 'Connect Service',
  connectcampaigns: 'ConnectCampaignService',
  connectcases: 'Connect Cases',
  connectparticipant: 'Connect Participant Service',
  controltower: 'Control Tower',
  cur: 'Cost  Usage Report Service',
  'customer-profiles': 'Connect Customer Profiles',
  databrew: 'Glue Data Brew',
  dataexchange: 'Data Exchange',
  datapipeline: 'Data Pipeline',
  datasync: 'DataSync',
  datazone: 'DataZone',
  dax: 'DynamoDB Accelerator (DAX)',
  detective: 'Detective',
  devicefarm: 'Device Farm',
  'devops-guru': 'Dev Ops Guru',
  directconnect: 'Direct Connect',
  discovery: 'Application Discovery Service',
  dlm: 'Data Lifecycle Manager',
  dms: 'Database Migration Service',
  'docdb-elastic': 'DocumentDB Elastic Clusters',
  docdb: 'DocumentDB',
  drs: 'Elastic Disaster Recovery Service',
  ds: 'Directory Service',
  dynamodb: 'DynamoDB',
  dynamodbstreams: 'DynamoDB Streams',
  ebs: 'Elastic Block Store',
  'ec2-instance-connect': 'EC2 Instance Connect',
  ec2: 'EC2',
  'ecr-public': 'Elastic Container Registry Public',
  ecr: 'ECR',
  ecs: 'ECS',
  efs: 'Elastic File System',
  eks: 'EKS',
  'elastic-inference': 'Elastic Inference',
  elasticache: 'ElastiCache',
  elasticbeanstalk: 'Elastic Beanstalk',
  elastictranscoder: 'Elastic Transcoder',
  elb: 'Elastic Load Balancing',
  elbv2: 'Elastic Load Balancing V2',
  'emr-containers': 'EMR Containers',
  'emr-serverless': 'EMR Serverless',
  emr: 'EMR',
  entityresolution: 'EntityResolution',
  es: 'Elasticsearch Service',
  events: 'EventBridge',
  evidently: 'Cloud Watch Evidently',
  'finspace-data': 'Fin Space Public API',
  finspace: 'Fin Space User Environment Management ',
  firehose: 'Kinesis Firehose',
  fis: 'Fault Injection Simulator',
  fms: 'Firewall Management Service',
  forecast: 'Forecast Service',
  forecastquery: 'Forecast Query Service',
  frauddetector: 'Fraud Detector',
  fsx: 'FSx',
  gamelift: 'GameLift',
  glacier: 'Glacier',
  globalaccelerator: 'Global Accelerator',
  glue: 'Glue',
  grafana: 'Managed Grafana',
  greengrass: 'Greengrass',
  greengrassv2: 'IoT Greengrass V2',
  groundstation: 'Ground Station',
  guardduty: 'GuardDuty',
  health: 'Health APIs  Notifications',
  healthlake: 'Health Lake',
  honeycode: 'Honeycode',
  iam: 'IAM',
  identitystore: 'SSO Identity Store',
  imagebuilder: 'EC2 Image Builder',
  importexport: 'Import/Export',
  infrastructure: 'Infrastructure',
  'inspector-scan': 'Inspector Scan',
  inspector: 'Inspector',
  inspector2: 'Inspector2',
  internetmonitor: 'Cloud Watch Internet Monitor',
  'iot-data': 'IoT Data Plane',
  'iot-jobs-data': 'IoT Jobs Data Plane',
  'iot-roborunner': 'IoT Robo Runner',
  iot: 'IoT',
  'iot1click-devices': 'IoT Click Devices Service',
  'iot1click-projects': 'IoT Click Projects Service',
  iotanalytics: 'IoT Analytics',
  iotdeviceadvisor: 'IoT Core Device Advisor',
  'iotevents-data': 'IoT Events Data',
  iotevents: 'IoT Events',
  iotfleethub: 'IoT Fleet Hub',
  iotfleetwise: 'IoT Fleet Wise',
  iotsecuretunneling: 'IoT Secure Tunneling',
  iotsitewise: 'IoT Site Wise',
  iotthingsgraph: 'IoT Things Graph',
  iottwinmaker: 'IoT Twin Maker',
  iotwireless: 'IoT Wireless',
  'ivs-realtime': 'Interactive Video Service Real Time',
  ivs: 'Interactive Video Service',
  ivschat: 'Interactive Video Service Chat',
  kafka: 'Kafka',
  kafkaconnect: 'Kafka Connect',
  'kendra-ranking': 'Kendra Intelligent Ranking',
  kendra: 'Kendra Frontend Service',
  keyspaces: 'Keyspaces',
  'kinesis-video-archived-media': 'Kinesis Video Streams Archived Media',
  'kinesis-video-media': 'Kinesis Video Streams Media',
  'kinesis-video-signaling': 'Kinesis Video Signaling Channels',
  'kinesis-video-webrtc-storage': 'Kinesis Video Web RTC Storage',
  kinesis: 'Kinesis',
  kinesisanalytics: 'Kinesis Analytics',
  kinesisanalyticsv2: 'Kinesis Analytics',
  kinesisvideo: 'Kinesis Video Streams',
  kms: 'KMS',
  lakeformation: 'Lake Formation',
  lambda: 'Lambda',
  'launch-wizard': 'Launch Wizard',
  'lex-models': 'Lex Model Building Service',
  'lex-runtime': 'Lex Runtime Service',
  'lexv2-models': 'Lex Model Building V2',
  'lexv2-runtime': 'Lex Runtime V2',
  'license-manager-linux-subscriptions': 'License Manager Linux Subscriptions',
  'license-manager-user-subscriptions': 'License Manager User Subscriptions',
  'license-manager': 'License Manager',
  lightsail: 'Lightsail',
  location: 'Location Service',
  logs: 'CloudWatch Logs',
  lookoutequipment: 'Lookout  Equipment',
  lookoutmetrics: 'Lookout  Metrics',
  lookoutvision: 'Lookout  Vision',
  m2: 'Mainframe Modernization',
  machinelearning: 'Machine Learning',
  macie2: 'Macie 2',
  'managedblockchain-query': 'Managed Blockchain Query',
  managedblockchain: 'Managed Blockchain',
  'marketplace-catalog': 'Marketplace Catalog Service',
  'marketplace-entitlement': 'Marketplace Entitlement Service',
  marketplacecommerceanalytics: 'Marketplace Commerce Analytics',
  mediaconnect: 'MediaConnect',
  mediaconvert: 'Elemental Media Convert',
  medialive: 'Elemental Media Live',
  'mediapackage-vod': 'Elemental Media Package VOD',
  mediapackage: 'Elemental Media Package',
  mediapackagev2: 'Elemental Media Package ',
  'mediastore-data': 'MediaStore Data',
  mediastore: 'MediaStore',
  mediatailor: 'MediaTailor',
  'medical-imaging': 'Health Imaging',
  memorydb: 'MemoryDB',
  meteringmarketplace: 'Marketplace Metering',
  mgh: 'Migration Hub',
  mgn: 'Application Migration Service',
  'migration-hub-refactor-spaces': 'Migration Hub Refactor Spaces',
  'migrationhub-config': 'Migration Hub Config',
  migrationhuborchestrator: 'Migration Hub Orchestrator',
  migrationhubstrategy: 'Migration Hub Strategy Recommendations',
  mobile: 'Mobile',
  mq: 'MQ',
  mturk: 'Mechanical Turk',
  mwaa: 'MWAA',
  neptune: 'Neptune',
  neptunedata: 'NeptuneData',
  'network-firewall': 'Network Firewall',
  networkmanager: 'Network Manager',
  nimble: 'NimbleStudio',
  oam: 'Cloud Watch Observability Access Manager',
  omics: 'Omics',
  opensearch: 'Open Search Service',
  opensearchserverless: 'Open Search Service Serverless',
  opsworks: 'OpsWorks',
  opsworkscm: 'Ops Works CM',
  organizations: 'Organizations',
  osis: 'Open Search Ingestion',
  outposts: 'Outposts',
  panorama: 'Panorama',
  'payment-cryptography-data': 'Payment Cryptography Data Plane',
  'payment-cryptography': 'Payment Cryptography Control Plane',
  'pca-connector-ad': 'PcaConnectorAd',
  'personalize-events': 'Personalize Events',
  'personalize-runtime': 'Personalize Runtime',
  personalize: 'Personalize',
  pi: 'Performance Insights',
  'pinpoint-email': 'Pinpoint Email Service',
  'pinpoint-sms-voice-v2': 'Pinpoint SMS Voice V2',
  'pinpoint-sms-voice': 'Pinpoint SMS  Voice Service',
  pinpoint: 'Pinpoint',
  pipes: 'EventBridge Pipes',
  polly: 'Polly',
  pricing: 'Price List Service',
  privatenetworks: 'Private 5G',
  proton: 'Proton',
  'qldb-session': 'QLDB Session',
  qldb: 'QLDB',
  quicksight: 'QuickSight',
  ram: 'Resource Access Manager',
  rbin: 'Recycle Bin',
  'rds-data': 'RDS Data Service',
  rds: 'RDS',
  'redshift-data': 'Redshift Data API Service',
  'redshift-serverless': 'Redshift Serverless',
  redshift: 'Redshift',
  rekognition: 'Rekognition',
  resiliencehub: 'Resilience Hub',
  'resource-explorer-2': 'Resource Explorer',
  'resource-groups': 'Resource Groups',
  resourcegroupstaggingapi: 'Resource Groups Tagging API',
  robomaker: 'RoboMaker',
  rolesanywhere: 'IAM Roles Anywhere',
  'route53-recovery-cluster': 'Route Recovery Cluster',
  'route53-recovery-control-config': 'Route Recovery Control Config',
  'route53-recovery-readiness': 'Route Recovery Readiness',
  route53: 'Route 53',
  route53domains: 'Route 53 Domains',
  route53resolver: 'Route 53 Resolver',
  rum: 'Cloud Watch RUM',
  s3: 'S3',
  s3control: 'S3 Control',
  s3outposts: 'S3  Outposts',
  'sagemaker-a2i-runtime': 'Augmented AI Runtime',
  'sagemaker-edge': 'Sagemaker Edge Manager',
  'sagemaker-featurestore-runtime': 'Sage Maker Feature Store Runtime',
  'sagemaker-geospatial': 'Sage Maker',
  'sagemaker-metrics': 'Sage Maker Metrics Service',
  'sagemaker-runtime': 'Sage Maker Runtime',
  sagemaker: 'SageMaker',
  savingsplans: 'Savings Plans',
  scheduler: 'Event Bridge Scheduler',
  schemas: 'Schemas',
  sdb: 'SimpleDB',
  secretsmanager: 'Secrets Manager',
  securityhub: 'SecurityHub',
  securitylake: 'Security Lake',
  serverlessrepo: 'Serverless Application Repository',
  'service-quotas': 'Service Quotas',
  'servicecatalog-appregistry': 'Service Catalog App Registry',
  servicecatalog: 'Service Catalog',
  servicediscovery: 'Cloud Map',
  ses: 'SES',
  sesv2: 'SES V2',
  shield: 'Shield',
  signer: 'Signer',
  simspaceweaver: 'Sim Space Weaver',
  'sms-voice': 'Pinpoint SMS  Voice Service',
  sms: 'Server Migration Service',
  'snow-device-management': 'Snow Device Management',
  snowball: 'Import Export Snowball',
  sns: 'SNS',
  sqs: 'SQS',
  'ssm-contacts': 'Systems Manager Incident Manager Contacts',
  'ssm-incidents': 'Systems Manager Incident Manager',
  'ssm-sap': 'Systems Manager  SAP',
  ssm: 'Simple Systems Manager (SSM)',
  'sso-admin': 'Single Sign On Admin',
  'sso-oidc': 'SSO OIDC',
  sso: 'Single Sign On',
  stepfunctions: 'Step Functions',
  storagegateway: 'Storage Gateway',
  sts: 'Security Token Service',
  'support-app': 'Support App',
  support: 'Support',
  swf: 'Simple Workflow Service',
  synthetics: 'Synthetics',
  textract: 'Textract',
  'timestream-query': 'Timestream Query',
  'timestream-write': 'Timestream',
  tnb: 'Telco Network Builder',
  transcribe: 'Transcribe Service',
  transfer: 'Transfer Family',
  translate: 'Translate',
  trustedadvisor: 'Trusted Advisor Public API',
  verifiedpermissions: 'Verified Permissions',
  'voice-id': 'Voice ID',
  'vpc-lattice': 'VPC Lattice',
  'waf-regional': 'WAF Regional',
  waf: 'WAF',
  wafv2: 'WAF V2',
  wellarchitected: 'Well Architected Tool',
  wisdom: 'Connect Wisdom Service',
  workdocs: 'WorkDocs',
  worklink: 'WorkLink',
  workmail: 'WorkMail',
  workmailmessageflow: 'Work Mail Message Flow',
  'workspaces-web': 'Work Spaces Web',
  workspaces: 'WorkSpaces',
  xray: 'X-Ray',
};
