/* eslint-disable max-len */
export enum ExternalLink {
  // Stripe
  STRIPE_BASE = 'https://stripe.com',
  STRIPE_BILLING = 'https://stripe.com/docs/billing/subscriptions/backdating?dashboard-or-api=api#backdating-billing-cycle',

  // Docs
  DOCS_BASE = 'https://docs.localstack.cloud',
  DOCS_PRO = 'https://docs.localstack.cloud/get-started/pro/',
  DOCS_TOOLS = 'https://docs.localstack.cloud/user-guide/tools/',
  DOCS_CLOUD_PODS = 'https://docs.localstack.cloud/user-guide/cloud-pods/',
  DOCS_CI = 'https://docs.localstack.cloud/user-guide/ci/',
  DOCS_EXTENSIONS = 'https://docs.localstack.cloud/user-guide/extensions/',
  DOCS_DEVELOPING_EXTENSIONS = 'https://docs.localstack.cloud/user-guide/extensions/developing-extensions/',
  DOCS_SSO = 'https://docs.localstack.cloud/user-guide/web-application/single-sign-on/',
  DOCS_AWS_CLI = 'https://docs.localstack.cloud/user-guide/integrations/aws-cli',
  DOCS_CORE_CONFIG = 'https://docs.localstack.cloud/references/configuration/#core',
  DOCS_ADD_BADGE_TO_REPO = 'https://docs.localstack.cloud/user-guide/cloud-pods/launchpad/#adding-a-badge-to-your-repository',
  DOCS_SECURITY_LAUNCHPAD = 'https://docs.localstack.cloud/user-guide/cloud-pods/launchpad/#security-implications-of-the-launchpad',
  DOCS_FEATURE_COVERAGE = 'https://docs.localstack.cloud/user-guide/aws/feature-coverage/',
  DOCS_GETTING_STARTED = 'https://docs.localstack.cloud/getting-started/',
  DOCS_PODS_CLI = 'https://docs.localstack.cloud/user-guide/state-management/pods-cli/',
  DOCS_STATE_MGMT = 'https://docs.localstack.cloud/user-guide/state-management/export-import-state/',
  DOCS_STACK_INSIGHTS = 'https://docs.localstack.cloud/user-guide/web-application/stack-insights',
  DOCS_QUICK_START = 'https://docs.localstack.cloud/getting-started/quickstart/',
  DOCS_USER_LICENSE = 'https://docs.localstack.cloud/user-guide/web-application/users-licences/',
  DOCS_ECR_GUIDE = 'https://docs.localstack.cloud/user-guide/aws/elastic-container-registry/',
  DOCS_AUTH_TOKEN = 'https://docs.localstack.cloud/getting-started/auth-token/',
  DOCS_EPHEMERAL_INSTANCES = 'https://docs.localstack.cloud/user-guide/cloud-sandbox/ephemeral-instance/',
  DOCS_SECURITY_TESTING = 'https://docs.localstack.cloud/user-guide/security-testing/',

  // Legal
  LEGAL_PRIVACY_POLICY = 'https://localstack.cloud/legal/privacy-policy',
  LEGAL_TOS = 'https://localstack.cloud/legal/tos',
  LEGAL_DPA = 'https://www.localstack.cloud/legal/dpa',

  // Demos
  DEMO_LS = 'https://github.com/localstack/localstack-demo',
  DEMO_PET_STORE = 'https://hub.docker.com/r/localstack/ecsapi-demo-petstore',
  DEMO_FOOD_STORE = 'https://hub.docker.com/r/localstack/ecsapi-demo-foodstore',
  DEMO_THUNDRA = 'https://github.com/thundra-io/thundra-demo-localstack-java',
  DEMO_QUIZ_APP = 'https://github.com/localstack-samples/serverless-quiz-app',

  // Sample
  SAMPLE_TF_ECS_AGW = 'https://github.com/localstack-samples/sample-terraform-ecs-apigateway',

  // Web app
  WEB_APP_SIGN_IN = 'https://app.localstack.cloud/sign-in',
  WEB_APP_BASE = 'https://app.localstack.cloud',
  WEB_APP_DEFAULT_ENDPOINT = 'https://localhost.localstack.cloud:4566',

  // Website
  WEBSITE_BASE = 'https://localstack.cloud',
  WEBSITE_CONTACT = 'https://localstack.cloud/contact',
  WEBSITE_BLOG = 'https://localstack.cloud/blog/',

  // Blog Posts
  BLOG_2024_03_RENAME_PRO_STARTER = 'https://blog.localstack.cloud/2024-03-22-pro-starter-rename/',

  // Badges
  BADGE_LAUNCH_POD = 'https://localstack.cloud/gh/launch-pod-badge.svg',
  BADGE_EXTENSIONS = 'https://cdn.localstack.cloud/gh/extension-badge.svg',

  // Logos
  LOGO_AUTHRESS_EXTENSION = 'https://raw.githubusercontent.com/authress/localstack-extension/release/0.1/logo.png',

  // AWS
  EXTERNAL_AWS_CLI = 'https://aws.amazon.com/cli/',

  // Others
  EXTERNAL_GRAVATAR = 'https://en.gravatar.com/',
  EXTERNAL_BREW = 'https://brew.sh/',
  EXTERNAL_THUNDRA = 'https://thundra.io',

  EXTENSION_HELLO_WORLD = 'https://github.com/localstack/localstack-extensions/tree/main/hello-world',
  DISCUSS = 'https://discuss.localstack.cloud',
}
