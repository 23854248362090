import { ReactElement, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { useRoutes } from '@localstack/services';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { ErrorBoundary } from './components';
import { LoadingProjectStatisticsContainer, PipelineContainer } from './containers';

export const Pipeline = (): ReactElement => {
  const { goto } = useRoutes();

  const {
    projectId,
    pipelineId,
  } = useParams<'projectId' | 'pipelineId'>() as { projectId: string, pipelineId: string };

  return (
    <CustomerLayout
      fullScreen
      title={
        <Box>
          <IconButton onClick={() => goto(AppRoute.PROJECT, { projectId })}>
            <BackIcon />
          </IconButton>
          Pipeline Overview
        </Box>
      }
    >
      <ErrorBoundary>
        <Suspense fallback={<LoadingProjectStatisticsContainer />}>
          <PipelineContainer
            projectId={projectId}
            pipelineId={pipelineId}
            onShowPipeline={
              (projectId_, pipelineId_) => goto(
                AppRoute.PIPELINE, { projectId: projectId_, pipelineId: pipelineId_ },
              )
            }
          />
        </Suspense>
      </ErrorBoundary>
    </CustomerLayout>
  );
};
