import { useEffect, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES } from '@localstack/services';
import { Pipeline, RepoProvider } from '@localstack/types';
import { Grid, MenuItem, TextField } from '@mui/material';
import { ControlledSelect, ControlledTextField } from '@localstack/ui';

export interface PipelineFormProps {
  formId: string;
  projectId: string;
  pipeline?: Optional<Pipeline>;
  onFormValidationChange?: (valid: boolean) => unknown;
  onSubmitCreate?: (projectId: string, data: Pipeline) => unknown;
  onSubmitUpdate?: (projectId: string, pipelineId: string, data: Pipeline) => unknown;
}

export const PipelineForm = ({
  formId,
  projectId,
  pipeline,
  onFormValidationChange,
  onSubmitCreate,
  onSubmitUpdate,
}: PipelineFormProps): ReactElement => {
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    reset,
  } = useForm<Pipeline>({ mode: 'all' });

  const onSubmit = async (data: Pipeline) => {
    if (pipeline) {
      await onSubmitUpdate?.(projectId, pipeline.id as string, data);
    } else {
      await onSubmitCreate?.(projectId, data);
    }
  };

  useEffect(() => {
    reset();

    if (!pipeline) {
      return;
    }

    Object.entries(pipeline).forEach(([key, value]) => {
      setValue(key as keyof Pipeline, value, { shouldValidate: true });
    });
  }, [pipeline]);

  useEffect(() => {
    onFormValidationChange?.(formState.isValid);
  }, [formState.isValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            required
            disabled
            label='Project ID'
            type='text'
            variant='outlined'
            value={projectId}
          />
        </Grid>
        {pipeline && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              required
              disabled
              label='Pipeline ID'
              type='text'
              variant='outlined'
              value={pipeline.id ?? ''}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ControlledTextField
            control={control}
            name='name'
            fullWidth
            required
            label='Pipeline Name'
            type='text'
            variant='outlined'
            rules={VALIDATION_RULES.required}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ControlledSelect
            name="repo_provider"
            control={control}
            variant="outlined"
            fullWidth
            required
            rules={VALIDATION_RULES.required}
            label="Repository Provider"
            options={[
              <MenuItem key={RepoProvider.GITHUB} value={RepoProvider.GITHUB}>GitHub</MenuItem>,
              <MenuItem key={RepoProvider.GITLAB} value={RepoProvider.GITLAB}>GitLab</MenuItem>,
              <MenuItem key={RepoProvider.BITBUCKET} value={RepoProvider.BITBUCKET}>BitBucket</MenuItem>,
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ControlledTextField
            control={control}
            name='repo_url'
            fullWidth
            required
            label='Repository URL'
            type='text'
            variant='outlined'
            rules={VALIDATION_RULES.required}
          />
        </Grid>
      </Grid>
    </form>
  );
};
