import { ReactElement, useState } from 'react';
import { noop } from 'lodash';
import { AwsServiceTile, CodeSnippetViewer } from '@localstack/ui';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ProjectsService, useApiGetter } from '@localstack/services';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography, Chip,
} from '@mui/material';

import { SessionLayout } from '../layouts';
import { SessionRequestQueryResult } from '../types';

import { StepRequestsContainer } from './StepRequestsContainer';

export interface StepDetailedRequestsContainerProps {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  stepId: string;
  onClose?: () => unknown;
}

export const StepDetailedRequestsContainer = ({
  projectId,
  pipelineId,
  sessionId,
  stepId,
}: StepDetailedRequestsContainerProps): ReactElement => {
  const [selectedRequest, setSelectedRequest] = useState<SessionRequestQueryResult | null>(null);

  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <SessionLayout
      details={
        selectedRequest && (
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Request Details
              </AccordionSummary>
              <AccordionDetails>
                <Table size="small">
                  <TableBody>
                    {selectedRequest.request_id && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Request ID
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedRequest.request_id}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          label={selectedRequest.status_code}
                          color={selectedRequest.status_code < 400 ? 'success' : 'error'}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Time Start
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedRequest.time_start}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Time End
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedRequest.time_end}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Duration (seconds)
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedRequest.duration_seconds}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
            {selectedRequest.service_name && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                  Service Details
                </AccordionSummary>
                <AccordionDetails>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Service
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <AwsServiceTile code={selectedRequest.service_name} size="medium" navigateTo={noop} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Operation
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedRequest.operation_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Region
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedRequest.region}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Account
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedRequest.account_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            API Version
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedRequest.api_version}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Headers
              </AccordionSummary>
              <AccordionDetails>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography>Request Headers</Typography>
                      </TableCell>
                    </TableRow>
                    {Object.entries(JSON.parse(selectedRequest.request_headers)).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography variant="caption">{key}</Typography>
                        </TableCell>
                        <TableCell>{value as string}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography>Response Headers</Typography>
                      </TableCell>
                    </TableRow>
                    {Object.entries(JSON.parse(selectedRequest.response_headers)).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography variant="caption">{key}</Typography>
                        </TableCell>
                        <TableCell>{value as string}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Payload
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography variant="caption">Request Body</Typography>
                  <CodeSnippetViewer disableParsing data={selectedRequest.request_body ?? ''} />
                </Box>
                <Box mt={2}>
                  <Typography variant="caption">Response Body</Typography>
                  <CodeSnippetViewer disableParsing data={selectedRequest.response_body ?? ''} />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      }
    >
      <StepRequestsContainer
        pipelineId={pipelineId}
        sessionId={sessionId}
        stepId={stepId}
        tinybirdToken={tinybirdToken}
        onSelect={setSelectedRequest}
      />
    </SessionLayout>
  );
};
