import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';
import { useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';

import { useTinybirdPipe } from '../hooks';
import { PipelineQueryResult } from '../types';
import { CurrentlyFailingPipelinesTable } from '../components';

export interface CurrentlyFailingPipelinesContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
}

export const CurrentlyFailingPipelinesContainer = (
  { tinybirdToken, pipelines }: CurrentlyFailingPipelinesContainerProps,
): ReactElement => {
  const { goto } = useRoutes();

  const { data: currentlyFailingPipelines } = useTinybirdPipe<PipelineQueryResult[]>(
    'currently_failing_pipelines_pipe', tinybirdToken,
  );

  return (
    <CurrentlyFailingPipelinesTable
      pipelines={pipelines}
      currentlyFailingPipelines={currentlyFailingPipelines ?? []}
      onShowSession={(projectId, pipelineId, sessionId) => {
        goto(AppRoute.PROJECT_SESSION, { projectId, pipelineId, sessionId });
      }}
    />
  );
};
