import { Accordion, AccordionSummary, AccordionDetails, IconButton, Typography, Box } from '@mui/material';
import { ReactElement, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { CopyIconButton } from '@localstack/ui';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

type CustomAccordionProps = {
  title: string;
  details: JSX.Element;
  expanded?: boolean;
  copyContent?: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      boxShadow: 'none',
    },
    accordionSummary: {
      display: 'flex', 
      alignItems: 'center', 
      width: '100%',
    },
    drawer: {
      width: '600px',
    },
    drawerHeader: {
      display: 'flex',  
      flexDirection: 'column',
      gap: '0.5rem',
    },
    drawerSubHeader: {
      display: 'flex',  
      alignItems: 'center',
      gap: '0.5rem',
    },
    cardContent: {
      padding: 0,
    },  
  }),
);


export const CustomAccordion = ({ 
  title, 
  details, 
  expanded = false, 
  copyContent,
}: CustomAccordionProps): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(expanded);

  return (
    <Accordion 
      disableGutters 
      square
      className={classes.accordion}
      expanded={open} 
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary>
        <Box className={classes.accordionSummary}>
          <Typography variant="h5">
            {title}
          </Typography>
          <IconButton>
            {open ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
          </IconButton>
          <Box sx={{ flexGrow: '1' }}/>
          { copyContent && (
            <CopyIconButton tooltipTitle='Copy Policy (JSON)' textToCopy={copyContent} color='default' />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};
