import { ReactElement, useState } from 'react';
import { ProgressButton } from '@localstack/ui';
import { ProjectsService, useApiGetter, useAwsEffect } from '@localstack/services';
import { NavigateBefore as BeforeSnapshotIcon, NavigateNext as AfterSnapshotIcon } from '@mui/icons-material';

import {
  Alert,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material';

import { StepQueryResult } from '../types';
import { useTinybirdPipe } from '../hooks';

export interface StepPodsContainerProps {
  tinybirdToken: string;
  authToken: string;
  pipelineId: string;
  sessionId: string;
  stepId: string;
}

export const StepPodsContainer = ({
  tinybirdToken,
  authToken,
  pipelineId,
  sessionId,
  stepId,
}: StepPodsContainerProps): ReactElement => {
  const [beforeLoading, setBeforeLoading] = useState(false);
  const [beforeInjected, setBeforeInjected] = useState(false);

  const [afterLoading, setAfterLoading] = useState(false);
  const [afterInjected, setAfterInjected] = useState(false);

  const { data: [step] } = useTinybirdPipe<[StepQueryResult]>(
    'step_pipe',
    tinybirdToken,
    { pipeline_id: pipelineId, session_id: sessionId, step_id: stepId },
  );

  const { loadPod, isLoading: isInjectingPod } = useAwsEffect(
    'LocalStack',
    ['loadPod'],
  );

  const stepHasSnapshots = step.snapshot_before || step.snapshot_after;

  const handleInjectBefore = async () => {
    if (step.snapshot_before) {
      const [podName, podVersion] = step.snapshot_before.split(':') as [string, string];
      setBeforeLoading(true);
      await loadPod(podName, Number.parseInt(podVersion, 10), authToken);
      setBeforeLoading(false);
      setBeforeInjected(true);
    }
  };

  const handleInjectAfter = async () => {
    if (step.snapshot_after) {
      const [podName, podVersion] = step.snapshot_after.split(':') as [string, string];
      setAfterLoading(true);
      await loadPod(podName, Number.parseInt(podVersion, 10), authToken);
      setAfterLoading(false);
      setAfterInjected(true);
    }
  };

  return (
    <>
      {!stepHasSnapshots && (
        <Alert severity="info">
          No snapshots captured for this step.
        </Alert>
      )}
      <Stack direction="row" spacing={2}>
        {step.snapshot_before && (
          <Card>
            <CardHeader avatar={<BeforeSnapshotIcon />} title="Before Snapshot" />
            <CardContent>
              <Typography paragraph>
                This step has a captured snapshot before the execution.
              </Typography>
              <Typography paragraph>
                You can inject this snapshot into the currently running LocalStack instance for further inspection.
              </Typography>
            </CardContent>
            {!beforeInjected && (
              <CardActions>
                <ProgressButton
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleInjectBefore}
                  loading={beforeLoading}
                  disabled={isInjectingPod}
                >
                  Inject
                </ProgressButton>
              </CardActions>
            )}
          </Card>
        )}
        {step.snapshot_after && (
          <Card>
            <CardHeader avatar={<AfterSnapshotIcon />} title="After Snapshot" />
            <CardContent>
              <Typography paragraph>
                This step has a captured snapshot after the execution.
              </Typography>
              <Typography paragraph>
                You can inject this snapshot into the currently running LocalStack instance for further inspection.
              </Typography>
            </CardContent>
            {!afterInjected && (
              <CardActions>
                <ProgressButton
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleInjectAfter}
                  loading={afterLoading}
                  disabled={isInjectingPod}
                >
                  Inject
                </ProgressButton>
              </CardActions>
            )}
          </Card>
        )}
      </Stack>
    </>
  );
};

export interface StepDetailedPodsContainerProps {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  stepId: string;
  authToken: string;
}

export const StepDetailedPodsContainer = (
  { projectId, pipelineId, sessionId, stepId, authToken }: StepDetailedPodsContainerProps,
): ReactElement => {
  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <StepPodsContainer
      tinybirdToken={tinybirdToken}
      authToken={authToken}
      pipelineId={pipelineId}
      sessionId={sessionId}
      stepId={stepId}
    />
  );
};
