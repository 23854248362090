import { ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pipeline } from '@localstack/types';

import { FailingTestQueryResult } from '../types';

import { PipelineNameToggle } from './PipelineNameToggle';

export interface CurrentlyFailingTestsTableProps {
  pipelines: Record<string, Pipeline>;
  currentlyFailingTests: FailingTestQueryResult[];
}

export const CurrentlyFailingTestsTable = (
  { pipelines, currentlyFailingTests }: CurrentlyFailingTestsTableProps,
): ReactElement => (
  <DataGrid
    autoHeight
    density="compact"
    columns={[
      { field: 'step_id', headerName: 'Test', minWidth: 500 },
      {
        field: 'pipeline_id',
        headerName: 'Pipeline',
        minWidth: 300,
        renderCell: ({ value }) => (
          <PipelineNameToggle
            pipelineId={value}
            pipelineName={pipelines[value]?.name}
          />
        ),
      },
      { field: 'session_id', headerName: 'Session', minWidth: 300 },
      { field: 'duration_seconds', headerName: 'Duration', minWidth: 50 },
    ]}
    rows={currentlyFailingTests ?? []}
    getRowId={(row) => `${row.pipeline_id}-${row.session_id}-${row.step_id}`}
  />
);
