import { ReactElement } from 'react';
import { formatDateTime } from '@localstack/services';
import { DataGrid } from '@mui/x-data-grid';
import { Link, Typography } from '@mui/material';
import { Pipeline } from '@localstack/types';

import { PipelineSessionQueryResult } from '../types';
import { buildCommitUrl } from '../utils';

import { StatusLabel } from './StatusLabel';

export interface PipelineSessionsTableProps {
  pipelines: Record<string, Pipeline>;
  sessions: PipelineSessionQueryResult[];
  onShowSession?: (projectId: string, pipelineId: string, sessionId: string) => unknown;
}

export const PipelineSessionsTable = (
  { pipelines, sessions, onShowSession }: PipelineSessionsTableProps,
): ReactElement => (
  <DataGrid
    autoHeight
    density="compact"
    columns={[
      {
        field: 'session_id',
        headerName: 'Session',
        minWidth: 300,
        renderCell: ({ value, row }) => (
          <Link
            href="#"
            onClick={(e) => {e.preventDefault(); onShowSession?.(row.project_id, row.pipeline_id, value);}}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'session_state',
        headerName: 'Status',
        minWidth: 100,
        renderCell: ({ value }) => (
          <StatusLabel status={value} />
        ),
      },
      {
        field: 'commit',
        headerName: 'Commit',
        minWidth: 280,
        renderCell: ({ value, row: { pipeline_id } }) => (
          <>
            {(value && pipelines[pipeline_id]) ? (
              <Link
                target="_blank"
                href={
                  buildCommitUrl(
                    pipelines[pipeline_id]!.repo_provider, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    pipelines[pipeline_id]!.repo_url, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    value,
                  )
                }
              >
                {value}
              </Link>
            ) : (
              <Typography variant="caption">No commit</Typography>
            )}
          </>
        ),
      },
      {
        field: 'time_start',
        headerName: 'Started At',
        minWidth: 200,
        valueFormatter: (params) => formatDateTime(params.value as number),
      },
      {
        field: 'time_end',
        headerName: 'Finished At',
        minWidth: 200,
        valueFormatter: (params) => formatDateTime(params.value as number),
      },
      {
        field: 'duration_seconds',
        headerName: 'Duration',
        minWidth: 150,
      },
    ]}
    rows={sessions ?? []}
    getRowId={(row) => `${row.pipeline_id}-${row.session_id}`}
  />
);
