import { ReactElement, useState } from 'react';
import { CodeSnippetViewer } from '@localstack/ui';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ProjectsService, useApiGetter } from '@localstack/services';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import { SessionLayout } from '../layouts';
import { SessionsErrorQueryResult } from '../types';

import { StepErrorsContainer } from './StepErrorsContainer';
import { ErrorAnalysisContainer } from './ErrorAnalysisContainer';

export interface StepDetailedErrorsContainerProps {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  stepId: string;
  onClose?: () => unknown;
}

export const StepDetailedErrorsContainer = ({
  projectId,
  pipelineId,
  sessionId,
  stepId,
}: StepDetailedErrorsContainerProps): ReactElement => {
  const [selectedError, setSelectedError] = useState<SessionsErrorQueryResult | null>(null);

  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <SessionLayout
      details={
        selectedError && (
          <Box>
            <Accordion defaultExpanded disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                Error Details
              </AccordionSummary>
              <AccordionDetails>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Error
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedError.error_type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Time
                        </Typography>
                      </TableCell>
                      <TableCell>{selectedError.time}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <CodeSnippetViewer disableParsing data={selectedError.error_message} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Stack Trace
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography variant="caption">Latest Trace</Typography>
                  <CodeSnippetViewer
                    disableParsing
                    data={selectedError.traceback[selectedError.traceback.length - 1] as string}
                  />
                </Box>
                <Box mt={2}>
                  <Typography variant="caption">Full Stack Trace</Typography>
                  <CodeSnippetViewer disableParsing data={selectedError.traceback.join('\n')} />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                Error Analysis
              </AccordionSummary>
              <ErrorAnalysisContainer
                projectId={projectId}
                errorFrames={Object.values(JSON.parse(selectedError.frames ?? '{}'))}
              />
            </Accordion>
          </Box>
        )
      }
    >
      <StepErrorsContainer
        pipelineId={pipelineId}
        sessionId={sessionId}
        stepId={stepId}
        tinybirdToken={tinybirdToken}
        onSelect={(error) => setSelectedError(error)}
      />
    </SessionLayout>
  );
};
