import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, IAMGroupDetails as Details } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMGroupDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { groupName } = useParams<'groupName'>();

  const { data: group } = useAwsGetter('IAM', 'getGroup', [{ GroupName: groupName }], { clientOverrides });

  return (
    <Layout
      documentTitle="IAM: Group Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Group Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Groups', () => goto(routes.RESOURCES_IAM_GROUPS)],
              [group?.Group?.GroupName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_IAM_GROUP_UPDATE, { groupName })}
        >
          Edit Group
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details group={group?.Group} />
        </CardContent>
      </Card>
    </Layout>
  );
};
