import { ReactElement } from 'react';
import { Pipeline, PipelineRunningSession } from '@localstack/types';
import { formatDateTime } from '@localstack/services';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@mui/material';

import { StatusLabel } from './StatusLabel';

export interface CurrentlyActiveSessionsTableProps {
  pipelines: Record<string, Pipeline>;
  sessions: PipelineRunningSession[];
  onShowPipeline?: (projectId: string, pipelineId: string) => unknown;
}

export const CurrentlyActiveSessionsTable = (
  { pipelines, sessions, onShowPipeline }: CurrentlyActiveSessionsTableProps,
): ReactElement => (
  <DataGrid
    autoHeight
    density="compact"
    columns={[
      { field: 'id', headerName: 'Session ID', minWidth: 300 },
      {
        field: 'pipeline_id',
        headerName: 'Pipeline',
        minWidth: 300,
        renderCell: ({ value, row }) => (
          <Link href="#" onClick={(e) => {e.preventDefault(); onShowPipeline?.(row.project_id, value);}}>
            {pipelines[value]?.name || value}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        renderCell: ({ value }) => (
          <StatusLabel status={value} />
        ),
      },
      {
        field: 'created',
        headerName: 'Started At',
        minWidth: 200,
        valueFormatter: (params) => formatDateTime(params.value as number),
      },
    ]}
    rows={sessions ?? []}
  />
);
