import { ReactElement, Suspense } from 'react';
import { ProjectsService, useApiGetter } from '@localstack/services';

import { SessionLayout } from '../layouts';

import { StepsContainer } from './StepsContainer';

export interface SessionContainer {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  onStepChange?: (projectId: string, pipelineId: string, sessionId: string, stepId: string) => unknown;
}

export const SessionContainer = ({
  projectId,
  pipelineId,
  sessionId,
  onStepChange,
}: SessionContainer): ReactElement => {
  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <SessionLayout>
      <Suspense fallback={<div>Loading...</div>}>
        <StepsContainer
          sessionId={sessionId}
          projectId={projectId}
          pipelineId={pipelineId}
          tinybirdToken={tinybirdToken}
          onStepChange={onStepChange}
        />
      </Suspense>
    </SessionLayout>
  );
};
