import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMGroupsTable, PageTitle } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMGroups = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: groups, isLoading, mutate } = useAwsGetter('IAM', 'listGroups', [], { clientOverrides });

  const { deleteGroup } = useAwsEffect(
    'IAM',
    ['deleteGroup'],
    { revalidate: ['getGroup', 'listGroups'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((GroupName) => deleteGroup({ GroupName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle='IAM: Groups'
      title={
        <PageTitle
          title="IAM Groups"
          breadcrumbs={[
            ['IAM', () => goto(routes.RESOURCES_IAM)],
            ['Groups', () => goto(routes.RESOURCES_IAM_GROUPS)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_GROUP_CREATE)}>
            Create Group
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Group(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Groups will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <IAMGroupsTable
          selectable
          loading={isLoading}
          groups={groups?.Groups || []}
          onSelect={setSelectedIds}
          onViewGroup={
            ({ GroupName }) => goto(routes.RESOURCES_IAM_GROUP, { groupName: GroupName })
          }
        />
      </Card>
    </Layout>
  );
};
