import { useState, useMemo, ReactElement } from 'react';
import {
  Alert, AlertTitle,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Link,
  FormControlLabel,
  Switch,
  Typography,
  Button,
} from '@mui/material';
import { Permission, MembershipRole, MembershipState, Subscription, ApiKey } from '@localstack/types';
import { SUB_ACTIVE_STATUSES, ExternalLink } from '@localstack/constants';
import { ApiKeysList, ConfirmableButton, LoadingFragment, NavLink } from '@localstack/ui';
import { OpenInNew as OpenInNewIcon, Add as CreateIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  formatDate,
  useApiEffect,
  useRoutes,
  useApiGetter,
  SubscriptionService,
  buildRoute,
  computeNumLicensesUsed,
  OrganizationsService,
} from '@localstack/services';


import { useAuthProvider } from '~/hooks';
import { AppRoute } from '~/config';

const useStyles = makeStyles(() => createStyles({
  subscriptionLink: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: '1rem',
    },
  },

  callout: {
    '& p ~ ul': {
      marginTop: '0.5em',
    },
    '& li': {
      marginLeft: '2em',
    },
  },
}));

export const ApiKeys = (): ReactElement => {
  const classes = useStyles();

  const [revealOwnKeys, setRevealOwnKeys] = useState(false);
  const [revealSharedKeys, setRevealSharedKeys] = useState(false);

  const { goto } = useRoutes();
  const { can, userInfo } = useAuthProvider();

  const { data: keys, isLoading: isApiKeysLoading } = useApiGetter(
    SubscriptionService, 'listKeys', [false],
  );

  const { updateKey, issueUserKey, isLoading: isUpdateKey, createKey, deleteKey } =
    useApiEffect(SubscriptionService,
      ['updateKey', 'issueUserKey', 'createKey', 'deleteKey'],
      {
        revalidate: ['listKeys'],
        revalidateOtherClient: {
          client: OrganizationsService,
          methods: ['preflightSelfAssignLicense'],
        },
      });

  const { data: subscriptions, isLoading: isSubsLoading } = useApiGetter(SubscriptionService, 'listSubscriptions', []);

  const isKeysLoading = isUpdateKey || isApiKeysLoading;

  const user = userInfo?.user;
  const organization = userInfo?.org;

  const isLoading = isKeysLoading || isSubsLoading;
  const isUserManaged = organization && user && organization.creator !== user.id;

  const { data: licenses } = useApiGetter(
    OrganizationsService,
    'listLicenseAssignments',
    [organization?.id || ''],
    { enable: !!organization && can(Permission.LIST_LICENSES) },
  );

  const organizationAdmins = useMemo(
    () => (organization?.members || []).filter(
      ({ roles, state }) => roles.includes(MembershipRole.ADMIN) && state === MembershipState.ACTIVE,
    ),
    [organization],
  );

  const members = useMemo(() => (organization?.members || []).filter((m) => m.state !== MembershipState.REMOVED),
    [organization?.members],
  );

  const numActiveSubscriptions = (subscriptions || []).filter((sub) => SUB_ACTIVE_STATUSES.includes(sub.status)).length;
  const hasActiveSubscriptions = numActiveSubscriptions > 0;

  const keysSubsMap = useMemo(() => {
    const keysSorter = (lk: ApiKey, rk: ApiKey) => {
      const leftName = lk.name || '';
      const rightName = rk.name || '';

      if (leftName < rightName) return -1;
      if (leftName > rightName) return 1;
      return 0;
    };

    const pairs: [Subscription, ApiKey[]][] = (subscriptions ?? []).filter(({ status }) =>
      SUB_ACTIVE_STATUSES.includes(status))
      .reduce(
        (memo, sub) => [...memo, [sub, keys?.org.filter((key) => key.subscription_id === sub.id) || []]],
        [] as [Subscription, ApiKey[]][],
      );

    const sortedPairs: [Subscription, ApiKey[]][] = pairs
      .sort((lp, rp) => lp[0].start_date - rp[0].start_date)
      .map((pair) => [pair[0], pair[1].sort(keysSorter)]);

    return new Map(sortedPairs);
  }, [keys, subscriptions]);

  const canSelfServeKey =
    can(Permission.CREATE_SELF_KEYS)
    && (keys?.shared?.length ?? 0) === 0
    && !can(MembershipRole.ADMIN);

  const numNonCIKeysRemainingToCreate = (subscription: Subscription): number => {
    if (!subscription || !licenses || !keys) {
      return 0;
    }

    const licensesUsed = computeNumLicensesUsed(subscription, licenses, keys.org, members);

    if (licensesUsed === undefined) {
      return 0;
    }

    return subscription.seats - licensesUsed;
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Alert severity="warning" className={classes.callout}>
          <AlertTitle>Deprecating API Keys</AlertTitle>
          <p>With the introduction of the new auth token, we are deprecating regular API keys used for individual
            developers.</p>
          <ul>
            <li>
              <b>As a user</b>, you can find your auth token and instructions on how to set it up
              on the <NavLink to={AppRoute.GETTING_STARTED} underline="hover">Getting Started</NavLink> page.
            </li>
            <li>
              <b>As a workspace admin</b>, you can assign licenses to users on
              the <NavLink to={AppRoute.WORKSPACE_MEMBERS} underline="hover">Users & Licenses</NavLink> page.
            </li>
            <li>
              API keys used to run <b>LocalStack in CI</b> or other machine contexts can be found
              on the <NavLink to={AppRoute.WORKSPACE_LEGACY_CI_KEYS}>CI Keys</NavLink> page.
              on the <NavLink to={AppRoute.WORKSPACE_LEGACY_CI_KEYS}>CI Keys</NavLink> page.
            </li>
          </ul>
        </Alert>
      </Grid>

      <Grid item md={12}>
        <Alert severity="info">
          <AlertTitle>How to use API Keys</AlertTitle>
          Configure your environment using the API key(s) listed below. Check
          out our{' '}
          <Link target="__blank" href={ExternalLink.DOCS_PRO} underline="hover">
            documentation{' '}
          </Link>
          for more details.
        </Alert>
      </Grid>

      {can(Permission.READ_ORG_KEYS) && (
        <Grid item md={6} sm={12}>
          <Card>
            <CardHeader
              title="API Keys in this Workspace"
              action={
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={revealOwnKeys}
                      onChange={(_, checked: boolean) =>
                        setRevealOwnKeys(checked)
                      }
                    />
                  }
                  label="Display Keys"
                />
              }
            />

            <CardContent>
              {!isLoading && !hasActiveSubscriptions && (
                <Alert severity="info" variant="outlined">
                  There are currently no active subscriptions in your workspace.
                  {' '}{organization &&
                    (<Link onClick={() => goto(AppRoute.PRICING)} underline="hover">Start a new plan.</Link>)}
                </Alert>
              )}
              <LoadingFragment loading={isApiKeysLoading} height={40} size={3}>
                {Array.from(keysSubsMap.keys()).map((sub) => (
                  <Box key={sub.id} mb={3}>
                    {(numActiveSubscriptions > 1) && (
                      <>
                        <NavLink
                          variant="subtitle1"
                          color="textPrimary"
                          className={classes.subscriptionLink}
                          to={buildRoute(AppRoute.WORKSPACE_SUBSCRIPTION_DETAIL, { subscriptionId: sub.id })}
                        >
                          {sub.plan?.name} <OpenInNewIcon />
                        </NavLink>
                        <Typography variant="caption" paragraph>
                          Started {formatDate(sub.start_date)},{' '}
                          {sub.cancel_at && !sub.cancel_at_period_end && (
                            <>expires at {formatDate(sub.cancel_at as number)}</>
                          )}
                          {sub.cancel_at_period_end && !sub.cancel_at && (
                            <>expires at {formatDate(sub.current_period_end)}</>
                          )}
                          {sub.cancel_at_period_end && sub.cancel_at && (
                            <>expires at {formatDate(sub.current_period_end)}</>
                          )}
                          {!sub.cancel_at_period_end && !sub.cancel_at && (
                            <>renews after {formatDate(sub.current_period_end as number)}</>
                          )}
                        </Typography>
                      </>
                    )}
                    <ApiKeysList
                      editable={can(Permission.UPDATE_ORG_KEYS)}
                      deletable={can(Permission.DELETE_ORG_KEYS)}
                      onDelete={deleteKey}
                      reveal={revealOwnKeys}
                      assignable
                      keys={(keysSubsMap.get(sub) ?? []).filter(key => !key.is_ci)}
                      members={members}
                      onSave={updateKey}
                      onAssign={(apiKey, selectedUser) => updateKey(apiKey.id, { member_email: selectedUser })}
                    />
                    <ConfirmableButton
                      fullWidth
                      componentType='ProgressButton'
                      title='Deprecating legacy API Keys'
                      text='Instead of creating API keys we recommend assigning licenses
                        to users on the &quot;Users &amp; Licenses&quot; page.'
                      startIcon={<CreateIcon />}
                      color="primary"
                      disabled={!(numNonCIKeysRemainingToCreate(sub) > 0)}
                      loading={isUpdateKey}
                      onClick={() => createKey({ subscription_id: sub.id })}
                    >
                      Create API Key ({numNonCIKeysRemainingToCreate(sub)} left)
                    </ConfirmableButton>
                  </Box>
                ))}
              </LoadingFragment>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid item md={can(Permission.READ_ORG_KEYS) ? 6 : 12} sm={12}>
        <Card>
          <CardHeader
            title="API Keys assigned to me"
            action={
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={revealSharedKeys}
                    onChange={(_, checked: boolean) =>
                      setRevealSharedKeys(checked)
                    }
                  />
                }
                label="Display Keys"
              />
            }
          />
          <CardContent>
            {!isLoading && canSelfServeKey && (
              <Box mb={2}>
                <Alert
                  severity="info"
                  variant="outlined"
                  action={
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={issueUserKey}
                      disabled={isUpdateKey}
                    >
                      Create
                    </Button>
                  }
                >
                  Your workspace owner enabled self-issued API Keys.
                  You can issue an API key for yourself by clicking the button.
                </Alert>
              </Box>
            )}
            {!isLoading && keys?.shared.length === 0 && (
              <Alert severity="info" variant="outlined">
                There are currently no keys assigned to your user account.
                {isUserManaged && organizationAdmins.length > 0 && (
                  <>
                    <Box mt={1}>
                      Please reach out to one of your workspace admins to assign API keys to your account.
                    </Box>
                    <Box mt={1}>
                      {organizationAdmins.map((admin) => (
                        <Box key={admin.id}>
                          <Link href={`mailto:${admin.email}`} underline="hover">
                            {admin.name} {`<${admin.email}>`}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </Alert>
            )}
            <LoadingFragment loading={isKeysLoading} height={40} size={3}>
              {(keys?.shared.length || 0) > 0 && (
                <ApiKeysList
                  editable={false}
                  reveal={revealSharedKeys}
                  keys={keys?.shared ?? []}
                />
              )}
            </LoadingFragment>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ApiKeys;
