import { ReactElement } from 'react';
import { Add as AddIcon, Details as DetailsIcon, ViewStream as PipelinesIcon } from '@mui/icons-material';
import { buildRoute, ProjectsService, useApiGetter, useRoutes } from '@localstack/services';
import { useMatch } from 'react-router-dom';

import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';

import { AppRoute } from '~/config';

export interface ProjectConfigurationMenuProps {
  projectId?: string;
  pipelineId?: string;
}

const PipelineButton = ({ id, projectId, name }: { id: string, projectId: string, name: string }): ReactElement => {
  const { goto } = useRoutes();

  const pipelineRoute = AppRoute.PIPELINE_UPDATE.replace(':projectId', projectId).replace(':pipelineId', id);
  const isPipelineActive = !!useMatch(pipelineRoute);

  return (
    <ListItemButton
      key={id}
      sx={{ pl: 4 }}
      selected={isPipelineActive}
      onClick={() => goto(AppRoute.PIPELINE_UPDATE, { projectId, pipelineId: id })}
    >
      <ListItemText primary={name} />
    </ListItemButton>
  );
};

export const LoadingProjectConfigurationMenu = (): ReactElement => (
  <List>
    <ListItemButton>
      <ListItemIcon>
        <DetailsIcon />
      </ListItemIcon>
      <ListItemText primary={<Skeleton width={150} />} />
    </ListItemButton>
    <ListItem>
      <ListItemIcon>
        <PipelinesIcon />
      </ListItemIcon>
      <ListItemText primary={<Skeleton width={150} />} />
    </ListItem>
  </List>
);

export const ProjectConfigurationMenu = ({ projectId }: ProjectConfigurationMenuProps): ReactElement => {
  const { goto } = useRoutes();

  const { data: project } = useApiGetter(
    ProjectsService,
    'getProject',
    [projectId],
    { enable: !!projectId, swrOverrides: { suspense: !!projectId } },
  );

  const { data: pipelines } = useApiGetter(
    ProjectsService,
    'listPipelines',
    [projectId],
    { enable: !!projectId, swrOverrides: { suspense: !!projectId } },
  );

  const projectCreateRoute = buildRoute(AppRoute.PROJECT_CREATE);
  const projectUpdateRoute = buildRoute(AppRoute.PROJECT_UPDATE, { projectId });

  const isProjectCreateActive = !!useMatch(projectCreateRoute);
  const isProjectUpdateActive = !!useMatch(projectUpdateRoute);

  return (
    <List>
      <ListItemButton
        selected={isProjectCreateActive || isProjectUpdateActive}
        onClick={() => {
          if (projectId) {
            goto(AppRoute.PROJECT_UPDATE, { projectId });
          } else {
            goto(AppRoute.PROJECT_CREATE);
          }
        }}
      >
        <ListItemIcon>
          <DetailsIcon />
        </ListItemIcon>
        <ListItemText primary="Basic Details" />
      </ListItemButton>
      {project && (
        <>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => goto(AppRoute.PIPELINE_CREATE, { projectId })}
              >
                <AddIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <PipelinesIcon />
            </ListItemIcon>
            <ListItemText primary="Pipelines" />
          </ListItem>
          <List component="div" disablePadding>
            {(pipelines ?? []).map((p) => (
              <PipelineButton key={p.id} id={p.id as string} projectId={project.id as string} name={p.name} />
            ))}
          </List>
        </>
      )}
    </List>
  );
};
