import { ReactElement, useMemo } from 'react';
import { ProjectsService, useApiGetter } from '@localstack/services';
import { Pipeline } from '@localstack/types';
import { Grid } from '@mui/material';

import {
  PlayCircleOutline as CurrentlyActiveSessionsIcon,
  History as PastExecutionsIcon,
  AccessTime as PipelinesExecutionTimeIcon,
  Error as PipelineTopFailingServicesIcon,
} from '@mui/icons-material';

import { SessionLayout } from '../layouts';
import { ProjectStatisticsCard, TableSkeleton } from '../components';

import { CurrentlyActiveSessionsContainer } from './CurrentlyActiveSessionsContainer';
import { PipelineSessionsContainer } from './PipelineSessionsContainer';

import {
  PipelinesExecutionTimeContainer,
  LoadingPipelinesExecutionTimeContainer,
} from './PipelinesExecutionTimeContainer';

import {
  PipelineTopFailingServicesContainer,
  LoadingPipelineTopFailingServicesContainer,
} from './PipelineTopFailingServicesContainer';

export interface PipelineContainerProps {
  projectId: string;
  pipelineId: string;
  onShowPipeline?: (projectId: string, pipelineId: string) => unknown;
}

export const PipelineContainer = ({ projectId, pipelineId, onShowPipeline }: PipelineContainerProps): ReactElement => {
  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const { data: pipelines } = useApiGetter(
    ProjectsService,
    'listPipelines',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const pipelinesMap: Record<string, Pipeline> = useMemo(() => {
    if (!pipelines) return {};
    return pipelines.reduce((acc, pipeline) => ({ ...acc, [pipeline.id as string]: pipeline }), {});
  }, [pipelines]);

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  return (
    <SessionLayout
      details={
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ProjectStatisticsCard
              title="Pipeline Execution Time"
              avatar={<PipelinesExecutionTimeIcon />}
              suspenseFallback={<LoadingPipelinesExecutionTimeContainer />}
            >
              <PipelinesExecutionTimeContainer
                hideSidebar
                tinybirdToken={tinybirdToken}
                pipelines={pipelines ?? []}
                pipelineId={pipelineId}
              />
            </ProjectStatisticsCard>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ProjectStatisticsCard
              title="Top Failing Services"
              avatar={<PipelineTopFailingServicesIcon />}
              suspenseFallback={<LoadingPipelineTopFailingServicesContainer />}
            >
              <PipelineTopFailingServicesContainer
                tinybirdToken={tinybirdToken}
                pipelineId={pipelineId}
              />
            </ProjectStatisticsCard>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ProjectStatisticsCard
            title="Currently Active Sessions"
            avatar={<CurrentlyActiveSessionsIcon />}
            suspenseFallback={<TableSkeleton />}
          >
            <CurrentlyActiveSessionsContainer
              projectId={projectId}
              pipelineId={pipelineId}
              pipelines={pipelinesMap}
              onShowPipeline={onShowPipeline}
            />
          </ProjectStatisticsCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ProjectStatisticsCard
            title="Past Sessions"
            avatar={<PastExecutionsIcon />}
            suspenseFallback={<TableSkeleton />}
          >
            <PipelineSessionsContainer
              tinybirdToken={tinybirdToken}
              pipelineId={pipelineId}
              pipelines={pipelinesMap}
            />
          </ProjectStatisticsCard>
        </Grid>
      </Grid>
    </SessionLayout>
  );
};
