import { ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { SessionsErrorQueryResult } from '../types';
import { useTinybirdPipe } from '../hooks';

export interface StepErrorsContainerProps {
  pipelineId: string;
  sessionId: string;
  stepId: string;
  tinybirdToken: string;
  onSelect?: (error: SessionsErrorQueryResult) => unknown;
}

export const StepErrorsContainer = ({
  pipelineId,
  sessionId,
  stepId,
  tinybirdToken,
  onSelect,
}: StepErrorsContainerProps): ReactElement => {
  const { data: sessionErrors } = useTinybirdPipe<SessionsErrorQueryResult[]>(
    'session_step_errors_pipe',
    tinybirdToken,
    { pipeline_id: pipelineId, session_id: sessionId, step_id: stepId },
  );

  const sessionErrorsWithId = sessionErrors?.map(
    (log, i) => ({ ...log, id: i }),
  );

  return (
    <DataGrid
      autoHeight
      density="compact"
      rows={sessionErrorsWithId ?? []}
      pageSize={Math.min(sessionErrorsWithId.length, 100)}
      onSelectionModelChange={(newSelection) => {
        onSelect?.(sessionErrorsWithId[newSelection[0] as number] as SessionsErrorQueryResult);
      }}
      disableExtendRowFullWidth
      style={{
        flexGrow: 1,
        overflow: 'auto',
      }}
      columns={[
        { field: 'error_type', headerName: 'Type', width: 100 },
        { field: 'error_message', headerName: 'Message', minWidth: 500 },
        { field: 'time', headerName: 'Time', type: 'dateTime', minWidth: 150 },
      ]}
    />
  );
};
