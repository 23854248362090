import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';

import { useTinybirdPipe } from '../hooks';
import { FailingTestQueryResult } from '../types';
import { CurrentlyFailingTestsTable } from '../components';

export interface CurrentlyFailingTestsContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
}

export const CurrentlyFailingTestsContainer = (
  { tinybirdToken, pipelines }: CurrentlyFailingTestsContainerProps,
): ReactElement => {
  const { data: currentlyFailingTests } = useTinybirdPipe<FailingTestQueryResult[]>(
    'currently_failing_tests_pipe', tinybirdToken,
  );

  return (
    <CurrentlyFailingTestsTable
      pipelines={pipelines}
      currentlyFailingTests={currentlyFailingTests}
    />
  );
};
