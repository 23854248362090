import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';

import { useTinybirdPipe } from '../hooks';
import { FlakyTestQueryResult } from '../types';
import { FlakyTestsTable } from '../components';

export interface FlakyTestsContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
}

export const FlakyTestsContainer = (
  { tinybirdToken, pipelines }: FlakyTestsContainerProps,
): ReactElement => {
  const { data: flakyTests } = useTinybirdPipe<FlakyTestQueryResult[]>(
    'flaky_tests_pipe', tinybirdToken,
  );

  return (
    <FlakyTestsTable
      pipelines={pipelines}
      flakyTests={flakyTests ?? []}
    />
  );
};
