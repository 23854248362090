import { useEffect, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES } from '@localstack/services';
import { Project } from '@localstack/types';
import { Grid, TextField } from '@mui/material';
import { ControlledTextField } from '@localstack/ui';

export interface ProjectFormProps {
  formId: string;
  project?: Optional<Project>;
  onFormValidationChange?: (valid: boolean) => unknown;
  onSubmitCreate?: (data: Project) => unknown;
  onSubmitUpdate?: (id: string, data: Project) => unknown;
}

export const ProjectForm = ({
  project,
  onSubmitCreate,
  onSubmitUpdate,
  onFormValidationChange,
  formId,
}: ProjectFormProps): ReactElement => {
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    reset,
  } = useForm<Project>({ mode: 'all' });

  const onSubmit = async (data: Project) => {
    if (project) {
      await onSubmitUpdate?.(project.id as string, data);
    } else {
      await onSubmitCreate?.(data);
    }
  };

  useEffect(() => {
    reset();

    if (!project) {
      return;
    }

    Object.entries(project).forEach(([key, value]) => {
      setValue(key as keyof Project, value, { shouldValidate: true });
    });
  }, [project]);

  useEffect(() => {
    onFormValidationChange?.(formState.isValid);
  }, [formState.isValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <Grid container spacing={2}>
        {project && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              required
              disabled
              label='Project ID'
              type='text'
              variant='outlined'
              value={project.id ?? ''}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ControlledTextField
            control={control}
            name='name'
            fullWidth
            required
            label='Project Name'
            type='text'
            variant='outlined'
            rules={VALIDATION_RULES.required}
          />
        </Grid>
      </Grid>
    </form>
  );
};
