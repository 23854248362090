import { ReactElement, useState } from 'react';
import { AwsServiceIcon, ProgressButton } from '@localstack/ui';
import { ExternalLink } from '@localstack/constants';
import { Alert, AlertTitle ,
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Link,
  Typography,
  Chip,
} from '@mui/material';
import { formatBytes } from '@localstack/services';
import { CloudPodMetadata } from '@localstack/types';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) => createStyles({
  error: {
    background: theme.palette.error.main,
  },
  terminalOutput: {
    display: 'block',
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    fontFamily: 'monospace',
    fontSize: '0.8rem',
    padding: theme.spacing(1),
    whiteSpace: 'pre-line',
    borderRadius: theme.shape.borderRadius,
  },
}));

interface PodsCardProps {
  podsMetadata: CloudPodMetadata & { url: string },
  onInject: () => void,
  injectError: boolean,
  isInjecting?: boolean;
  isInjected?: boolean;
  logOutput: string,
}

export const PodCard = ({
  podsMetadata,
  onInject,
  injectError,
  isInjecting,
  isInjected,
  logOutput,
}: PodsCardProps): ReactElement => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const injectPod = () => {
    onInject();
    setExpanded(true);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" component="div">
          <Box display="flex" justifyContent="space-between">
            <span>{podsMetadata.name}</span>
            {isInjected && !injectError && <Chip label="Loaded" color="primary" size="small" />}
            {injectError && (
              <Chip label="Failed" color="primary" size="small" className={classes.error} />
            )}
          </Box>
        </Typography>
        <Typography variant='body1' color="textSecondary">
          Size: {formatBytes(parseInt(podsMetadata.size, 10))}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant='body1' color="textSecondary">
            Services:
          </Typography>
          {podsMetadata.services &&
            podsMetadata.services.map((code: string) => (
              <AwsServiceIcon code={code} key={code} />
            ))
          }
        </Box>
        {podsMetadata.url && <Typography variant='body1' color="textSecondary">Url: {podsMetadata.url}</Typography>}

        <Alert severity='warning' style={{ marginTop: '1rem' }}>
          <AlertTitle>
            External Source
          </AlertTitle>
          This pod is loaded from an external source and could potentially contain malicious code.
          Only load this pod if you trust this source. Read more on pods security implications
          {' '}
          <Link
            href={ExternalLink.DOCS_SECURITY_LAUNCHPAD}
            target="blank"
            underline="hover"
          >
            here
          </Link>.
        </Alert>
      </CardContent>
      {!isInjected && !injectError && (
        <CardActions style={{ justifyContent: 'flex-start' }}>
          <ProgressButton variant="contained" color="primary"
            onClick={injectPod}
            loading={isInjecting}
          >
            Load
          </ProgressButton>
        </CardActions>
      )}
      <Collapse in={expanded} timeout={{ enter: 500 }}>
        <CardContent>
          <Typography className={classes.terminalOutput} style={{ color: injectError ? 'red' : 'lightgreen' }}>
            {logOutput}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};
