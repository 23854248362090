import { ReactElement, useMemo } from 'react';
import { Box, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Pipeline } from '@localstack/types';

import { PipelineExecutionTimeQueryResult } from '../types';

const buildColorFromPipelineName = (name: string) => {
  const hash = name.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
  const hue = hash % 360;
  return `hsl(${hue}, 50%, 50%)`;
};

const groupPipelinesExecutionTimeByDate = (pipelinesExecutionTime: PipelineExecutionTimeQueryResult[]) => {
  const formattedEntries: { [k: string]: number | string }[] = [];
  const formattedDates: Record<string, number> = {}; // O(1) "hash table" for O(1) date-to-index lookups

  // eslint-disable-next-line no-restricted-syntax
  for (const exec of pipelinesExecutionTime) {
    const dateIndex = formattedDates[exec.execution_date];

    if (dateIndex === undefined) {
      formattedEntries.push({
        execution_date: exec.execution_date,
        [exec.pipeline_id]: exec.max_execution_time_seconds,
      });
      formattedDates[exec.execution_date] = formattedEntries.length - 1;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formattedEntries[dateIndex]![exec.pipeline_id] = exec.max_execution_time_seconds;
    }
  }

  return formattedEntries;
};

export interface PipelinesExecutionTimeChartProps {
  pipelines: Pipeline[];
  displayedPipelines: string[];
  pipelinesExecutionTime: PipelineExecutionTimeQueryResult[];
  hideSidebar?: boolean;
  onDisplayPipeline: (pipelineId: string) => void;
  onHidePipeline: (pipelineId: string) => void;
}

export const PipelinesExecutionTimeChart = ({
  pipelines,
  displayedPipelines,
  pipelinesExecutionTime,
  hideSidebar,
  onDisplayPipeline,
  onHidePipeline,
}: PipelinesExecutionTimeChartProps): ReactElement => {
  const groupedPipelinesExecutionTime = useMemo(
    () => groupPipelinesExecutionTimeByDate(pipelinesExecutionTime),
    [pipelinesExecutionTime],
  );

  return (
    <Box display="flex" width="100%">
      <Box width={hideSidebar ? '100%' : '70%'}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={[...groupedPipelinesExecutionTime].reverse()}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="execution_date" />
            <YAxis />
            <Tooltip />
            {(pipelines ?? []).map((pipeline) => (
              <Line
                key={pipeline.id}
                type="monotone"
                name={pipeline.name}
                dataKey={pipeline.id}
                stroke={buildColorFromPipelineName(pipeline.name)}
                hide={!displayedPipelines.includes(pipeline.id as string)}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box width="30%" display={hideSidebar ? 'none' : 'block'}>
        <List dense>
          {(pipelines ?? []).map((pipeline) => (
            <ListItemButton
              key={pipeline.id}
              selected={displayedPipelines.includes(pipeline.id as string)}
              onClick={() => {
                if (displayedPipelines.includes(pipeline.id as string)) {
                  onHidePipeline(pipeline.id as string);
                } else {
                  onDisplayPipeline(pipeline.id as string);
                }
              }}
            >
              <ListItemAvatar>
                <Box
                  width={20}
                  height={20}
                  borderRadius="50%"
                  bgcolor={buildColorFromPipelineName(pipeline.name)}
                />
              </ListItemAvatar>
              <ListItemText
                primary={pipeline.name}
                secondary={`${groupedPipelinesExecutionTime?.[0]?.[pipeline.id as string]}s`}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
};
