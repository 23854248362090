import { ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pipeline } from '@localstack/types';

import { FlakyTestQueryResult } from '../types';

import { PipelineNameToggle } from './PipelineNameToggle';

export interface FlakyTestsTableProps {
  pipelines: Record<string, Pipeline>;
  flakyTests: FlakyTestQueryResult[];
}

export const FlakyTestsTable = (
  { pipelines, flakyTests }: FlakyTestsTableProps,
): ReactElement => {
  const flakyTestsWithId = flakyTests.map((test, i) => ({
    ...test,
    id: `${test.pipeline_id}-${test.step_id}-${i}`,
  }));

  return (
    <DataGrid
      autoHeight
      density="compact"
      columns={[
        {
          field: 'pipeline_id',
          headerName: 'Pipeline',
          minWidth: 300,
          renderCell: ({ value }) => (
            <PipelineNameToggle
              pipelineId={value}
              pipelineName={pipelines[value]?.name}
            />
          ),
        },
        { field: 'step_id', headerName: 'Test', minWidth: 500 },
        { field: 'duration_seconds', headerName: 'Duration', minWidth: 50 },
      ]}
      rows={flakyTestsWithId}
    />
  );
};
