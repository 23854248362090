export type AccountRegionUsageEntry = {
  region_name: string,
  account_id: string,
}
export type ResourceAggregationType = AccountRegionUsageEntry & {
  resource_type: string,
  id: string,
}

export type ServiceResourceAggregationObj = {
  [key: string]: ResourceAggregationType[]
}
