import {
  MagicExternalFields, MagicFormInjectedFields,
  ResolvedMagicSchema, ForbiddenValuesType,
  MagicFieldConditions,
} from '@localstack/types';
import get from 'lodash/get';

import { capitalise } from './format';

const transformMagicFieldDocumentation = <T extends Optional<string>>(docString: T): T => {
  if (!docString) return docString;
  return docString.replace(/<\/?p[^>]*>/g, '') as T;
};

export const transformMagicFieldName = (name: Optional<string>): string => {
  if (!name) return '';
  return capitalise(name).replace('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2');
};

/**
 * Resolve the final type of the given field.
 * @param field - current field schema
 * @param schema - full schema
 * @param name - starting name of the field
 * @param required - whether the field is required
 * @returns {ResolvedMagicSchema}
 */
export const resolveMagicType = (
  field: any, // eslint-disable-line
  schema: any, // eslint-disable-line
  name?: string,
  required = false,
): ResolvedMagicSchema => {
  const { type } = field;
  const documentation = transformMagicFieldDocumentation(field.documentation);

  // TODO: fix the self-referencing issue. More details here: https://github.com/localstack/localstack-web/issues/733
  if (field.shape === 'Mappings') return { ...field, name, required, documentation, type: 'string' };
  if (field.type === 'structure') {
    const requiredFields = field.required || [];
    return {
      name,
      type,
      documentation,
      members: Object.entries(field.members || {}).map(
        ([fieldName, params]) => resolveMagicType(params, schema, fieldName, requiredFields.includes(fieldName)),
      ),
    };
  }

  if (field.shape) {
    return resolveMagicType({ ...schema.shapes[field.shape], documentation }, schema, name, required);
  }

  if (['double', 'float', 'integer', 'boolean', 'long', 'string', 'timestamp', 'blob'].includes(field.type)) {
    return { ...field, name, required, documentation };
  }

  if (field.type === 'list' && field.member.shape) {
    const shape = schema.shapes[field.member.shape];
    const resolvedType = resolveMagicType(shape, schema, name);

    return {
      ...resolvedType,
      type: `listOf${capitalise(resolvedType.type)}` as 'listOfString',
      required,
      documentation,
    };
  }

  if (field.type === 'map' && field.key.shape && field.value.shape) {
    const valueShape = schema.shapes[field.value.shape];
    const valueType = resolveMagicType(valueShape, schema);

    return { ...valueType, name, type: `mapOf${capitalise(valueType.type)}` as 'mapOfString', required, documentation };
  }

  if (field.type === 'blob') {
    return { type: 'blob' };
  }

  throw new Error(`Don't know how to handle field ${JSON.stringify(field)}`);
};

export const getExternalMagicFieldRenderer = (
  name: Optional<string>,
  externals: Optional<(MagicExternalFields)>,
): Optional<(MagicExternalFields)[string]> => {
  const external = Object.entries(externals || {}).find(([field]) => new RegExp(field).test(name || ''));
  return external?.[1];
};

export const getFieldForbiddenValue = (
  name: string,
  forbiddenValues: ForbiddenValuesType,
): Optional<string | string[]> => {
  const forbiddenValue = Object.entries(forbiddenValues || {}).find(([field]) => new RegExp(field).test(name || ''));
  return forbiddenValue?.[1];
};

export const getInjectedFieldsRenderer = (
  name: Optional<string>,
  injectedFields: Optional<(MagicFormInjectedFields)>,
): Optional<(MagicFormInjectedFields)[string]> => {
  const item = Object.keys(injectedFields || {}).find(field => new RegExp(field).test(name || ''));
  return injectedFields && item ? injectedFields[item] : null;
};

export const processIdAttributes = <T extends string | string[]>(
  item: T,
  separator = ':-:',
): T extends string ? string[] : string =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof item === 'string' ? item.split(separator) : (item as unknown as []).join(separator) as any;

export const getMagicFieldVisibility = (
  name: Optional<string>,
  conditions: Optional<MagicFieldConditions>,
  values: any, // eslint-disable-line
): boolean => {
  if (Object.keys(conditions || {}).length === 0) return true;

  const matchedCondition = Object.entries(conditions || {})
    .find(([field]) => new RegExp(field).test(name || ''));

  if (!matchedCondition) return true;

  const [, condition] = matchedCondition;

  if (typeof condition === 'boolean') return condition;

  const [left, operator, right] = condition;

  const leftValue = left.startsWith('$') ? get(values, left.substring(1)) : left;
  const rightValue = typeof right === 'string' && right.startsWith('$') ? get(values, right.substring(1)) : right;

  if (operator === '===') return leftValue === rightValue;
  if (operator === '!==') return leftValue !== rightValue;
  if (operator === 'in') return rightValue.includes(leftValue);

  throw new Error(`Unknown operator ${operator}`);
};
