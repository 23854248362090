import { ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { SessionsLogQueryResult } from '../types';
import { useTinybirdPipe } from '../hooks';

import { LevelLabel } from '../components';

export interface StepLogsContainer {
  pipelineId: string;
  sessionId: string;
  stepId: string;
  tinybirdToken: string;
  onSelect?: (log: SessionsLogQueryResult) => unknown;
}

export const StepLogsContainer = ({
  pipelineId,
  sessionId,
  stepId,
  tinybirdToken,
  onSelect,
}: StepLogsContainer): ReactElement => {
  const { data: sessionLogs } = useTinybirdPipe<SessionsLogQueryResult[]>(
    'session_step_logs_pipe',
    tinybirdToken,
    { pipeline_id: pipelineId, session_id: sessionId, step_id: stepId },
  );

  const sessionLogsWithId = sessionLogs?.map((log, i) => ({ ...log, id: i }));

  return (
    <DataGrid
      autoHeight
      density="compact"
      rows={sessionLogsWithId ?? []}
      pageSize={Math.min(sessionLogsWithId.length, 100)}
      onSelectionModelChange={(newSelection) => {
        onSelect?.(sessionLogsWithId[newSelection[0] as number] as SessionsLogQueryResult);
      }}
      disableExtendRowFullWidth
      style={{
        flexGrow: 1,
        overflow: 'auto',
      }}
      columns={[
        {
          field: 'level',
          headerName: 'Level',
          width: 100,
          renderCell: (params) => (
            <LevelLabel level={params.value as string} />
          ),
        },
        { field: 'message', headerName: 'Message', minWidth: 500 },
        { field: 'time', headerName: 'Time', type: 'dateTime', minWidth: 150 },
        { field: 'origin', headerName: 'Origin', minWidth: 150 },
      ]}
    />
  );
};
