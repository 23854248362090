import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';
import { ProjectsService, useApiGetter } from '@localstack/services';

import { CurrentlyActiveSessionsTable } from '../components';

export interface CurrentlyActiveSessionsContainerProps {
  projectId: string;
  pipelineId?: string;
  pipelines: Record<string, Pipeline>;
  onShowPipeline?: (projectId: string, pipelineId: string) => unknown;
}

export const CurrentlyActiveSessionsContainer = (
  { projectId, pipelineId, pipelines, onShowPipeline }: CurrentlyActiveSessionsContainerProps,
): ReactElement => {
  const { data: sessions } = useApiGetter(
    ProjectsService,
    'listProjectRunningSessions',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const filteredSessions = (sessions ?? []).filter(
    (session) => !pipelineId || (session.pipeline_id === pipelineId),
  );

  return (
    <CurrentlyActiveSessionsTable
      pipelines={pipelines}
      sessions={filteredSessions}
      onShowPipeline={onShowPipeline}
    />
  );
};
