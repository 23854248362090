import { LocalStackEventType, observable, STORAGE_KEY_THEME, ThemeType } from '@localstack/integrations';
import { useApiGetter, useRoutes, UserService } from '@localstack/services';
import { 
  ConfirmableButton, 
  Dropdown, 
  DropdownProps,
  LogoRocket, 
  LoadingFragment,
} from '@localstack/ui';
import { KeyboardArrowDown, DarkMode, Laptop, LightMode } from '@mui/icons-material';
import { Divider, MenuItem, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ReactElement } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { AppRoute } from '~/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        paddingBottom: '0.5rem',
        maxWidth: '100%',
      },
      dropDownSection: { 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
        '& svg': {
          padding: '0.05rem',
        },
      },
      skeleton: {
        minWidth: theme.spacing(15),
      },
      dropDown: {
        minWidth: theme.spacing(30),
        marginLeft: theme.spacing(5),
      },
      dropDownArrow: {
        '.collapsed &': {
          display: 'none',
        },
      },
      globalSignOut: {
        color: theme.palette.error.main,
      },  
      fakeMenuItem: {
        border: '1px solid transparent',
        borderRadius: 8,  
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },  
      },
      dropdownMenuItem: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      themeToggler: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        width: '100%',
        '&:hover': { 
          backgroundColor: 'unset !important', 
          cursor: 'unset !important', 
          color: 'unset !important', 
          '& svg': {
            color: 'unset !important',
          },
        },
      },
      divider: {
        marginLeft: '-10px',
        marginRight: '-10px',
      },
    },
  ),
);

type WorkspaceSelectorProps = {
  menuItemClassName?: string;
  labelClassName?: string;
}

export const WorkspaceSelector = ({ menuItemClassName, labelClassName }: WorkspaceSelectorProps): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const currentTheme = localStorage.getItem(STORAGE_KEY_THEME);

  const handleChangeTheme = (theme: ThemeType) => {
    observable.notify({ eventType: LocalStackEventType.THEME_UPDATE, data: { theme } });
  };

  const { data: user, isLoading: isUserLoading } =
    useApiGetter(UserService, 'getUser', []);

  return (
    <div data-name="workspace selector" className={classes.root}>
      <Dropdown
        size="small"
        dropdownClassName={classes.dropDown}
        menuListProps={{ 
          disablePadding: true,
        }}
        renderButton={({ onClick }: DropdownProps) => (
          <MenuItem 
            className={menuItemClassName} 
            sx={{ maxWidth: '100%' }}
            onClick={onClick}
          >
            <div className={classes.dropDownSection}>
              <LogoRocket size='small'/>

              <LoadingFragment 
                loading={isUserLoading} 
                skeletonClassName={`${labelClassName} ${classes.skeleton}`} 
                size={1}
              >
                <Typography className={labelClassName} style={{ 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden',
                  textAlign: 'left',
                  fontWeight: 500,
                }}
                >
                  {user?.org.name}
                </Typography>
              </LoadingFragment>
              <KeyboardArrowDown
                className={classes.dropDownArrow} 
                fontSize='small' 
                opacity={0.5}
              />
            </div>
          </MenuItem>
        )}
      >
        <MenuItem 
          className={`${menuItemClassName} ${classes.dropdownMenuItem}`} 
          onClick={() => goto(AppRoute.WORKSPACE)}
        >
          Workspace Settings
        </MenuItem>
        <MenuItem 
          className={`${menuItemClassName} ${classes.themeToggler} ${classes.dropdownMenuItem}`}
          disableRipple
        >
          Theme
          <ToggleButtonGroup
            exclusive
            onChange={(_e, newValue) => newValue ? handleChangeTheme(newValue as ThemeType) : undefined}
            value={currentTheme}
          >
            <ToggleButton value={ThemeType.SYSTEM} size="xsmall">
              <Laptop fontSize='inherit'/>
            </ToggleButton>
            <ToggleButton value={ThemeType.LIGHT} size="xsmall">
              <LightMode fontSize='inherit' />
            </ToggleButton>
            <ToggleButton value={ThemeType.DARK} size="xsmall">
              <DarkMode fontSize='inherit'/>
            </ToggleButton>
          </ToggleButtonGroup>
        </MenuItem>
        <Divider className={classes.divider}/>
        <MenuItem 
          className={`${menuItemClassName} ${classes.dropdownMenuItem}`} 
          onClick={() => goto(AppRoute.SIGN_OUT)} 
        >
            Sign Out
        </MenuItem>
        <ConfirmableButton
          componentType="MenuItem"
          onClick={() => goto(AppRoute.GLOBAL_SIGN_OUT)}
          okText="Sign out"
          title="Sign out on all devices?"
          text='By performing this action you will sign out of your account on every device / browser.'
          className={`${classes.globalSignOut} ${menuItemClassName} ${classes.dropdownMenuItem}`}
        >
          Global Sign Out
        </ConfirmableButton>
      </Dropdown>
    </div>
  );
};
