import {
  Warning as WarningIcon,
  Error as MUIStatusIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';

export const STATUS_ICONS = {
  'warning': <WarningIcon sx={{ color: 'orange' }} fontSize='small' />,
  'success': <CheckCircleIcon sx={{ color: 'lightgray' }} fontSize='small' />,
  'error': <MUIStatusIcon sx={{ color: 'red' }} fontSize='small' />,
};
