import { ReactNode } from 'react';
import axios from 'axios';
import { max, unionBy } from 'lodash';
import { ResolvedMagicSchema } from '@localstack/types';
import { formatDateTime, resolveMagicType } from '@localstack/services';

import { GridColDef } from '../../../display';

// Array index corresponds to ASCII character code, wheres value - scaling ratio.
// TOOD: if we buy a license for X-GRID PRO we probably won't need this magic
const SYMBOL_WIDTH_SCALE_RATIO = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0.4405079, 0.4355859, 0.5586329, 0.8736329, 0.8736329,
  1.4002734, 1.0483595, 0.3002345, 0.5241797, 0.5241797, 0.6127734, 0.9179297,
  0.4355859, 0.5241797, 0.4355859, 0.4749609, 0.8736329, 0.8736329, 0.8736329,
  0.8736329, 0.8736329, 0.8736329, 0.8736329, 0.8736329, 0.8736329, 0.8736329,
  0.4355859, 0.4355859, 0.9203907, 0.9179297, 0.9203907, 0.8736329, 1.5971484,
  1.0483595, 1.0483595, 1.1369532, 1.1369532, 1.0483595, 0.9597657, 1.2230859,
  1.1369532, 0.4355859, 0.7875, 1.0483595, 0.8736329, 1.3116796, 1.1369532,
  1.2230859, 1.0483595, 1.2230859, 1.1369532, 1.0483595, 0.9597657, 1.1369532,
  1.0483595, 1.4864063, 1.0483595, 1.0483595, 0.9597657, 0.4355859, 0.5586329,
  0.4355859, 0.7505859, 0.8736329, 0.5241797, 0.8736329, 0.8736329, 0.7875,
  0.8736329, 0.8736329, 0.4355859, 0.8736329, 0.8736329, 0.3494532, 0.3789845,
  0.7875, 0.3494532, 1.3116796, 0.8736329, 0.8736329, 0.8736329, 0.8736329,
  0.5241797, 0.7875, 0.4355859, 0.8736329, 0.7875, 1.1369532, 0.7875, 0.7875,
  0.7875, 0.5586329, 0.4085157, 0.556172, 0.9277734,
];
const FONT_SIZE_BASE = 12;
const AVERAGE_SYMBOL_SCALE = 0.5279276315789471;
const MIN_COLUMN_WIDTH_PX = 100;
const COLUMN_PADDING = 80; // To account for sort/filter controls
const PRIMITIVE_TYPES = ['string', 'boolean', 'double', 'long', 'integer', 'timestamp'];

/**
 * Calculate the pixel width of a single character.
 */
const getCharWidth = (char: string): number =>
  (SYMBOL_WIDTH_SCALE_RATIO[char.charCodeAt(0)] ?? AVERAGE_SYMBOL_SCALE) *
  FONT_SIZE_BASE;

/**
 * Calculate the pixel width of a string.
 */
const getStringWidth = (str: string): number =>
  str.split('').reduce((acc, char) => acc + getCharWidth(char), 0);

/**
 * Calculate the ideal width of a DataGrid column so that values are not clipped, taking into account the width of the column names and field values.
 */
export function getColumnWidth<
  R extends Record<string, unknown>,
  C extends ResolvedMagicSchemaWithName
>(rows: R[], cols: C[], disableColumnMenu?: boolean): { [key: string]: number } {
  const getStringWidthWithPadding = (value: string) => getStringWidth(value) + (disableColumnMenu ? 0 : COLUMN_PADDING);
  const getCellWidths = (column: string, rs: R[]) => rs.map(row => getCellWidth(row[column]));
  const getCellWidth = (cell: unknown) => {
    if (cell instanceof Date) {
      return 200;
    }
    return typeof cell === 'string' ? getStringWidthWithPadding(cell) : 0;
  };

  type Widths = [string, number[]][];
  const minWidths = Object.values(cols).map(({ name }) => ([name, [MIN_COLUMN_WIDTH_PX]])) as Widths;
  const withCols = minWidths.map(([name, value]) => [name, [...value, getStringWidthWithPadding(name)]]) as Widths;
  const withCells = withCols.map(([name, value]) => [name, [...value, ...getCellWidths(name, rows)]]) as Widths;

  return withCells.reduce((acc, [name, value]) => ({ ...acc, [name]: max(value) }), {});
}

/**
 * ResolvedMagicSchema with a name prop that cannot be undefined.
 */
type ResolvedMagicSchemaWithName = ResolvedMagicSchema & Required<{ name: string }>;

/**
 * Extracts column names from a ResolvedMagicSchema and externalFields to include non-schema fields and remove duplicates
 */
export function getColumnNames(resolvedSchema?: ResolvedMagicSchema, externalFields?: ResolvedMagicSchemaWithName[]):
  ResolvedMagicSchemaWithName[] {
  return unionBy(resolvedSchema?.members || [], externalFields || [], 'name') as ResolvedMagicSchemaWithName[];
}

export function orderBy(order?: string[]) {
  return (
    { name: leftName }: ResolvedMagicSchemaWithName,
    { name: rightName }: ResolvedMagicSchemaWithName,
  ): number => {
    if (!order?.length) {
      return 0;
    }

    if (order.includes(leftName) && order.includes(rightName)) {
      return order.indexOf(leftName) - order.indexOf(rightName);
    }

    if (order.includes(leftName)) return -1;
    if (order.includes(rightName)) return 1;
    return 0;
  };
}

/**
 * Filters fields not listed in filterColumns prop.
 *
 * - pick only primitive type in case `filterColumns` is not set; OR
 * - pick field if it set in `filterColumns` or `externalFields`
 */
export function filterBy(filterColumns?: string[], externalFields?: any) { // eslint-disable-line
  return ({ name, type }: ResolvedMagicSchemaWithName): boolean =>
    (!filterColumns && PRIMITIVE_TYPES.includes(type)) ||
    (filterColumns ?? []).includes(name) ||
    Object.keys(externalFields ?? {}).includes(name);
}

/**
 * Format a cell value before displaying it in the table. Only simple types and lists of string are rendered.
 */
export function formatValue(value: unknown, type: string, name: string): string {
  // Fields which have "time" keyword in their name but aren't timestamps should be ignored
  // Otherwise it throws Invalid Date error
  if (value === null || value === undefined) {
    return '';
  }

  const ignoreFields = ['runtime'];
  const isTimestamp = name.toLowerCase().includes('time') &&
    !ignoreFields.some(toIgnore => name.toLocaleLowerCase().includes(toIgnore));
  if ((type === 'timestamp' || isTimestamp) && value) {
    return formatDateTime(value as Date);
  }

  if ((type === 'listOfString')) {
    return (value as string[]).join(', ');
  }

  return PRIMITIVE_TYPES.includes(type) ? value as string : '';
}

export function renderCustom(
  renderCell?: (row: unknown) => ReactNode | undefined,
): GridColDef['renderCell'] | undefined {
  return renderCell ? ({ row }) => renderCell(row) : undefined;
}

/**
 * Fetches the schema from a remote server before resolving it.
 */
export async function fetchRemoteSchema(url: string, entry: string): Promise<ResolvedMagicSchema> {
  const { data } = await axios.get(url);
  return resolveMagicType(data.shapes[entry], data);
}

/**
 * Resolves a schema from a valid local object.
 */
export function resolveLocalSchema(schema: any, entry: string): ResolvedMagicSchema { // eslint-disable-line
  return resolveMagicType(schema.shapes[entry], schema);
}

/**
 * Filters columns with empty cells.
 */
export function filterEmptyColumns(columnDefinitions: GridColDef[], rows: { [key: string]: unknown }[]): GridColDef[] {
  const names = columnDefinitions.map(({ field }) => field);
  const notEmpty = names.filter(name => rows.some(row => row?.[name]));
  return columnDefinitions.filter((({ field }) => notEmpty.includes(field)));
}
