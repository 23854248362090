import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';
import { useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';

import { useTinybirdPipe } from '../hooks';
import { PipelineSessionQueryResult } from '../types';
import { PipelineSessionsTable } from '../components';

export interface PipelineSessionsContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
  pipelineId: string;
}

export const PipelineSessionsContainer = (
  { tinybirdToken, pipelines, pipelineId }: PipelineSessionsContainerProps,
): ReactElement => {
  const { goto } = useRoutes();

  const { data: sessions } = useTinybirdPipe<PipelineSessionQueryResult[]>('sessions_pipe', tinybirdToken);

  const filteredSessions = (sessions ?? []).filter((session) => session.pipeline_id === pipelineId);

  return (
    <PipelineSessionsTable
      pipelines={pipelines}
      sessions={filteredSessions}
      onShowSession={(projectId, pipelineId_, sessionId) => {
        goto(AppRoute.PROJECT_SESSION, { projectId, pipelineId: pipelineId_, sessionId });
      }}
    />
  );
};
