import { buildRoute } from '@localstack/services';
import { LocalStackInstance } from '@localstack/types';
import { NavLink as CustomNavLink } from '@localstack/ui';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { ReactElement } from 'react';

import { AppRoute } from '~/config';

type AppRoutePath = (typeof AppRoute)[keyof typeof AppRoute]

type InstanceEntriesDefinition = {
  name: string,
  route: AppRoutePath;
  secondaryRoute?: AppRoutePath
}

type InstanceMenuEntriesProps = {
  instance?: LocalStackInstance,
  wrapper?: (children: ReactElement, entry: InstanceEntriesDefinition) => ReactElement;
  className?: string;
  useLocalStackLinkComponent?: boolean;
}

export const InstanceMenuEntries = ({
  instance,
  wrapper = (children: ReactElement, _entry: InstanceEntriesDefinition) => children,
  className,
  useLocalStackLinkComponent = true,
}: InstanceMenuEntriesProps): ReactElement => {

  const LinkComponent = useLocalStackLinkComponent ? CustomNavLink : BaseNavLink;
  const links: InstanceEntriesDefinition[] = [
    {
      name: 'Overview',
      route: AppRoute.STACK_OVERVIEW,
    },
    {
      name: 'Status',
      route: AppRoute.STATUS,
    },
    {
      name: 'Resource Browser',
      route: AppRoute.RESOURCES_OVERVIEW,
    },
    {
      name: 'State',
      route: AppRoute.INSTANCE_STATE,
    },
    {
      name: 'IAM Policy Stream',
      route: AppRoute.IAM_POLICY_STREAM,
    },
    {
      name: 'Chaos Engineering',
      route: AppRoute.CHAOS_ENGINEERING,
    },
    ...(instance?.ephemeralInstance ? [
      {
        name: 'Logs',
        route: AppRoute.INSTANCE_LOGS,
      },
    ] : []
    ),
    {
      name: 'Extensions',
      route: AppRoute.EXTENSIONS_MANAGE,
      secondaryRoute: AppRoute.EXTENSIONS_DETAILS,
    },
  ];

  return (
    <>
      {links.map((entry) => wrapper(
        <LinkComponent
          key={entry.name}
          to={buildRoute(entry.route, { iid: instance?.id })}
          className={className}
        >
          {entry.name}
        </LinkComponent >,
        entry,
      ),
      )}
    </>
  );
};
