/* eslint-disable react/no-array-index-key */

import { ReactElement } from 'react';
import { Box, Stack, Skeleton, Divider } from '@mui/material';

export interface TableSkeletonProps {
  rowCount?: number;
  columnCount?: number;
}

export const TableSkeleton = ({ rowCount = 5, columnCount = 5 }: TableSkeletonProps): ReactElement => (
  <Box>
    {Array.from(new Array(rowCount)).map((_rv, rowIndex) => (
      <Stack
        key={rowIndex}
        direction="row"
        spacing={1}
        mb={1}
        width="100%"
        divider={<Divider orientation="vertical" flexItem />}
      >
        {Array.from(new Array(columnCount)).map((_cv, colIndex) => (
          <Skeleton key={colIndex} variant="rectangular" height={25} sx={{ flex: 1 }} />
        ))}
      </Stack>
    ))}
  </Box>
);
