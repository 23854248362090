import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { CreateLambdaFunctionRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, LambdaFunctionUpdateCodeForm } from '@localstack/ui';
import { useParams } from 'react-router-dom';

import { LambdaProps } from './types';

export const LambdaFunctionUpdateCode = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: lambdaFunction } = useAwsGetter(
    'Lambda', 'getFunction', [{ FunctionName: name }], { clientOverrides },
  );
  const { updateFunctionCode, isLoading } = useAwsEffect(
    'Lambda',
    ['updateFunctionCode'],
    { revalidate: ['listFunctions','getFunction'], clientOverrides },
  );

  const handleCreate = async (data: CreateLambdaFunctionRequest) => {
    if (await updateFunctionCode(data)) {
      goto(routes.RESOURCES_LAMBDA_FUNCTION, { name: data.FunctionName });
    }
  };

  return (
    <Layout
      documentTitle="Lambda: Update Function Code"
      title={
        <Box>
          <Typography variant="h4">Update Function Code</Typography>
          <Breadcrumbs
            mappings={[
              ['Lambda', () => goto(routes.RESOURCES_LAMBDA_FUNCTIONS)],
              ['Update Function Code', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="UpdateLambdaFunctionCode"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          {lambdaFunction &&
            <LambdaFunctionUpdateCodeForm
              onSubmit={handleCreate}
              formId="UpdateLambdaFunctionCode"
              loading={isLoading}
              clientOverrides={clientOverrides}
              lambdaFunction={{ packageType: lambdaFunction?.Configuration?.PackageType, name }}
            />}
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpdateLambdaFunctionCode"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
