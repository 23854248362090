import { ReactElement, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { Pipeline } from '@localstack/types';

import { useTinybirdPipe } from '../hooks';
import { PipelineExecutionTimeQueryResult } from '../types';
import { PipelinesExecutionTimeChart } from '../components';

export interface PipelinesExecutionTimeContainerProps {
  tinybirdToken: string;
  pipelines: Pipeline[];
  pipelineId?: string;
  hideSidebar?: boolean;
}

export const LoadingPipelinesExecutionTimeContainer = (): ReactElement => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={250}
  />
);

export const PipelinesExecutionTimeContainer = (
  { tinybirdToken, pipelines, pipelineId, hideSidebar }: PipelinesExecutionTimeContainerProps,
): ReactElement => {
  const [displayedPipelines, setDisplayedPipelines] = useState<string[]>([]);

  const { data: pipelinesExecutionTime } = useTinybirdPipe<PipelineExecutionTimeQueryResult[]>(
    'pipelines_execution_time_pipe', tinybirdToken,
  );

  useEffect(() => {
    if (!pipelinesExecutionTime?.length) return;
    setDisplayedPipelines(pipelinesExecutionTime.map((exec) => exec.pipeline_id));
  }, [pipelinesExecutionTime?.length]);

  const filteredExecutions = (pipelinesExecutionTime ?? []).filter(
    (exec) => !pipelineId || (exec.pipeline_id === pipelineId),
  );

  return (
    <PipelinesExecutionTimeChart
      hideSidebar={hideSidebar}
      pipelines={pipelines}
      displayedPipelines={displayedPipelines}
      pipelinesExecutionTime={filteredExecutions}
      onDisplayPipeline={(pipelineId_) => setDisplayedPipelines([...displayedPipelines, pipelineId_])}
      onHidePipeline={(pipelineId_) => setDisplayedPipelines(displayedPipelines.filter((id) => id !== pipelineId_))}
    />
  );
};
