import { useState, ReactElement } from 'react';
import { Project } from '@localstack/types';
import { ProgressButton } from '@localstack/ui';
import { ProjectsService, useApiEffect, useApiGetter } from '@localstack/services';
import { CardContent, CardActions, CardHeader, Skeleton } from '@mui/material';

import { ProjectForm } from '../components';

interface ProjectEditorProps {
  projectId?: Optional<string>;
  onProjectCreated?: (project: Project) => unknown;
}

export const LoadingProjectEditor = (): ReactElement => (
  <>
    <CardHeader title="Project Details" />
    <CardContent>
      <Skeleton variant="rectangular" height={150} />
    </CardContent>
  </>
);

export const ProjectEditor = ({ projectId, onProjectCreated }: ProjectEditorProps): ReactElement => {
  const [isValid, setIsValid] = useState(false);

  const { data: project } = useApiGetter(
    ProjectsService,
    'getProject',
    [projectId],
    { enable: !!projectId, swrOverrides: { suspense: !!projectId } },
  );

  const { createProject, updateProject, isLoading: isMutating } = useApiEffect(
    ProjectsService,
    ['createProject', 'updateProject'],
    { revalidate: ['listProjects'] },
  );

  const onCreateProject = async (data: Project) => {
    const createdProject = await createProject(data);
    onProjectCreated?.(createdProject);
  };

  return (
    <>
      <CardHeader title="Project Details" />
      <CardContent>
        <ProjectForm
          formId="project-form"
          project={project}
          onSubmitCreate={onCreateProject}
          onSubmitUpdate={updateProject}
          onFormValidationChange={setIsValid}
        />
      </CardContent>
      <CardActions>
        <ProgressButton
          type="submit"
          variant="contained"
          color="primary"
          form="project-form"
          loading={isMutating}
          disabled={!isValid || isMutating}
        >
          Save
        </ProgressButton>
      </CardActions>
    </>
  );
};
