import { ErrorExplanation, ErrorFrame } from '@localstack/types';
import { useState, ReactElement } from 'react';
import { ProjectsService, useApiEffect } from '@localstack/services';
import { AccordionActions, AccordionDetails, Box, Typography } from '@mui/material';
import { CodeSnippetViewer, ProgressButton } from '@localstack/ui';

export interface ErrorAnalysisContainerProps {
  projectId: string;
  errorFrames: ErrorFrame[];
}

export const ErrorAnalysisContainer = ({ projectId, errorFrames }: ErrorAnalysisContainerProps): ReactElement => {
  const [errorExplanation, setErrorExplanation] = useState<ErrorExplanation | null>(null);

  const { explainError, isLoading } = useApiEffect(ProjectsService, ['explainError']);

  const handleExplainError = async () => {
    const result = await explainError(projectId, errorFrames);
    setErrorExplanation(result);
  };

  if (!errorExplanation) {
    return (
      <>
        <AccordionDetails>
          <Typography paragraph>
            Error Analysis is an experimental feature that uses AI to analyze the error and provide a detailed
            explanation. To make the analysis possible we would need to send the error message along with context to
            our servers, which may include some of the code snippets of your application.
          </Typography>
          <Typography paragraph color="error">
            The use of this feature will consume 1 AI credit!
          </Typography>
          <Typography paragraph>
            AI can make mistakes, so please use the results responsibly.
          </Typography>
        </AccordionDetails>
        <AccordionActions>
          <ProgressButton
            onClick={handleExplainError}
            color="primary"
            variant="contained"
            loading={isLoading}
          >
            Accept and Explain the Error
          </ProgressButton>
        </AccordionActions>
      </>
    );
  }

  return (
    <AccordionDetails>
      <Box>
        <Typography variant="caption">Error Reasoning</Typography>
        <Typography variant="body2">{errorExplanation?.reason}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption">Proposed Solution</Typography>
        <Typography variant="body2">{errorExplanation?.proposal_text}</Typography>
        <Box mt={2}>
          <CodeSnippetViewer disableParsing data={errorExplanation?.proposal_code} />
        </Box>
      </Box>
    </AccordionDetails>
  );
};
