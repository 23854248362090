import { ReactElement } from 'react';

import { useTinybirdPipe } from '../hooks';
import { StepQueryResult } from '../types';
import { StepsTable } from '../components';

export interface StepsContainerProps {
  projectId: string;
  pipelineId: string;
  sessionId: string;
  tinybirdToken: string;
  onStepChange?: (projectId: string, pipelineId: string, sessionId: string, stepId: string) => void;
}

export const StepsContainer = ({
  projectId,
  pipelineId,
  sessionId,
  tinybirdToken,
  onStepChange,
}: StepsContainerProps): ReactElement => {
  const { data: steps } = useTinybirdPipe<StepQueryResult[]>(
    'steps_pipe', tinybirdToken, { session_id: sessionId },
  );

  return (
    <StepsTable
      steps={steps ?? []}
      onStepChange={(stepId) => onStepChange?.(projectId, pipelineId, sessionId, stepId)}
    />
  );
};
