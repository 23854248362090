import { ReactElement, Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { useRoutes } from '@localstack/services';

import { retrieveAuthToken } from '~/util/storage';
import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import {
  StepDetailedLogsContainer,
  StepDetailedErrorsContainer,
  StepDetailedRequestsContainer,
  StepDetailedPodsContainer,
} from './containers';

type SessionStepParams = { projectId: string, pipelineId: string, sessionId: string, stepId: string };

export const SessionStep = (): ReactElement => {
  const { goto } = useRoutes();

  const authToken = retrieveAuthToken()?.token as string;

  const [selectedTab, setSelectedTab] = useState(1);

  const {
    projectId,
    pipelineId,
    sessionId,
    stepId,
  } = useParams<keyof SessionStepParams>() as SessionStepParams;

  return (
    <CustomerLayout
      fullScreen
      title={
        <Box>
          <IconButton onClick={() => goto(AppRoute.PROJECT_SESSION, { projectId, pipelineId, sessionId })}>
            <BackIcon />
          </IconButton>
          Step Overview
        </Box>
      }
      tabs={
        <>
          <Button
            variant="text"
            color="primary"
            onClick={() => setSelectedTab(0)}
            sx={{ display: 'none', textDecoration: selectedTab === 0 ? 'underline' : 'none' }}
          >
            Overview
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setSelectedTab(1)}
            sx={{ textDecoration: selectedTab === 1 ? 'underline' : 'none' }}
          >
            Logs
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setSelectedTab(2)}
            sx={{ textDecoration: selectedTab === 2 ? 'underline' : 'none' }}
          >
            Errors
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setSelectedTab(3)}
            sx={{ textDecoration: selectedTab === 3 ? 'underline' : 'none' }}
          >
            Requests
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setSelectedTab(4)}
            sx={{ textDecoration: selectedTab === 4 ? 'underline' : 'none' }}
          >
            Pod Snapshots
          </Button>
        </>
      }
    >
      {selectedTab === 0 && (
        <div>Some nice charts or summary will be here...</div>
      )}
      {selectedTab === 1 && (
        <Suspense fallback={<div>Loading step overview...</div>}>
          <StepDetailedLogsContainer
            projectId={projectId}
            pipelineId={pipelineId}
            sessionId={sessionId}
            stepId={stepId}
          />
        </Suspense>
      )}
      {selectedTab === 2 && (
        <Suspense fallback={<div>Loading step errors...</div>}>
          <StepDetailedErrorsContainer
            projectId={projectId}
            pipelineId={pipelineId}
            sessionId={sessionId}
            stepId={stepId}
          />
        </Suspense>
      )}
      {selectedTab === 3 && (
        <Suspense fallback={<div>Loading step requests...</div>}>
          <StepDetailedRequestsContainer
            projectId={projectId}
            pipelineId={pipelineId}
            sessionId={sessionId}
            stepId={stepId}
          />
        </Suspense>
      )}
      {selectedTab === 4 && (
        <Suspense fallback={<div>Loading step pods...</div>}>
          <StepDetailedPodsContainer
            projectId={projectId}
            pipelineId={pipelineId}
            sessionId={sessionId}
            stepId={stepId}
            authToken={authToken}
          />
        </Suspense>
      )}
    </CustomerLayout>
  );
};
