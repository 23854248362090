import { useState, useCallback, useEffect, ReactElement } from 'react';
import {
  useRoutes,
  useAwsEffect,
} from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMPoliciesTable, PageTitle } from '@localstack/ui';
import { IAMPolicy, IAMListPoliciesResponse } from '@localstack/types';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

const PAGE_SIZE = 100;

export const IAMPolicies = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const [policies, setPolicies] = useState<IAMPolicy[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);

  const { goto } = useRoutes();

  const { listPolicies } = useAwsEffect(
    'IAM',
    ['listPolicies'],
    { clientOverrides },
  );

  let response: IAMListPoliciesResponse = { Policies: [], IsTruncated: true, Marker: undefined };

  useEffect(() => {
    const allPolicies: IAMPolicy[] = [];
    setIsLoading(true);

    const listAllPolicies = async () => {
      while (response.IsTruncated) {
        // eslint-disable-next-line no-await-in-loop
        response = await listPolicies({ MaxItems: PAGE_SIZE, Marker: response.Marker });
        allPolicies.push(...response.Policies ?? []);
      }

      setPolicies(allPolicies);
      setIsLoading(false);
    };

    listAllPolicies();

  }, []);

  const { deletePolicy } = useAwsEffect(
    'IAM',
    ['deletePolicy'],
    { revalidate: ['getPolicy', 'listPolicies'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((PolicyArn) => deletePolicy({ PolicyArn }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle='IAM: Policies'
      title={
        <PageTitle
          title="IAM Policies"
          breadcrumbs={[
            ['IAM', () => goto(routes.RESOURCES_IAM)],
            ['Policies', () => goto(routes.RESOURCES_IAM_POLICIES)],
          ]}
        />
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_POLICY_CREATE)}>
            Create Policy
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Policy(es)?`}
              onClick={handleDeleteSelected}
              text="Selected Policies will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card style={{ height: '100%' }}>
        <IAMPoliciesTable
          selectable
          loading={isLoading}
          policies={policies ?? []}
          onSelect={setSelectedIds}
          page={page}
          onPageChange={setPage}
          pageSize={PAGE_SIZE}
          onViewPolicy={
            ({ Arn }) => goto(routes.RESOURCES_IAM_POLICY, { policyArn: encodeURIComponent(Arn || '') })
          }
        />
      </Card>
    </Layout >
  );
};
