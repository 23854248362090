import { curry } from 'lodash';
import useSWR from 'swr';

const tinybirdPipeFetcher = curry(
  (token: string, queryParams: string, pipe: string) =>
    fetch(
      `https://api.tinybird.co/v0/pipes/${pipe}.json${queryParams}`,
      { headers: { Authorization: `Bearer ${token}` } },
    ).then((res) => res.json()).then((data) => data.data),
);

export function useTinybirdPipe<T>(
  pipe: string,
  token?: string,
  queryParams?: Optional<Record<string, string>>,
): { data: T } {
  const searchParams = queryParams ? new URLSearchParams(queryParams) : null;

  if (searchParams) {
    searchParams.sort();
  }

  const queryParamsString = searchParams ? `?${searchParams.toString()}` : '';

  const { data } = useSWR(
    token ? pipe : null,
    tinybirdPipeFetcher(token ?? '', queryParamsString),
    { suspense: true, shouldRetryOnError: false },
  );

  return { data } as { data: T };
}
