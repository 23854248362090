import { ReactElement, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CICreditRangesType, UsageSummary } from '@localstack/types';

import {
  ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Line, Label, ReferenceArea,
} from 'recharts';
import { areDatesEqual, getDateRangeBetweenDates, getIsostringFromDate } from '@localstack/services';

import { useChartProps } from '../../../chart';

import { ChartDot } from './ChartDot';
import { ChartTooltip, TooltipProps } from './ChartTooltip';

export type CIKeysUsageChartProps = {
  title?: string;
  data: UsageSummary;
  selectedKey?: Optional<SelectedKeyType>;
  creditRangePercentages: CICreditRangesType;
  creditsQuota: number;
  subscriptionId: string;
  rightLabel?: string;
  leftLabel?: string;
}

type SelectedKeyType = {
  id: string;
  name: string;
  rotatedFrom: string[]
};

type CIKeysUsageChartData = {
  date: string;
  dailyUsage: number | null;
  totalUsage: number | null;
  estimated: number | null;
}[]

const generateDataInRangeWithEstimations = (
  fromTimeStamp: number,
  toTimeStamp: number,
  usageByDate: UsageSummary['ci_keys_usage_by_date'],
  selectedKey: Optional<SelectedKeyType>,
  subscriptionId: string,
): CIKeysUsageChartData => {
  /*
  usageByDate we receive only has few date values till current date meanwhile to plot the chart till the end of the period
  we need all the values from the startDate till the endDate
  */
  const startDate = new Date(fromTimeStamp * 1000);
  const endDate = new Date(toTimeStamp * 1000);
  const dates = getDateRangeBetweenDates(startDate, endDate);
  const currentDate = new Date();

  let average = {
    isCalculated: false,
    value: 0,
  };
  let totalUsage = 0;

  const rangeData = dates.map((date, i) => {
    const index = i + 1;
    const dateIsoString = getIsostringFromDate(date);
    const currentDateEntry = usageByDate?.[dateIsoString];
    const equalToOrLessThanCurrentDate = date < currentDate || areDatesEqual(date, currentDate);
    const dailyUsage =
      equalToOrLessThanCurrentDate ? (currentDateEntry?.subscription_usage?.[subscriptionId] || 0) : null;
    totalUsage += dailyUsage || 0;

    // Only compute the average if all the data is collected till today's date to get correct average
    if (!average.isCalculated && areDatesEqual(date, currentDate)) {
      average = {
        isCalculated: true,
        value: totalUsage / index,
      };
    }

    // If a key is selected, show its usage in the tooltip as well and also calculate the usage for the old keys reference (in case of rotations only)
    const selectedKeyWithRotatedKeysUsage = selectedKey && equalToOrLessThanCurrentDate ? {
      [selectedKey.name]: selectedKey.rotatedFrom.reduce(
        (sum, current) => sum + (currentDateEntry?.key_usage?.[current] || 0),
        currentDateEntry?.key_usage?.[selectedKey.id] || 0,
      ),
    } : {};

    return {
      date: dateIsoString,
      dailyUsage,
      totalUsage: equalToOrLessThanCurrentDate ? totalUsage : null,
      estimated: average.isCalculated ? Math.round(average.value * index) : null,
      ...selectedKeyWithRotatedKeysUsage,
    };
  });

  return rangeData;
};

const convertValuesToPercentages = (percentages: [number, number], creditsQuota: number) => {
  const getValue = ((percentage: number) => creditsQuota * (percentage / 100));
  return [getValue(percentages[0]), getValue(percentages[1])];
};


export const CIKeysUsageChart = ({ 
  title, 
  data, 
  selectedKey,
  creditRangePercentages,
  creditsQuota, 
  subscriptionId, 
  rightLabel = 'Total Invocations', 
  leftLabel = 'Daily Invocations', 
}:
    CIKeysUsageChartProps): ReactElement => {
  const chartProps = useChartProps();
  const theme = useTheme();
  const { usage_period_start, usage_period_end, ci_keys_usage_by_date } = data;

  const formattedData = useMemo(() => generateDataInRangeWithEstimations(
    usage_period_start, usage_period_end, ci_keys_usage_by_date, selectedKey, subscriptionId,
  ), [data, selectedKey]);

  const ranges = {
    green: convertValuesToPercentages(creditRangePercentages.green, creditsQuota),
    orange: convertValuesToPercentages(creditRangePercentages.orange, creditsQuota),
    red: convertValuesToPercentages(creditRangePercentages.red, creditsQuota),
    extra: convertValuesToPercentages(creditRangePercentages.extra, creditsQuota),
  };

  const COLORS = {
    light: {
      green: '#E6FAF6',
      orange: '#FFFAE8',
      red: '#FEEDEC',
      extra: '#DCDCDC',
      dailyUsageBarSvg: 'url(#dailyUsageBarSvg)',
      selectedKeyBarSvg: 'url(#selectedKeyBarSvg)',
      estimatedLine: '#B4B6B7',
      totalUsageLine: '#20114C',
    },
    dark: {
      green: '#1FB091',
      orange: '#DCB93C',
      red: '#D3665D',
      extra: '#818181',
      dailyUsageBarSvg: '#B4B6B7',
      selectedKeyBarSvg: 'url(#selectedKeyBarSvg)',
      estimatedLine: '#FFFFFF',
      totalUsageLine: '#20114C',
    },
  }[theme.palette.mode];

  return (
    <>
      {title && (
        <Typography align='center'>
          {title}
        </Typography>
      )}
      <Box style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <ResponsiveContainer width='100%' minWidth={650} height={250}>
          <ComposedChart
            data={formattedData}
          >
            <defs>
              <linearGradient id="dailyUsageBarSvg" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#DFE1E7" stopOpacity="1" />
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity="1" />
              </linearGradient>
              <linearGradient id="selectedKeyBarSvg" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#4D33BD" stopOpacity="1" />
                <stop offset="100%" stopColor="rgba(117, 84, 214, 0.5)" stopOpacity="1" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' {...chartProps.cartesianGridProps} />
            <XAxis dataKey='date' xAxisId={0} interval='preserveStartEnd' {...chartProps.xAxisProps} />
            {selectedKey && (
              <XAxis dataKey='date' xAxisId={1} interval='preserveStartEnd' hide {...chartProps.xAxisProps} />
            )}
            <YAxis
              yAxisId='dailyUsage' {...chartProps.yAxisProps}
            >
              <Label angle={-90} position='insideLeft'
                fill={theme.palette.text.secondary} style={{ textAnchor: 'middle' }}
              >
                {leftLabel}
              </Label>
            </YAxis>
            <YAxis
              yAxisId='esimated' orientation='right'{...chartProps.yAxisProps}
            >
              <Label angle={90} position='insideRight'
                fill={theme.palette.text.secondary} style={{ textAnchor: 'middle' }}
              >
                {rightLabel}
              </Label>
            </YAxis>
            <ReferenceArea y1={ranges.green[0]} y2={creditsQuota ? ranges.green[1] : undefined}
              yAxisId='esimated' fill={COLORS.green} fillOpacity={1} ifOverflow='extendDomain'
            />
            {!!creditsQuota && (
              <>
                <ReferenceArea y1={ranges.orange[0]} y2={ranges.orange[1]}
                  yAxisId='esimated' fill={COLORS.orange} fillOpacity={1} ifOverflow='extendDomain'
                />
                <ReferenceArea y1={ranges.red[0]} y2={ranges.red[1]}
                  yAxisId='esimated' fill={COLORS.red} fillOpacity={1} ifOverflow='extendDomain'
                />
                <ReferenceArea y1={ranges.extra[0]}
                  yAxisId='esimated' fill={COLORS.extra} fillOpacity={1} ifOverflow='extendDomain'
                />
              </>
            )}

            <Tooltip content={(props) => (
              <ChartTooltip
                {...props as TooltipProps} order={['dailyUsage', 'totalUsage', 'estimated', (selectedKey?.name || '')]}
              />)
            }
            />
            <Bar barSize={15} dataKey='dailyUsage' name='Daily Total' yAxisId='dailyUsage' xAxisId={0}
              fill={COLORS.dailyUsageBarSvg} radius={[3, 3, 0, 0]} opacity={1}
            />
            {selectedKey && (
              <Bar barSize={15} dataKey={selectedKey.name} yAxisId='dailyUsage' xAxisId={1}
                fill={COLORS.selectedKeyBarSvg} radius={[3, 3, 0, 0]} opacity={1}
              />
            )}
            <Line type='monotone'
              yAxisId='esimated' dataKey='totalUsage' name='Since Start of Period' strokeWidth={4}
              stroke={COLORS.totalUsageLine} dot={false}
            />
            <Line type='monotone'
              yAxisId='esimated' dataKey='estimated' stroke={COLORS.estimatedLine} strokeWidth={4}
              strokeDasharray="6 6" name='Estimated Total Usage'
              dot={(props) => <ChartDot total={formattedData.length} {...props} />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};
