import { ReactElement, useMemo, useEffect } from 'react';
import { Pipeline, Project } from '@localstack/types';
import { ProjectsService, useApiGetter } from '@localstack/services';
import { Grid, Skeleton } from '@mui/material';

import {
  BugReport as CurrentlyFailingTestsIcon, CheckCircleOutline as PipelinesStabilityIcon,
  ErrorOutline as CurrentlyFailingPipelinesIcon, Flaky as FlakyTestsIcon,
  PlayCircleOutline as CurrentlyActiveSessionsIcon, Timer as PipelinesExecutionTimeIcon,
  Update as LatestPipelineSessionsIcon,
} from '@mui/icons-material';

import { TableSkeleton, ProjectStatisticsCard } from '../components';

import { CurrentlyActiveSessionsContainer } from './CurrentlyActiveSessionsContainer';
import { LatestPipelineSessionsContainer } from './LatestPipelineSessionsContainer';
import { CurrentlyFailingPipelinesContainer } from './CurrentlyFailingPipelinesContainer';
import { CurrentlyFailingTestsContainer } from './CurrentlyFailingTestsContainer';
import { FlakyTestsContainer } from './FlakyTestsContainer';

import {
  LoadingPipelinesStabilityContainer,
  PipelinesStabilityContainer,
} from './PipelinesStabilityContainer';

import {
  LoadingPipelinesExecutionTimeContainer,
  PipelinesExecutionTimeContainer,
} from './PipelinesExecutionTimeContainer';

export interface ProjectStatisticsContainerProps {
  projectId: string;
  onProjectReady?: (project: Project) => unknown;
  onShowPipeline?: (projectId: string, pipelineId: string) => unknown;
}

const loadingGridLayout = [
  { sm: 12, md: 12, lg: 12, xl: 12 },
  { sm: 12, md: 12, lg: 12, xl: 12 },
  { sm: 12, md: 6, lg: 6, xl: 6 },
  { sm: 12, md: 6, lg: 6, xl: 6 },
  { sm: 12, md: 4, lg: 4, xl: 4 },
  { sm: 12, md: 8, lg: 8, xl: 8 },
  { sm: 12, md: 12, lg: 12, xl: 12 },
];

export const LoadingProjectStatisticsContainer = (): ReactElement => (
  <Grid container spacing={3}>
    {loadingGridLayout.map((layout, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={`index-${index}`} item {...layout}>
        <ProjectStatisticsCard
          title={<Skeleton variant="text" width="100%" />}
          suspenseFallback={<TableSkeleton />}
          avatar={<Skeleton variant="circular" width={24} height={24} />}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={150}
          />
        </ProjectStatisticsCard>
      </Grid>
    ))}
  </Grid>
);

export const ProjectStatisticsContainer = (
  { projectId, onProjectReady, onShowPipeline }: ProjectStatisticsContainerProps,
): ReactElement => {
  const { data: project } = useApiGetter(
    ProjectsService,
    'getProject',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const { data: queryTokenResult } = useApiGetter(
    ProjectsService,
    'requestQueryToken',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const { data: pipelines } = useApiGetter(
    ProjectsService,
    'listPipelines',
    [projectId],
    { swrOverrides: { suspense: true } },
  );

  const pipelinesMap: Record<string, Pipeline> = useMemo(() => {
    if (!pipelines) return {};
    return pipelines.reduce((acc, pipeline) => ({ ...acc, [pipeline.id as string]: pipeline }), {});
  }, [pipelines]);

  const tinybirdToken = queryTokenResult?.token as string; // suspense will ensure availability

  useEffect(() => {
    if (project) {
      onProjectReady?.(project as Project);
    }
  }, [project]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <ProjectStatisticsCard
          title="Currently Active Sessions"
          avatar={<CurrentlyActiveSessionsIcon />}
          suspenseFallback={<TableSkeleton />}
        >
          <CurrentlyActiveSessionsContainer
            projectId={projectId}
            pipelines={pipelinesMap}
            onShowPipeline={onShowPipeline}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <ProjectStatisticsCard
          title="Latest Pipeline Sessions"
          avatar={<LatestPipelineSessionsIcon />}
          suspenseFallback={<TableSkeleton />}
        >
          <LatestPipelineSessionsContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelinesMap}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ProjectStatisticsCard
          title="Currently failing pipelines"
          avatar={<CurrentlyFailingPipelinesIcon />}
          suspenseFallback={<TableSkeleton />}
        >
          <CurrentlyFailingPipelinesContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelinesMap}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ProjectStatisticsCard
          title="Currently Failing Tests"
          avatar={<CurrentlyFailingTestsIcon />}
          suspenseFallback={<TableSkeleton />}
        >
          <CurrentlyFailingTestsContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelinesMap}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ProjectStatisticsCard
          title="Stability"
          avatar={<PipelinesStabilityIcon />}
          suspenseFallback={<LoadingPipelinesStabilityContainer />}
        >
          <PipelinesStabilityContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelinesMap}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <ProjectStatisticsCard
          title="Pipelines Execution Time"
          avatar={<PipelinesExecutionTimeIcon />}
          suspenseFallback={<LoadingPipelinesExecutionTimeContainer />}
        >
          <PipelinesExecutionTimeContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelines ?? []}
          />
        </ProjectStatisticsCard>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ProjectStatisticsCard
          title="Flaky Tests"
          avatar={<FlakyTestsIcon />}
          suspenseFallback={<TableSkeleton />}
        >
          <FlakyTestsContainer
            tinybirdToken={tinybirdToken}
            pipelines={pipelinesMap}
          />
        </ProjectStatisticsCard>
      </Grid>
    </Grid>
  );
};
