import { useMemo, ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { StepQueryResult, EnrichedStep, EnrichedStepWithLevel } from '../types';

import { StatusLabel } from './StatusLabel';
import { DataGridLevelDot } from './DataGridLevelDot';

const buildStepsTree = (steps: StepQueryResult[]): EnrichedStep[] => {
  const rootNodes: EnrichedStep[] = [];

  const stepsTree = Object.fromEntries(
    new Map(steps.map(s => [[s.step_id], { ...s, children: [], isLastChild: false, isfirstChild: false }])),
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const step of steps) {
    const treeNode = stepsTree[step.step_id];
    const parentId = step.step_id !== '' && !step.parent ? '' : step.parent;

    if (parentId !== null && parentId !== undefined && stepsTree[parentId]) {
      stepsTree[parentId].children.push(treeNode);
    } else {
      rootNodes.push(treeNode);
    }
  }

  Object.values(stepsTree).forEach((step: EnrichedStep) => {
    if (step.children.length > 0) {
      step.children[0]!.isFirstChild = true; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      step.children[step.children.length - 1]!.isLastChild = true; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
  });

  rootNodes.forEach((step: EnrichedStep) => {
    step.isFirstChild = true;
    step.isLastChild = true;
  });

  return rootNodes;
};

const formatStepForDataGrid = (
  step: EnrichedStep,
  level = 0,
  parentalLastChild: boolean[] = [],
): EnrichedStepWithLevel[] => [
  { ...step, level, parentalLastChild: [...parentalLastChild, step.isLastChild] },
  ...step.children.flatMap(
    (s) => formatStepForDataGrid(s, level + 1, [...parentalLastChild, step.isLastChild]),
  ),
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    failedRow: {
      background: theme.palette.error.main,
      color: `${theme.palette.error.contrastText} !important`,
      '& a': {
        color: `${theme.palette.error.contrastText} !important`,
      },
      '&:hover': {
        background: `${theme.palette.error.dark} !important`,
        color: `${theme.palette.error.contrastText} !important`,
      },
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.error.light} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.error.light} !important`,
        },
      },
    },
  }),
);

export interface StepsTableProps {
  steps: StepQueryResult[];
  onStepChange: (stepId: string) => void;
}

export const StepsTable = ({ steps, onStepChange }: StepsTableProps): ReactElement => {
  const classes = useStyles();

  const stepsTree = useMemo(() => steps ? buildStepsTree(steps) : [], [steps]);
  const stepsForDataGrid = useMemo(() => stepsTree.flatMap(s => formatStepForDataGrid(s)), [stepsTree]);

  return (
    <DataGrid
      autoHeight
      density="compact"
      getRowClassName={({ row }) => (row.state === 'failed' ? classes.failedRow : '')}
      columns={[
        {
          field: 'level',
          headerName: 'Level',
          sortable: false,
          width: 100,
          renderCell: ({ row }) => (
            <DataGridLevelDot step={row} />
          ),
        },
        {
          field: 'step_id',
          headerName: 'Step ID',
          sortable: false,
          minWidth: 500,
          renderCell: ({ value }) => (
            <>
              {value ? (
                <Link
                  href="#"
                  onClick={(e) => {e.preventDefault(); onStepChange(value);}}
                  underline="hover"
                >
                  {value}
                </Link>
              ) : (
                <Typography variant="caption">Root</Typography>
              )}
            </>
          ),
        },
        { field: 'name', headerName: 'Name', sortable: false, minWidth: 200 },
        {
          field: 'snapshot_after',
          headerName: 'Snapshots',
          sortable: false,
          minWidth: 150,
          renderCell: ({ row }) => (
            <Box display="flex">
              {row.snapshot_before && (
                <Box mr={1}>
                  <Chip label="Before" size="small" color="primary" />
                </Box>
              )}
              {row.snapshot_after && (
                <Chip label="After" size="small" color="primary" />
              )}
            </Box>
          ),
        },
        { field: 'time_start', headerName: 'Start', sortable: false, minWidth: 150 },
        { field: 'time_end', headerName: 'End', sortable: false, minWidth: 150 },
        {
          field: 'state',
          headerName: 'Status',
          sortable: false,
          renderCell: ({ value }) => (
            <StatusLabel status={value} />
          ),
        },
        { field: 'duration_seconds', headerName: 'Duration', sortable: false },
      ]}
      rows={stepsForDataGrid}
      getRowId={(row) => row.step_id}
      pageSize={Math.min(stepsForDataGrid.length, 100)}
      sx={{
        '& .MuiDataGrid-cell': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: 'none',
        },
        '& .MuiDataGrid-row': {
          borderBottom: 'none',
        },
      }}
    />
  );
};
