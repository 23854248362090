import { ReactElement, useState } from 'react';
import { Box, Card, Tooltip } from '@mui/material';
import { ConfirmableButton, Dropdown, PodsTable, PageTitle } from '@localstack/ui';
import { CloudPod } from '@localstack/types';

import {
  CloudPodsService,
  useApiGetter,
  useRoutes,
  useInjectPodState,
  useAwsGetter,
} from '@localstack/services';

import { AppRoute } from '~/config';
import { CustomerLayout } from '~/layouts';

export const CloudPodsPublic = (): ReactElement => {
  const { goto } = useRoutes();
  const [selectedPods, setSelectedPods] = useState<string[]>([]);
  const { data: health } = useAwsGetter('LocalStack', 'getHealth', [], { silentErrors: true });

  const isLocalStackRunning = !!Object.keys(health || {}).length;
  const { data: allPods, isLoading: isPodLoading, mutate } =
    useApiGetter(CloudPodsService, 'listPublicCloudPods', [], { defaultValue: [] });

  const { inject, isLoading: isPodInjecting } = useInjectPodState();

  const pods = (allPods ?? []).filter((pod) => !pod.pod_name.match(/^ci[:_].+/));
  const isLoading = isPodLoading || isPodInjecting;
  const selectedPod = pods?.find((findPod: CloudPod) => findPod.id === selectedPods.at(0));

  return (
    <CustomerLayout
      title={
        <PageTitle title='Public Cloud Pods' onMutate={mutate}/>
      }
      actions={
        <Dropdown label="Actions">
          <Box>
            <Tooltip title={isLocalStackRunning ? '' :
              'In order to load, start a LocalStack instance first'}
            >
              <span>
                <ConfirmableButton
                  componentType="MenuItem"
                  color="primary"
                  variant="outlined"
                  disabled={!isLocalStackRunning || !selectedPod}
                  onClick={() => selectedPod && inject(selectedPod)}
                  title={`Load pod ${selectedPod?.pod_name}?`}
                  text="The state of this cloud pod will be loaded into your locally running instance."
                >
                  Load
                </ConfirmableButton>
              </span>
            </Tooltip>
          </Box>
        </Dropdown>
      }
    >
      <Card>
        <PodsTable
          pods={pods as CloudPod[] || []}
          loading={isLoading}
          onViewPod={(name) => goto(AppRoute.POD, { name })}
          onSelect={setSelectedPods}
          hideUserId
        />
      </Card>
    </CustomerLayout>
  );
};
