import { SERVICE_CODES } from '@localstack/constants';
import { formatTimestamp } from '@localstack/services';
import { AggregatedPolicies } from '@localstack/types';
import { MagicTable } from '@localstack/ui';
import { Box, Chip } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { GridEventListener } from '@mui/x-data-grid';

import { StatusIcon } from '../StatusIcon';
import { useStyles } from '../common/style';

const getPrincipalForAggregatedPolicy = (policy: AggregatedPolicies): string => {
  const firstPolicy = policy.policies[0];
  if (firstPolicy?.policy_type === 'identity') {
    return firstPolicy?.resource;
  }
  const principal = firstPolicy?.policy_document?.Statement[0]?.Principal;
  if (typeof principal === 'string') {
    return principal;
  }
  const value = principal?.AWS ?? principal?.Service;
  if (typeof value === 'string') return value;
  return value?.join(',') ?? 'No Principal available';
};

type OperationTableProps = { 
  policies: AggregatedPolicies[];
  onRowClick: GridEventListener<'rowClick'>;
}


const TABLE_SCHEMA = {
  shapes: {
    Operation: {
      type: 'structure',
      members: {
        id: { type: 'string' },
        status: { type: 'string' },
        operation: { type: 'string' },
        service: { type: 'string' },
        principal: { type: 'string' },
        timestamp: { type: 'string' },
      },
    },
  },
};

export const OperationsTable = ({ policies, onRowClick }: OperationTableProps): ReactElement => {
  const classes = useStyles();

  const transformedPolicies = useMemo(() => 
    policies.map((policy) => (
      {
        'id': policy.request.id,
        'principal': getPrincipalForAggregatedPolicy(policy), 
        'status': policy.status,
        'operation': policy.request.operation,
        'service': policy.request.service,
        'timestamp': policy.timestamp,
        'internal': policy.request.internal,
      }
    )).reverse()
  , [policies]);

  return (
    <MagicTable
      schema={TABLE_SCHEMA}
      entry="Operation"
      pageSize={25}
      onRowClick={onRowClick}
      rows={transformedPolicies ?? []}
      idAttribute="id"
      density='compact'
      filterColumns={['principal', 'status', 'operation', 'service', 'timestamp', 'internal']}
      hideColumnHeaders={['internal', 'status']}
      disableColumnMenu
      className={classes.tableHover}
      customGridColDef={{
        operation: { flex: 1 },
        service: { flex: 1 },
        principal: { flex: 1 },
      }}
      customWidths={{
        status: 25,
        internal: 25,
      }}
      externalFields={{
        operation: (row) => 
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {row.internal && (
              <Chip label='Internal' variant='filled' size='small'/>
            )}
            {row.operation}
          </Box>,
        service: (row) => 
          <Chip 
            label={SERVICE_CODES[row.service as keyof typeof SERVICE_CODES]} 
            variant='outlined'
            size='small'
          />,
        status: (row) =>
          <StatusIcon status={row.status}/>,
        timestamp: (row) => <div>{formatTimestamp(row.timestamp)}</div>,
      }}
    />
  );
};
