import { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { Tooltip } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: theme.spacing(1),
    margin: theme.spacing(1, 0),
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  greenText: {
    color: theme.palette.success.main,
  },
  orangeText: {
    color: theme.palette.warning.main,
  },
  redText: {
    color: theme.palette.error.main,
  },
  pointer: {
    position: 'absolute',
    bottom: theme.spacing(-2),
    padding: theme.spacing(0, 1),
    background: theme.palette.primary.main,
    height: theme.spacing(2),
    marginLeft: theme.spacing(0),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.caption,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    '&::before': {
      content: '" "',
      position: 'absolute',
      width: '3px',
      height: theme.spacing(4),
      top: theme.spacing(-2),
      left: 0,
      background: theme.palette.primary.main,
    },
  },
}));

export interface UsageLimitProps {
  value: number;
  displayValue?: ReactNode;
  tooltip?: ReactNode;
  variant?: 'smooth' | 'edged',
  greenRange: [number, number];
  orangeRange?: [number, number];
  redRange?: [number, number];
  reverse?: boolean;
}

export const UsageLimit = ({
  value,
  displayValue,
  tooltip,
  greenRange,
  orangeRange,
  redRange,
  variant = 'smooth',
  reverse = false,
}: UsageLimitProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  const buildGradient = () => {
    const gradient = [];

    if (variant === 'smooth') {
      gradient.push(`${theme.palette.success.main} ${greenRange[1]}%`);

      if (orangeRange) {
        gradient.push(`${theme.palette.warning.main} ${orangeRange[1]}%`);
      }

      if (redRange) {
        gradient.push(`${theme.palette.error.main} ${redRange[1]}%`);
      }
    }

    if (variant === 'edged') {
      gradient.push(`${theme.palette.success.main} ${greenRange[0]}%`);
      gradient.push(`${theme.palette.success.main} ${greenRange[1]}%`);

      if (orangeRange) {
        gradient.push(`${theme.palette.warning.main} ${orangeRange[0]}%`);
        gradient.push(`${theme.palette.warning.main} ${orangeRange[1]}%`);
      }

      if (redRange) {
        gradient.push(`${theme.palette.error.main} ${redRange[0]}%`);
        gradient.push(`${theme.palette.error.main} ${redRange[1]}%`);
      }
    }

    if (reverse) {
      gradient.reverse();
    }
    return `linear-gradient(90deg, ${gradient.join(', ')})`;
  };

  return (
    <Tooltip title={tooltip ?? displayValue ?? value} placement="top">
      <div className={classes.root}>
        <div
          style={{ left: `${value}%` }}
          className={
            classnames(classes.pointer, {
              [classes.greenText]: value >= greenRange[0] && value < greenRange[1],
              [classes.orangeText]: orangeRange && value >= orangeRange[0] && value < orangeRange[1],
              [classes.redText]: redRange && value >= redRange[0] && value < redRange[1],
            })
          }
        >
          {displayValue ?? value}
        </div>
        <div
          className={classes.container}
          style={{ background: buildGradient() }}
        >
          {/* <div className={classes.green} style={{ width: `${greenRange[1] - greenRange[0]}%`  }} />
          {orangeRange && (
            <div className={classes.orange} style={{ width: `${orangeRange[1] - orangeRange[0]}%`  }} />
          )}
          {redRange && (
            <div className={classes.red} style={{ width: `${redRange[1] - redRange[0]}%`  }} />
          )} */}
        </div>
      </div>
    </Tooltip>
  );
};
