import { ReactElement } from 'react';
import { Skeleton } from '@mui/material';
import { Pipeline } from '@localstack/types';

import { useTinybirdPipe } from '../hooks';
import { PipelineStabilityQueryResult } from '../types';

import { PipelinesStability } from '../components';

export interface PipelinesStabilityContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
}

export const LoadingPipelinesStabilityContainer = (): ReactElement => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={150}
  />
);

export const PipelinesStabilityContainer = (
  { tinybirdToken, pipelines }: PipelinesStabilityContainerProps,
): ReactElement => {
  const { data: pipelinesStability } = useTinybirdPipe<PipelineStabilityQueryResult[]>(
    'pipelines_stability_pipe', tinybirdToken,
  );

  return (
    <PipelinesStability
      pipelines={pipelines}
      pipelinesStability={pipelinesStability}
    />
  );
};
