import { ReactElement } from 'react';
import { Pipeline } from '@localstack/types';
import { useRoutes } from '@localstack/services';

import { AppRoute } from '~/config';

import { useTinybirdPipe } from '../hooks';
import { PipelineQueryResult } from '../types';
import { LatestPipelineSessionsTable } from '../components';

export interface LatestPipelineSessionsContainerProps {
  tinybirdToken: string;
  pipelines: Record<string, Pipeline>;
}

export const LatestPipelineSessionsContainer = (
  { tinybirdToken, pipelines }: LatestPipelineSessionsContainerProps,
): ReactElement => {
  const { goto } = useRoutes();

  const { data: latestPipelines } = useTinybirdPipe<PipelineQueryResult[]>('pipelines_pipe', tinybirdToken);

  return (
    <LatestPipelineSessionsTable
      pipelines={pipelines}
      latestPipelines={latestPipelines}
      onShowSession={(projectId, pipelineId, sessionId) => {
        goto(AppRoute.PROJECT_SESSION, { projectId, pipelineId, sessionId });
      }}
      onShowPipeline={(projectId, pipelineId) => {
        goto(AppRoute.PIPELINE, { projectId, pipelineId });
      }}
    />
  );
};
