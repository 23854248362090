import { ReactElement, useState } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface PipelineNameToggleProps {
  pipelineId: string;
  pipelineName?: string;
}

export const PipelineNameToggle = ({ pipelineId, pipelineName }: PipelineNameToggleProps): ReactElement => {
  const theme = useTheme();

  const [mode, setMode] = useState<'name' | 'id'>('name');

  return (
    <Typography
      sx={{ cursor: 'pointer', borderBottom: `1px dotted ${theme.palette.text.secondary}` }}
      variant="caption"
      onClick={() => setMode((prev) => (prev === 'name' ? 'id' : 'name'))}
    >
      {mode === 'name' ? (pipelineName || pipelineId) : pipelineId}
    </Typography>
  );
};
