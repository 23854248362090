import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateIAMGroupRequest, UpdateIAMGroupRequest } from '@localstack/types';
import { ProgressButton, IAMGroupForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMGroupUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { groupName } = useParams<'groupName'>();

  const { createGroup, updateGroup, isLoading } = useAwsEffect(
    'IAM',
    ['createGroup', 'updateGroup'],
    { revalidate: ['getGroup', 'listGroups'] },
  );

  const { data: group } = useAwsGetter('IAM', 'getGroup', [{ GroupName: groupName }], { clientOverrides });

  const handleCreate = async (data: CreateIAMGroupRequest) => {
    await createGroup(data);
    goto(routes.RESOURCES_IAM_GROUPS);
  };

  const handleUpdate = async (data: UpdateIAMGroupRequest) => {
    await updateGroup(data);
    goto(routes.RESOURCES_IAM_GROUP, { groupName: data.NewGroupName || groupName });
  };

  return (
    <Layout
      documentTitle={`IAM: ${group?.Group?.GroupName ? 'Update' : 'Create'} Group`}
      title={
        <Box>
          <Typography variant="h4">{group?.Group?.GroupName ? 'Update' : 'Create'} Group</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Groups', () => goto(routes.RESOURCES_IAM_GROUPS)],
              [group?.Group?.GroupName, () => goto(routes.RESOURCES_IAM_GROUP, { groupName })],
              [groupName ? 'Update' : 'Create', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMGroupForm
            group={group?.Group}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
