import get from 'lodash/get';
import { useState, useEffect, useMemo, ReactElement } from 'react';
import { MARKER_IDS, TestMarkerSpan, processIdAttributes, transformMagicFieldName } from '@localstack/services';
import { ResolvedMagicSchema } from '@localstack/types';
import { GridRenderCellParams, GridRowData } from '@mui/x-data-grid';
import {
  AddCircleOutlineTwoTone as AddIcon, RemoveCircleOutlineTwoTone as RemoveIcon,
  SvgIconComponent,
} from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { GridColDef, DataGrid, DataGridProps } from '../../../display';

import {
  getColumnNames,
  filterBy,
  orderBy,
  formatValue,
  renderCustom,
  getColumnWidth,
  fetchRemoteSchema,
  resolveLocalSchema,
  filterEmptyColumns,
} from './utils';

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

const EXPAND_BTN_WIDTH = 22;
const useStyles = makeStyles((theme: Theme) => createStyles({
  expandableRow: {
    display: 'flex',
    alignItems: 'center',
  },
  expandIcon: {
    color: theme.palette.text.secondary,
    transition: '.3s ease',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  expandIconParent: {
    marginRight: '10px',
    display: 'inline-flex',
    cursor: 'pointer',
  },
  expandIconPlaceholder: {
    display: 'inline-block',
    marginRight: '10px',
    width: EXPAND_BTN_WIDTH,
  },
}));

export type MagicTableProps<T, D = Flatten<T>> = Omit<DataGridProps, 'columns' | 'rows'> & {
  schema: string | Record<string, unknown>;
  entry: string;
  selectable?: boolean;
  formatNames?: boolean;
  idAttribute: keyof D | (keyof D)[];
  rows: T;
  loading?: boolean;
  order?: Extract<keyof D, string>[];
  /**
   * Only display the listed columns. Leave undefined to display all columns in the schema.
   */
  filterColumns?: Extract<keyof D, string>[];
  /**
   * Whether to display columns with empty cells or not. Default is to display all columns.
   * A table without any rows will display all columns regardless of this hideEmptyColumns.
   */
  hideEmptyColumns?: boolean;
  externalFields?: Partial<Record<keyof D, (row: D) => ReactElement>>;
  customWidths?: Partial<Record<keyof D, number>>;
  expandableTable?: {
    idColumn: keyof D;
    icons?: {
      open?: SvgIconComponent;
      close?: SvgIconComponent;
    }
  }
  disableSorting?: boolean;
  onSelect?: (ids: string[]) => unknown;
  /**
   * Hide the column headers for the supplied column names - by default displays all headers
   */
  hideColumnHeaders?: Extract<keyof D, string>[];
  customGridColDef?: Partial<Record<keyof D, Partial<GridColDef>>>;
}

const getChildrenIdsRecursively = (ids: string[], rows: Record<string, GridRowData>, result: string[] = []) => {
  ids.forEach((id) => {
    if (rows[id] && rows[id]?.childrenIds?.length) {
      result.push(id);
      getChildrenIdsRecursively(rows[id]?.childrenIds, rows, result);
    }
  });
  return result;
};

const getColNameFromIdAttribute = (idAttribute: unknown) => {
  // idAttribute is always going to be a string or string[] but we can't assign it directly
  // in the props otherwise it disables the valid key existence check. So, casting here
  const idAttr = idAttribute as (string | string[]);
  const colName: keyof GridRowData = typeof idAttr === 'object' ? processIdAttributes(idAttr) : idAttr;
  return colName;
};

// This function adds extra props like level and parentId and then sort the data in the order required by by our MagicTable to function properly
const processMultilevelTableRows = (rows: GridRowData[], idAttribute: string) => {
  const assignLevel = (dataMap: Map<string, GridRowData>, item: GridRowData | undefined, level: number) => {
    if (!item) return [];
    const items = [{ ...item, level }];
    item.childrenIds?.forEach((children: string) => items.push(
      ...assignLevel(dataMap, dataMap.get(children), level + 1),
    ));
    return items;
  };

  const idMap = new Map();
  const idToParentMap = new Map();

  rows.forEach(row => {
    idMap.set(row[idAttribute], row);
    row.childrenIds?.forEach((childrenId: string) => idToParentMap.set(childrenId, row[idAttribute]));
  });

  rows.forEach(row => {
    const parentId = idToParentMap.get(row[idAttribute]);
    if (parentId) {
      row.parentId = parentId;
    }
  });

  const levelOneItems = rows.filter(row => !row.parentId);
  return levelOneItems.flatMap(parent => assignLevel(idMap, parent, 1));
};

// eslint-disable-next-line
export const MagicTable = <T extends { [Key in keyof D]?: unknown }[], D>({
  schema,
  entry,
  selectable,
  formatNames = true,
  idAttribute,
  rows: dataRows,
  loading,
  order,
  filterColumns,
  externalFields,
  customWidths,
  hideEmptyColumns,
  expandableTable,
  disableSorting = false,
  onSelect,
  hideColumnHeaders,
  customGridColDef,
  ...rest
}: MagicTableProps<T>): ReactElement => {
  const [resolvedSchema, setResolvedSchema] = useState<ResolvedMagicSchema>();
  const columns = useMemo(
    () => getColumnNames(resolvedSchema, Object.keys(externalFields ?? {}).map(key => ({ name: key, type: 'string' })))
      .filter(filterBy(filterColumns, externalFields)).sort(orderBy(order)),
    [resolvedSchema, order, filterColumns],
  );

  const isExpandableTable = !!expandableTable?.idColumn;
  const idColName = getColNameFromIdAttribute(idAttribute);
  const rows = useMemo(
    () => isExpandableTable ?
      processMultilevelTableRows(dataRows, idColName) : dataRows, [dataRows],
  ) as T;
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const classes = useStyles();
  const toggleRow = (row: GridRowData, col: string) => {
    const rowId = row[col as keyof typeof row];
    const expandedRowsMap = rows.reduce((acc, item) => ({
      ...acc,
      [item[idColName as keyof D] as string]: item,
    }), {});
    const children = getChildrenIdsRecursively(row.childrenIds, expandedRowsMap);
    setExpandedRows((prevRows) => {
      if (prevRows.includes(rowId)) {
        const filterOut = [...children, rowId];
        return prevRows.filter(id => filterOut.indexOf(id) < 0);
      }
      return [...prevRows, rowId];
    },
    );
  };

  const renderExpandableControls = (params: GridRenderCellParams, idColumn: string, field: string) => {
    const isOpen = expandedRows.includes(params.row[idColName]);
    const Icon = isOpen ? expandableTable?.icons?.close || RemoveIcon : expandableTable?.icons?.open || AddIcon;
    const marginLeft = `${((params.row.level - 1) * 2)}0px`;
    const renderExternalField = externalFields?.[field as Extract<keyof D, string>];
    return (
      <div className={classes.expandableRow}>
        {params.row.childrenIds?.length ? (
          <>
            <span
              style={{ marginLeft }}
              className={classes.expandIconParent}
              onClick={() => toggleRow(params.row, idColName)}
              role='button'
              tabIndex={0}
            >
              <Icon className={classes.expandIcon} />
            </span>
          </>
        ) :
          <span className={classes.expandIconPlaceholder} style={{ marginLeft }} />
        }
        <span>
          {renderExternalField ?
            renderExternalField(params.row as Flatten<T>) : params.row[idColumn]}
        </span>
      </div>
    );
  };

  const sizes = useMemo(() => getColumnWidth(rows, columns, rest.disableColumnMenu), [rows, columns]);

  const columnDefinitions: GridColDef[] = useMemo(() =>
    columns.map(({ name: field, type }) => {
      let headerName = '';

      if (!hideColumnHeaders?.find(columnHeader => columnHeader === field)) {
        headerName = formatNames ? transformMagicFieldName(field) : field;
      }
      
      return {
        field,
        headerName,
        width: customWidths?.[field as keyof D] || sizes[field],
        valueFormatter: ({ value }) => formatValue(value, type, field),
        renderCell: field === expandableTable?.idColumn ?
          (params) => renderExpandableControls(params, expandableTable.idColumn as string, field) :
          renderCustom(externalFields?.[field as Extract<keyof D, string>]),
        ...customGridColDef?.[field as keyof D],
      };
    })
  , [columns, sizes, customWidths, expandedRows]);
  const filledColumns = useMemo(
    () =>
      rows?.length && hideEmptyColumns
        ? filterEmptyColumns(columnDefinitions, rows)
        : columnDefinitions,
    [rows, columnDefinitions],
  );

  useEffect(() => {
    (async () => {
      if (typeof schema === 'string') {
        setResolvedSchema(await fetchRemoteSchema(schema, entry));
      } else {
        setResolvedSchema(resolveLocalSchema(schema, entry));
      }
    })();
  }, [schema, entry]);

  const processedRows = isExpandableTable ? (
    rows.filter((row) =>
      row['parentId' as keyof D] ? expandedRows.includes(row['parentId' as keyof D] as string) : true,
    ) as T
  ) : rows;
  return (
    <TestMarkerSpan name={MARKER_IDS.MAGIC_TABLE}>
      <DataGrid
        autoHeight
        rows={processedRows}
        columns={filledColumns}
        getRowId={(row) => typeof idAttribute === 'object' ?
          processIdAttributes(idAttribute.map(attr => get(row, attr))) : get(row, idAttribute)}
        loading={loading}
        checkboxSelection={selectable}
        onSelectionModelChange={(names) => onSelect && onSelect(names as string[])}
        disableColumnMenu={disableSorting}
        disableColumnFilter={disableSorting}
        {...rest}
      />
    </TestMarkerSpan>
  );
};
