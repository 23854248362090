import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, IAMRoleDetails as Details } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { RoleTabs } from './components';
import { IAMProps } from './types';

export const IAMRoleDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { roleName } = useParams<'roleName'>();

  const { data: role } = useAwsGetter('IAM', 'getRole', [{ RoleName: roleName }], { clientOverrides });

  return (
    <Layout
      documentTitle="IAM: Role Details"
      tabs={<RoleTabs routes={routes} roleName={roleName} />}
      title={
        <Box>
          <Typography variant="h4">Role Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Roles', () => goto(routes.RESOURCES_IAM_ROLES)],
              [role?.Role?.RoleName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_IAM_ROLE_UPDATE, { roleName })}
        >
          Edit Role
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details iamRole={role?.Role} />
        </CardContent>
      </Card>
    </Layout>
  );
};
