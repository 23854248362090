import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const LAMBDA_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.LAMBDA}`;

export const DEFAULT_LAMBDA_ROUTES: Record<string, string> = {
  RESOURCES_LAMBDA_FUNCTIONS: `${LAMBDA_BASE_ROUTE}/functions`,
  RESOURCES_LAMBDA_FUNCTION: `${LAMBDA_BASE_ROUTE}/functions/:name`,
  RESOURCES_LAMBDA_FUNCTION_INVOKE: `${LAMBDA_BASE_ROUTE}/functions/:name/invoke`,
  RESOURCES_LAMBDA_FUNCTION_UPDATE_CODE: `${LAMBDA_BASE_ROUTE}/functions/:name/update-code`,
  RESOURCES_LAMBDA_FUNCTION_NEW: `${LAMBDA_BASE_ROUTE}/functions/new`,
  RESOURCES_LAMBDA_LAYERS: `${LAMBDA_BASE_ROUTE}/layers`,
  RESOURCES_LAMBDA_LAYER_NEW: `${LAMBDA_BASE_ROUTE}/layers/new`,
  RESOURCES_LAMBDA_LAYER_VERSIONS: `${LAMBDA_BASE_ROUTE}/layers/:layer/versions`,
};
