import { ReactElement, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { useRoutes } from '@localstack/services';
import { Box, IconButton } from '@mui/material';

import { AppRoute } from '~/config';
import { CustomerLayout } from '~/layouts';

import { SessionContainer } from './containers';

export const Session = (): ReactElement => {
  const { goto } = useRoutes();

  const { projectId, pipelineId, sessionId } = useParams<'projectId' | 'pipelineId' | 'sessionId'>() as {
    projectId: string, pipelineId: string, sessionId: string,
  };

  return (
    <CustomerLayout
      fullScreen
      title={
        <Box>
          <IconButton onClick={() => goto(AppRoute.PIPELINE, { projectId, pipelineId })}>
            <BackIcon />
          </IconButton>
          Session Overview
        </Box>
      }
    >
      <Suspense fallback={<div>Loading...</div>}>
        <SessionContainer
          projectId={projectId}
          pipelineId={pipelineId}
          sessionId={sessionId}
          onStepChange={
            (projectId_, pipelineId_, sessionId_, stepId) => goto(
              AppRoute.PROJECT_SESSION_STEP,
              { projectId: projectId_, pipelineId: pipelineId_, sessionId: sessionId_, stepId },
            )
          }
        />
      </Suspense>
    </CustomerLayout>
  );
};
