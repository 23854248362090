import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles(() =>
  createStyles({
    centerActions: {
      alignItems: 'center',
      justifyContent: 'right',
    },
    autocomplete: {
      minWidth: '200px',
      maxWidth: '400px',
    },
    drawer: {
      width: '600px',
    },
    drawerHeader: {
      display: 'flex',  
      flexDirection: 'column',
      gap: '0.5rem',
    },
    drawerSubHeader: {
      display: 'flex',  
      alignItems: 'center',
      gap: '0.5rem',
    },
    cardContent: {
      padding: 0,
    },  
    tableHover: {
      '.MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
      },
    },
  }),
);
