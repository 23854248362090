import { ReactElement } from 'react';
import { IAMPolicyVersion, CreateIAMPolicyVersionRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export interface IAMPolicyVersionFormProps {
  policyVersion?: Optional<Partial<IAMPolicyVersion>>;
  policyArn?: string;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateIAMPolicyVersionRequest) => unknown;
}

export const IAMPolicyVersionForm = (
  { policyVersion, policyArn, loading, formId, onCreate }: IAMPolicyVersionFormProps,
): ReactElement => {
  const mappedVersion: CreateIAMPolicyVersionRequest = {
    PolicyArn: policyArn || '',
    PolicyDocument: policyVersion?.Document || '',
    SetAsDefault: true,
  };

  return (
    <MagicForm
      data={mappedVersion}
      schema={getSchema('IAM')}
      loading={loading}
      entry="CreatePolicyVersionRequest"
      formId={formId}
      fieldConditions={{
        '^PolicyArn$': false,
      }}
      externalFields={{
        '^PolicyDocument$': (control, name) => (
          <ControlledCodeEditor
            name={name}
            control={control}
            required
            rules={{ ...VALIDATION_RULES.validJson, ...VALIDATION_RULES.required }}
            language="json"
          />
        ),
      }}
      onSubmit={(data: CreateIAMPolicyVersionRequest) => onCreate(data)}
    />
  );
};
