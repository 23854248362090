import { obfuscateWithStars, useSnackbar } from '@localstack/services';
import { LS_TOKEN_PREFIX } from '@localstack/constants';
import { ReactElement } from 'react';
import {
  FileCopyOutlined as CopyIcon,
} from '@mui/icons-material';
import { Chip } from '@mui/material';

type TokenChipProps = {
  token: string,
  message?: string,
  errorMessage?: string,
}
export const TokenChip = ({ token, message, errorMessage }: TokenChipProps): ReactElement => {
  const { showSnackbar } = useSnackbar();

  const copyToClipboard = (
    text: string,
  ) => {
    navigator.clipboard.writeText(text)
      .then(() => { showSnackbar({ message: message ?? 'Auth Token copied to clipboard!', severity: 'success' }); })
      .catch(() => {
        showSnackbar({ message: errorMessage ?? 'Copy to clipboard failed', severity: 'error' });
      });
  };

  return (
    <Chip
      variant="outlined"
      label={obfuscateWithStars(token, token.startsWith(LS_TOKEN_PREFIX) ? 6 : 3)}
      deleteIcon={<CopyIcon />}
      onDelete={() => copyToClipboard(token)}
      sx={{
        fontFamily: 'monospace',
        letterSpacing: '0.1em',
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent',
          color: 'inherit',
        },
      }}
    />
  );
};
