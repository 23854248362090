import { ReactElement } from 'react';
import { Skeleton } from '@mui/material';

import { useTinybirdPipe } from '../hooks';
import { PipelineTopFailingServicesQueryResult } from '../types';
import { PipelineTopFailingServicesChart } from '../components';

export interface PipelineTopFailingServicesContainerProps {
  tinybirdToken: string;
  pipelineId: string;
}

export const LoadingPipelineTopFailingServicesContainer = (): ReactElement => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={250}
  />
);

export const PipelineTopFailingServicesContainer = (
  { tinybirdToken, pipelineId }: PipelineTopFailingServicesContainerProps,
): ReactElement => {
  const { data: failingServices } = useTinybirdPipe<PipelineTopFailingServicesQueryResult[]>(
    'pipeline_top_failing_services', tinybirdToken, { pipeline_id: pipelineId },
  );

  return (
    <PipelineTopFailingServicesChart
      services={failingServices ?? []}
    />
  );
};
