import { ReactElement } from 'react';
import { EventBridgePipeDetailsResponse } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface EventBridgePipeDetailsProps {
  pipe: Partial<EventBridgePipeDetailsResponse>;
}

const getServiceFromArn = (arn: string) => arn.split(':')[2];

export const EventBridgePipeDetails = ({ pipe }: EventBridgePipeDetailsProps): ReactElement => {
  const sourceService = getServiceFromArn(pipe.Source as string);
  const targetService = getServiceFromArn(pipe.Target as string);
  return (
    <MagicDetails
      data={pipe}
      schema={getSchema('Pipes')}
      entry="DescribePipeResponse"
      title="Pipe Details"
      fieldConditions={{
        // Sources supported
        '^SourceParameters.KinesisStreamParameters$': sourceService === 'kinesis',
        '^SourceParameters.DynamoDBStreamParameters$': sourceService === 'dynamodb',
        '^SourceParameters.SqsQueueParameters$': sourceService === 'sqs',
        // Sources not supported
        '^SourceParameters.ActiveMQBrokerParameters$': false,
        '^SourceParameters.RabbitMQBrokerParameters$': false,
        '^SourceParameters.ManagedStreamingKafkaParameters$': false,
        '^SourceParameters.SelfManagedKafkaParameters$': false,
        // Targets supported
        '^TargetParameters.LambdaFunctionParameters$': targetService === 'lambda',
        '^TargetParameters.StepFunctionStateMachineParameters$': targetService === 'states',
        '^TargetParameters.KinesisStreamParameters$': targetService === 'kinesis',
        '^TargetParameters.SqsQueueParameters$': targetService === 'sqs',
        '^TargetParameters.EventBridgeEventBusParameters$': targetService === 'events',
        // Targets not supported
        '^TargetParameters.EcsTaskParameters$': false,
        '^TargetParameters.BatchJobParameters$': false,
        '^TargetParameters.RedshiftDataParameters$': false,
        '^TargetParameters.SageMakerPipelineParameters$': false,
        '^TargetParameters.CloudWatchLogsParameters$': false,
        '^TargetParameters.TimestreamParameters$': false,
        '^TargetParameters.HttpParameters$': false,
        // Only Cloudwatch logs are supported
        '^LogConfiguration$': !!pipe?.LogConfiguration?.CloudwatchLogsLogDestination?.LogGroupArn,
        '^LogConfiguration.S3LogDestination$': false,
        '^LogConfiguration.FirehoseLogDestination$': false,
      }}
    />
  );
};
