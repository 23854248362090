import { RepoProvider } from '@localstack/types';

export const buildCommitUrl = (repoProvider: RepoProvider, repoUrl: string, commit: string): string => {
  if (repoProvider === RepoProvider.GITHUB) {
    return `${repoUrl}/commit/${commit}`;
  }
  if (repoProvider === RepoProvider.GITLAB) {
    return `${repoUrl}/-/commit/${commit}`;
  }
  if (repoProvider === RepoProvider.BITBUCKET) {
    return `${repoUrl}/commits/${commit}`;
  }
  throw new Error(`Unknown repository provider: ${repoProvider}`);
};
