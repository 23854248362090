import { CICreditRangesType, Permission, ProductType, SubscriptionCiKeys } from '@localstack/types';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  formatDateTo,
  obfuscateWithStars,
  SubscriptionService,
  useApiEffect,
  useApiGetter,
} from '@localstack/services';

import { CIKeysUsageChart, ControlledTextField, ProgressButton } from '@localstack/ui';

import { useForm } from 'react-hook-form';

import { useAuthProvider } from '~/hooks';

import { AUTH_TOKENS_DATE_FORMAT } from '../../utils';

import { CITokenTableRow } from './CITokenTableRow';
import { UsageOverview } from './UsageOverview';

const useStyles = makeStyles((theme: Theme) => createStyles({
  faded: {
    textColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  fadedBold: {
    textColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  keysDropdown: {
    width: '15em',
  },
  keysDropdownOption: {
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    display: 'block',
  },
}));

const CI_CREDIT_RANGES: CICreditRangesType = {
  green: [0, 70],
  orange: [70, 90],
  red: [90, 100],
  extra: [100, 200],
};

type ManageCITokenProps = {
  subscriptionCITokens: SubscriptionCiKeys
}

export const ManageSubscriptionCITokens = ({ subscriptionCITokens }: ManageCITokenProps): ReactElement => {
  const classes = useStyles();
  const displayedQuota = subscriptionCITokens.quota !== 0 ? subscriptionCITokens.quota : '∞';
  const { data: usageSummary, isLoading: isUsageSummaryLoading }
    = useApiGetter(SubscriptionService, 'getUsage', [subscriptionCITokens.subscription_id]);

  const [selectedKey, setSelectedKey] =
    useState<{ id: string; name: string; rotatedFrom: string[] } | undefined>(undefined);
  const creditsUsed = usageSummary?.usage_by_type[ProductType.CI_USAGE] || 0;

  const remainingCredits = typeof displayedQuota === 'string' ? displayedQuota : displayedQuota - creditsUsed;

  const {
    control,
    handleSubmit,
    formState,
  } = useForm<{ name: string }>({ mode: 'all' });
  const { can } = useAuthProvider();
  const canWrite = can(Permission.WRITE_CI_KEYS);

  const { createCiKey, deleteCiKey, updateCiKey, rotateCiKey, isLoading: isCiKeyUpdating } =
    useApiEffect(SubscriptionService,
      [
        'createCiKey',
        'deleteCiKey',
        'updateCiKey',
        'rotateCiKey',
      ],
      { revalidate: ['listCiKeys'] },
    );

  const submitHandler = useCallback((data: { name: string }) => {
    if (!formState.isValid) return;
    createCiKey({
      subscription_id: subscriptionCITokens.subscription_id,
      name: data.name,
    });
  }, [formState.isValid]);

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Box display="flex" flexDirection="row" gap={1}>
                <Typography variant='h4'>
                  CI Auth Tokens
                </Typography>
                <Typography variant='h4' className={classes.faded}>
                  · {subscriptionCITokens.subscription_display_name}
                </Typography>

              </Box>
            }
            action={
              isUsageSummaryLoading ?
                <Skeleton variant='text' width={280} />
                :
                <Box display="flex" flexDirection="row" gap={1}>

                  <Typography>
                    Credits remaining: {remainingCredits}
                  </Typography>
                  <Typography className={classes.faded}>
                    · Resets on {formatDateTo(usageSummary?.usage_period_end ?? 0, AUTH_TOKENS_DATE_FORMAT)}
                  </Typography>
                </Box>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  CI Auth Tokens can access all workspace data like Cloud Pods.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UsageOverview
                  isLoading={isUsageSummaryLoading}
                  usageSummary={usageSummary}
                  creditsUsed={creditsUsed}
                  quota={displayedQuota}
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          variant='firstColumnCell'
                          sx={{ width: '30%' }}
                        >
                          <Typography className={classes.fadedBold}>
                            <span style={{ fontWeight: 600 }}>
                              Name
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.fadedBold}>
                            <span style={{ fontWeight: 600 }}>
                              Token
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.fadedBold}>
                            <span style={{ fontWeight: 600 }}>
                              Invocations
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.fadedBold}>
                            <span style={{ fontWeight: 600 }}>
                              Created
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriptionCITokens.keys.map(token => (
                        <CITokenTableRow
                          token={token}
                          key={token.api_key}
                          deleteCiKey={deleteCiKey}
                          updateCiKey={updateCiKey}
                          rotateCiKey={rotateCiKey}
                          isCiKeyUpdating={isCiKeyUpdating}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {canWrite &&
                <Grid item xs={12}>
                  <form onSubmit={handleSubmit(submitHandler)}>
                    <Grid container spacing={1}>
                      <Grid item md={4} sm={6}>
                        <ControlledTextField
                          name='name'
                          control={control}
                          placeholder="CI Auth Token Name"
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled={isCiKeyUpdating}
                          rules={{ required: 'Provide a label to differentiate the token from others' }}
                        />
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <ProgressButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!formState.isValid}
                          loading={isCiKeyUpdating}
                        >
                          Generate New CI Auth Token
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card >
      </Grid>

      {
        usageSummary &&
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Box display="flex" flexDirection="row" gap={1}>
                  <Typography variant='h4'>
                    CI Auth Tokens Usage
                  </Typography>
                  <Typography variant='h4' className={classes.faded}>
                    · {subscriptionCITokens.subscription_display_name}
                  </Typography>

                </Box>
              }
              action={
                <Box display="flex">
                  <Autocomplete
                    size="small"
                    options={subscriptionCITokens.keys}
                    getOptionLabel={key => `${key.name} (${obfuscateWithStars(key.api_key)})`}
                    className={classes.keysDropdown}
                    classes={{ option: classes.keysDropdownOption }}
                    renderInput={(params) => <TextField {...params} label="Highlight Auth Token" variant="outlined" />}
                    value={subscriptionCITokens.keys.find(key => key.id === selectedKey?.id) || null}
                    onChange={(_e, selectedItem) => {
                      if (!selectedItem) return setSelectedKey(undefined);

                      const key = subscriptionCITokens.keys.find(item => item.api_key === selectedItem.id);
                      if (!key) return setSelectedKey(undefined);

                      setSelectedKey({
                        id: key.id,
                        name: key.name || '',
                        rotatedFrom: key.rotated_from,
                      });
                    }}
                  />
                </Box>
              }
            />
            <CardContent>
              <CIKeysUsageChart
                data={usageSummary}
                selectedKey={selectedKey}
                creditRangePercentages={CI_CREDIT_RANGES}
                creditsQuota={subscriptionCITokens.quota}
                subscriptionId={subscriptionCITokens.subscription_id}
              />
            </CardContent>
          </Card>
        </Grid>
      }
    </>
  );
};
