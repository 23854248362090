import { Fragment, ReactElement, useState } from 'react';
import { ApiKeysList, DecorativeDivider, LoadingFragment, SubscriptionStatus } from '@localstack/ui';
import { ApiKey, Plan, ProductType } from '@localstack/types';
import { AdminService, useApiEffect, useApiGetter } from '@localstack/services';
import { SUB_ACTIVE_STATUSES } from '@localstack/constants';

import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';

import { LicenseAssignments } from '../components';

const planHasCIProduct = (plan: Plan) => plan.products?.some((p) => p.type === ProductType.CI_USAGE);

export interface KeysAndLicensesProps {
  orgId: string;
}

export const KeysAndLicenses = ({ orgId }: KeysAndLicensesProps): ReactElement => {
  const [revealKeys, setRevealKeys] = useState(false);
  const [showCancelledKeys, setShowCancelledKeys] = useState(false);

  const { data: organization } = useApiGetter(AdminService, 'getOrganization', [orgId]);

  const { data: subscriptions } =
    useApiGetter(AdminService, 'listOrganizationSubscriptions', [orgId]);

  const { data: keys, isLoading: isKeysLoading } = useApiGetter(AdminService, 'listOrganizationKeys', [orgId]);

  const { data: licenseAssignments } = useApiGetter(
    AdminService,
    'listOrganizationLicenseAssignments',
    [organization?.id || ''],
    { enable: !!organization },
  );

  const { assignLicense, unassignLicense } = useApiEffect(
    AdminService,
    ['assignLicense', 'unassignLicense'],
    { revalidate: ['listOrganizationLicenseAssignments'] },
  );

  const {
    updateOrganizationKey,
    removeOrganizationKey,
    rotateOrganizationKey,
    createOrganizationKey,
    isLoading: isKeyMutating,
  } = useApiEffect(
    AdminService,
    ['updateOrganizationKey', 'removeOrganizationKey', 'createOrganizationKey', 'rotateOrganizationKey'],
    { revalidate: ['listOrganizationKeys', 'listOrganizationSubscriptions'] },
  );

  const handleCreateKey = (subscriptionId: string, isCi = false) => {
    createOrganizationKey(orgId, {
      is_ci: isCi,
      subscription_id: subscriptionId,
    } as ApiKey);
  };

  const activeSubscriptions = (subscriptions ?? []).filter(({ status }) => SUB_ACTIVE_STATUSES.includes(status));
  const activeKeys = (keys ?? []).filter(({ deleted }) => !deleted);
  const filteredKeys = showCancelledKeys ? (keys ?? []) : activeKeys;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" align="right" component="div">License Assignments</Typography>
      </Box>
      <LicenseAssignments
        licenseAssignments={licenseAssignments ?? []}
        members={organization?.members ?? []}
        subscriptions={subscriptions ?? []}
        assignLicense={(user_id, subscription_id) => assignLicense(orgId, {
          user_id,
          subscription_id,
        })}
        removeLicenseAssignment={(assignmentId) => unassignLicense(orgId, assignmentId)}
      />
      <Box mt={2} mb={2}>
        <DecorativeDivider />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" align="right" component="div">Keys</Typography>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={revealKeys}
                onChange={(_, checked: boolean) => setRevealKeys(checked)}
              />
            }
            label="Display Keys"
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showCancelledKeys}
                onChange={(_, checked: boolean) => setShowCancelledKeys(checked)}
              />
            }
            label="Show Cancelled"
          />
        </Box>
      </Box>
      <LoadingFragment
        loading={isKeysLoading}
        arrayData={subscriptions ?? []}
        size={5}
        height={50}
      >
        <List>
          {(showCancelledKeys ? (subscriptions ?? []) : activeSubscriptions).map((sub) => (
            <Fragment key={sub.id}>
              <ListItem>
                <ListItemText primary={sub.plan?.name} secondary={sub.id} />
                <ListItemSecondaryAction>
                  <SubscriptionStatus subscription={sub} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem style={{ display: 'block' }}>
                <ApiKeysList
                  editable
                  deletable
                  deletableCI
                  showState
                  reveal={revealKeys}
                  keys={filteredKeys.filter((k) => k.subscription_id === sub.id) ?? []}
                  onSave={(keyId, keyParams) => updateOrganizationKey(orgId, keyId, keyParams)}
                  onDelete={(keyId) => removeOrganizationKey(orgId, keyId)}
                  onRotate={(keyId, notify) => rotateOrganizationKey(orgId, keyId, { notify })}
                />
              </ListItem>
              {planHasCIProduct(sub.plan as Plan) && (
                <ListItem
                  button
                  onClick={() => handleCreateKey(sub.id, true)}
                  disabled={isKeyMutating}
                >
                  <ListItemText secondary="+Create CI Auth Token" />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => handleCreateKey(sub.id, false)}
                disabled={isKeyMutating}
              >
                <ListItemText secondary="+Create Legacy API Key (deprecated)" />
              </ListItem>
            </Fragment>
          ))}
        </List>
      </LoadingFragment>
    </>
  );
};
