import { ReactElement } from 'react';
import { Chart } from 'react-google-charts';

import { PipelineTopFailingServicesQueryResult } from '../types';

export interface TopFailingServicesChartProps {
  services: PipelineTopFailingServicesQueryResult[];
}

export const PipelineTopFailingServicesChart = ({ services }: TopFailingServicesChartProps): ReactElement => {
  const data = [
    ['Service', '# Failed Requests'],
    ...services.map((service) => [service.service_name, service.failed_requests_count]),
  ];

  return (
    <Chart
      chartType="PieChart"
      data={data}
      width="100%"
      height="400px"
    />
  );
};
