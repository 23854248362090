import { ReactElement } from 'react';
import { Chip, ChipOwnProps } from '@mui/material';

import { RequestStatus } from '@localstack/types';



type StatusChipProps = {
  status: RequestStatus;
}

export const StatusChip = ({ status }: StatusChipProps): ReactElement => {
  let chipColor: ChipOwnProps['color'];
  switch (status) {
  case RequestStatus.DENIED:
    chipColor = 'error';
    break;
  case RequestStatus.WARNING:
    chipColor = 'warning';
    break;
  default:
    chipColor = 'default';
    break;
  }


  return(
    <Chip label={status.toUpperCase()} color={chipColor} size='small'/>
  );
};
