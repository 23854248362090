import { Box, Button, Card, Chip, Grid, Link, Typography } from '@mui/material';
import { ConfirmableButton, PlanChip } from '@localstack/ui';
import { ReactElement } from 'react';
import { FormState } from 'react-hook-form';
import { PlanFamily } from '@localstack/types';
import { ExternalLink } from '@localstack/constants';
import { useSnackbar } from '@localstack/services';

import { ExperimentCardType, ExperimentProps } from '../types';
import { isExperimentRunning } from '../utils';

import { WrapInToolTip } from './WrapInTooltip';
import { useExperimentCardStyles } from './common-styles';


type ExperimentCardProps = {
  experimentCard: ExperimentCardType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>
} & ExperimentProps;

export const ExperimentCard = (
  { experimentCard, onStopExperiment, onUpsertExperiment,
    experiment: experimentState, alert, formState, disableAndShowCta, createLead }
    : ExperimentCardProps): ReactElement => {
  const { showSnackbar } = useSnackbar();
  const classes = useExperimentCardStyles({ disableAndShowCta });
  const isRunning = isExperimentRunning(experimentState);
  const { title, description, options, template } = experimentCard;
  const { isValid: formIsValid } = formState;

  const copyButtonClickHandler = async () => {
    await navigator.clipboard.writeText(JSON.stringify(template, null, 4));
    showSnackbar({ message: 'Copied to clipboard', severity: 'success' });
  };

  return (
    <Card className={classes.card} variant='outlined'>
      <Box mb={1}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item minHeight={32}>
            {/* min height is set to avoid the box size layout shift when status is shown */}
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography variant='h5' className={classes.heading}>
                {title}
              </Typography>
              {disableAndShowCta && <PlanChip planFamily={PlanFamily.ENTERPRISE_PLANS} />}
            </Box>

          </Grid>
          {!disableAndShowCta && isRunning &&
            <Grid item>
              <Chip className={classes.running} label='Running' />
            </Grid>
          }
        </Grid>
      </Box>
      <Box mb={2} className={classes.description}>
        <Typography>{description}</Typography>
      </Box>
      <Box mb={2} className={classes.controlsAndTemplate}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ paddingTop: '15px' }}>
            {options}
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container justifyContent='flex-end' height='100%'>
              <Grid item display='flex' alignItems='flex-end' justifyContent='flex-end' height='100%' gap={1}>
                <Button
                  variant='contained'
                  onClick={copyButtonClickHandler}
                >
                  Copy Chaos API Template
                </Button>
                <Box>
                  {disableAndShowCta && (
                    <ConfirmableButton
                      componentType="Button"
                      variant='contained'
                      color='primary'
                      title='Contact Sales'
                      onClick={() => createLead(title)}
                      okText='Contact Sales'
                      disabled={!!alert}
                      text={<>
                        <Box mb={1}>
                          <Typography variant='body1' color='grey'>
                            This feature requires an Enterprise subscription. Please reach out to sales and {' '}
                            one of our representatives will reach out to you shortly.
                          </Typography>
                        </Box>
                        <Typography variant='body1' color='grey' lineHeight={1.3}>
                          <small>
                            LocalStack will use your contact information to contact you about the products {' '}
                            and services.<br />
                            You may unsubscribe from these communications at any time.<br />
                            For information, check out our{' '}
                            <Link target='_blank' href={ExternalLink.LEGAL_PRIVACY_POLICY} underline="hover">
                              Privacy Policy
                            </Link>.
                          </small>
                        </Typography>
                      </>}
                    >
                      Upgrade Plan
                    </ConfirmableButton>
                  )}
                  {!disableAndShowCta && !isRunning && (
                    <WrapInToolTip title={alert}>
                      <span>
                        <Button
                          title="Run Experiment"
                          variant='contained' color='primary'
                          onClick={() => onUpsertExperiment(template, experimentState)}
                          disabled={!!alert || !formIsValid}
                        >
                          Run Experiment
                        </Button>
                      </span>
                    </WrapInToolTip>
                  )}
                  {!disableAndShowCta && isRunning &&
                    (
                      <WrapInToolTip title={alert}>
                        <span>
                          <Button
                            title="Stop Experiment"
                            variant='outlined'
                            className={classes.secondaryBtn}
                            onClick={() => onStopExperiment(experimentState)}
                            disabled={!!alert}
                          >
                            Stop Experiment
                          </Button>
                        </span>
                      </WrapInToolTip>
                    )}
                </Box>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card >
  );
};
