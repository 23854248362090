import { ReactElement } from 'react';
import { SQSMessage } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { ListItem } from '@mui/material';

import { MagicDetails } from '../../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../../display';

export type SQSMessageDetailsProps = {
  message: Optional<SQSMessage>;
};

export const SQSMessageDetails = ({ message }: SQSMessageDetailsProps): ReactElement => (
  <MagicDetails
    data={message}
    schema={getSchema('SQS')}
    entry="Message"
    title="Message Details"
    order={['Body']}
    externalFields={{
      '^Body$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} disableParsing />
        </ListItem>
      ),
    }}
  />
);
