import { ReactNode, ReactElement } from 'react';
import { Grid } from '@mui/material';

export interface SessionLayoutProps {
  children?: ReactNode;
  details?: ReactNode;
}

export const SessionLayout = ({ children, details }: SessionLayoutProps): ReactElement => (
  <Grid container spacing={1} height="100%">
    <Grid item xs={12} sm={12} md={details ? 6 : 12} lg={details ? 6 : 12} xl={details ? 6 : 12}>
      {children}
    </Grid>
    {details && (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {details}
      </Grid>
    )}
  </Grid>
);
