/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorExplanation } from '@localstack/types';
import type { ErrorFrame } from '@localstack/types';
import type { Pipeline } from '@localstack/types';
import type { PipelineRunningSession } from '@localstack/types';
import type { Project } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * @param projectId
     * @param requestBody
     * @returns ErrorExplanation
     * @throws ApiError
     */
    public static explainError(
      projectId: any,
      requestBody?: Array<ErrorFrame>,
    ): CancelablePromise<ErrorExplanation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects/{project_id}/explain/error',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @param requestBody
     * @returns Pipeline
     * @throws ApiError
     */
    public static createPipeline(
      projectId: any,
      requestBody?: Pipeline,
    ): CancelablePromise<Pipeline> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects/{project_id}/pipelines',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns Pipeline
     * @throws ApiError
     */
    public static listPipelines(
      projectId: any,
    ): CancelablePromise<Array<Pipeline>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v0/projects/{project_id}/pipelines',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @param requestBody
     * @returns Pipeline
     * @throws ApiError
     */
    public static updatePipeline(
      projectId: any,
      pipelineId: any,
      requestBody?: Pipeline,
    ): CancelablePromise<Pipeline> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @returns Pipeline
     * @throws ApiError
     */
    public static getPipeline(
      projectId: any,
      pipelineId: any,
    ): CancelablePromise<Pipeline> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
            },
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @returns Pipeline
     * @throws ApiError
     */
    public static deletePipeline(
      projectId: any,
      pipelineId: any,
    ): CancelablePromise<Pipeline> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static createProject(
      requestBody?: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @param requestBody
     * @returns Project
     * @throws ApiError
     */
    public static updateProject(
      projectId: any,
      requestBody?: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v0/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns Project
     * @throws ApiError
     */
    public static getProject(
      projectId: any,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v0/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @returns Project
     * @throws ApiError
     */
    public static deleteProject(
      projectId: any,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v0/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @returns Project
     * @throws ApiError
     */
    public static listProjects(): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v0/projects',
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @param requestBody
     * @returns PipelineRunningSession
     * @throws ApiError
     */
    public static createRunningSession(
      projectId: any,
      pipelineId: any,
      requestBody?: {
          id: string;
          commit?: string;
          status?: string;
      },
    ): CancelablePromise<PipelineRunningSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}/sessions',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns PipelineRunningSession
     * @throws ApiError
     */
    public static listProjectRunningSessions(
      projectId: any,
    ): CancelablePromise<Array<PipelineRunningSession>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v0/projects/{project_id}/sessions',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @param sessionId
     * @returns PipelineRunningSession
     * @throws ApiError
     */
    public static closeRunningSession(
      projectId: any,
      pipelineId: any,
      sessionId: any,
    ): CancelablePromise<PipelineRunningSession> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}/sessions/{session_id}',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
                'session_id': sessionId,
            },
        });
    }

    /**
     * @param projectId
     * @returns any
     * @throws ApiError
     */
    public static requestQueryToken(
      projectId: any,
    ): CancelablePromise<{
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects/{project_id}/tokens',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param projectId
     * @param pipelineId
     * @param sessionId
     * @returns any
     * @throws ApiError
     */
    public static requestUploadUrl(
      projectId: any,
      pipelineId: any,
      sessionId: any,
    ): CancelablePromise<{
        url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v0/projects/{project_id}/pipelines/{pipeline_id}/sessions/{session_id}/uploads',
            path: {
                'project_id': projectId,
                'pipeline_id': pipelineId,
                'session_id': sessionId,
            },
        });
    }

}
