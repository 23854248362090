import { ReactElement } from 'react';
import { formatDateTime } from '@localstack/services';
import { DataGrid } from '@mui/x-data-grid';
import { Link, Typography } from '@mui/material';
import { Pipeline, RepoProvider } from '@localstack/types';

import { PipelineQueryResult } from '../types';

import { StatusLabel } from './StatusLabel';

const buildCommitUrl = (repoProvider: RepoProvider, repoUrl: string, commit: string) => {
  if (repoProvider === RepoProvider.GITHUB) {
    return `${repoUrl}/commit/${commit}`;
  }
  if (repoProvider === RepoProvider.GITLAB) {
    return `${repoUrl}/-/commit/${commit}`;
  }
  if (repoProvider === RepoProvider.BITBUCKET) {
    return `${repoUrl}/commits/${commit}`;
  }
  throw new Error(`Unknown repository provider: ${repoProvider}`);
};

export interface LatestPipelineSessionsTableProps {
  pipelines: Record<string, Pipeline>;
  latestPipelines: PipelineQueryResult[];
  onShowSession?: (projectId: string, pipelineId: string, sessionId: string) => unknown;
  onShowPipeline?: (projectId: string, pipelineId: string) => unknown;
}

export const LatestPipelineSessionsTable = (
  { pipelines, latestPipelines, onShowSession, onShowPipeline }: LatestPipelineSessionsTableProps,
): ReactElement => (
  <DataGrid
    autoHeight
    density="compact"
    columns={[
      {
        field: 'latest_session_id',
        headerName: 'Session',
        minWidth: 300,
        renderCell: ({ value, row }) => (
          <Link
            href="#"
            onClick={(e) => {e.preventDefault(); onShowSession?.(row.project_id, row.pipeline_id, value);}}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'pipeline_id',
        headerName: 'Pipeline',
        minWidth: 300,
        renderCell: ({ value, row }) => (
          <Link href="#" onClick={(e) => {e.preventDefault(); onShowPipeline?.(row.project_id, value);}}>
            {pipelines[value]?.name || value}
          </Link>
        ),
      },
      {
        field: 'pipeline_state',
        headerName: 'Status',
        minWidth: 100,
        renderCell: ({ value }) => (
          <StatusLabel status={value} />
        ),
      },
      {
        field: 'latest_session_commit',
        headerName: 'Commit',
        minWidth: 280,
        renderCell: ({ value, row: { pipeline_id } }) => (
          <>
            {(value && pipelines[pipeline_id]) ? (
              <Link
                target="_blank"
                href={
                  buildCommitUrl(
                    pipelines[pipeline_id]!.repo_provider, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    pipelines[pipeline_id]!.repo_url, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    value,
                  )
                }
              >
                {value}
              </Link>
            ) : (
              <Typography variant="caption">No commit</Typography>
            )}
          </>
        ),
      },
      {
        field: 'created',
        headerName: 'Started At',
        minWidth: 200,
        valueFormatter: (params) => formatDateTime(params.value as number),
      },
    ]}
    rows={latestPipelines ?? []}
    getRowId={(row) => `${row.pipeline_id}-${row.latest_session_id}`}
  />
);
