import { ReactElement } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Typography } from '@mui/material';
import { AwsServiceTile } from '@localstack/ui';
import { Chart } from 'react-google-charts';
import { noop } from 'lodash';

import { SessionRequestQueryResult } from '../types';
import { useTinybirdPipe } from '../hooks';

export interface StepRequestsContainerProps {
  pipelineId: string;
  sessionId: string;
  stepId: string;
  tinybirdToken: string;
  onSelect?: (request: SessionRequestQueryResult) => unknown;
}

export const StepRequestsContainer = ({
  pipelineId,
  sessionId,
  stepId,
  tinybirdToken,
  onSelect,
}: StepRequestsContainerProps): ReactElement => {
  const { data: sessionRequests } = useTinybirdPipe<SessionRequestQueryResult[]>(
    'session_step_requests_pipe',
    tinybirdToken,
    { pipeline_id: pipelineId, session_id: sessionId, step_id: stepId },
  );

  const sessionRequestsWithId = sessionRequests?.map(
    (log, i) => ({ ...log, id: i }),
  );

  const timelineColumns = [
    { type: 'string', id: 'Service' },
    { type: 'string', id: 'Operation' },
    { type: 'datetime', id: 'Started' },
    { type: 'datetime', id: 'Finished' },
  ];

  const timelineData = sessionRequestsWithId?.map((request) => [
    request.service_name ?? 'localstack',
    request.operation_name ?? 'unknown',
    new Date(request.time_start),
    new Date(request.time_end),
  ]);

  const uniqueServices = Array.from(new Set(sessionRequestsWithId?.map((request) => request.service_name) ?? []));

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Chart
        chartType="Timeline"
        data={[timelineColumns, ...timelineData]}
        width="100%"
        height={(uniqueServices.length + 2) * 41}
        options={{
          timeline: {
            groupByRowLabel: true,
          },
          hAxis: {
            format: 'HH:mm:ss',
          },
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper?.getChart();
              const selection = chart?.getSelection();
              if (selection && selection.length > 0) {
                onSelect?.(sessionRequestsWithId[(selection[0] as { row: number }).row] as SessionRequestQueryResult);
              }
            },
          },
        ]}
      />
      <DataGrid
        autoHeight
        density="compact"
        rows={sessionRequestsWithId ?? []}
        pageSize={Math.min(sessionRequestsWithId.length, 100)}
        onSelectionModelChange={(newSelection) => {
          onSelect?.(sessionRequestsWithId[newSelection[0] as number] as SessionRequestQueryResult);
        }}
        disableExtendRowFullWidth
        style={{
          flexGrow: 1,
          overflow: 'auto',
        }}
        columns={[
          { field: 'request_id', headerName: 'Request ID', width: 250 },
          {
            field: 'service_name',
            headerName: 'Service',
            minWidth: 200,
            renderCell: ({ value }) => (
              <AwsServiceTile code={value} size="medium" navigateTo={noop} />
            ),
          },
          {
            field: 'operation_name',
            headerName: 'Operation',
            minWidth: 200,
            renderCell: ({ value }) => (
              <Typography variant="button">
                {value}
              </Typography>
            ),
          },
          {
            field: 'status_code',
            headerName: 'Status',
            minWidth: 80,
            renderCell: ({ value }) => (
              <Chip
                size="small"
                label={value}
                color={value < 400 ? 'success' : 'error'}
              />
            ),
          },
          { field: 'region', headerName: 'Region', minWidth: 100 },
          { field: 'account_id', headerName: 'Account', minWidth: 100 },
          { field: 'time_start', headerName: 'Start Time', type: 'dateTime', minWidth: 150 },
          { field: 'time_end', headerName: 'End Time', type: 'dateTime', minWidth: 150 },
          { field: 'duration_seconds', headerName: 'Duration', minWidth: 80 },
        ]}
      />
    </Box>
  );
};
