import { ReactElement } from 'react';
import { SendSQSMessageRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export type SQSSendFormProps = {
  loading?: boolean;
  formId: string;
  onSubmit: (data: SendSQSMessageRequest) => unknown;
}

export const SQSSendForm = ({
  loading,
  formId,
  onSubmit,
}: SQSSendFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SQS')}
    formId={formId}
    entry="SendMessageRequest"
    fieldConditions={{
      '^QueueUrl$': false,
    }}
    externalFields={{
      '^MessageBody$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
    }}
    loading={loading}
    onSubmit={(data: SendSQSMessageRequest) => onSubmit({ ...data })}
  />
);
