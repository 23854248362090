import { ReactElement, Suspense } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { buildRoute, useRoutes } from '@localstack/services';
import { Box, Card, Divider } from '@mui/material';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import {
  ProjectEditor,
  LoadingProjectEditor,
  PipelineEditor,
  LoadingPipelineEditor,
  ProjectConfigurationMenu,
  LoadingProjectConfigurationMenu,
} from './containers';

export const UpsertProject = (): ReactElement => {
  const { goto } = useRoutes();

  const { projectId, pipelineId } = useParams<'projectId' | 'pipelineId'>();

  const createProjectRoute = buildRoute(AppRoute.PROJECT_CREATE);
  const updateProjectRoute = buildRoute(AppRoute.PROJECT_UPDATE, { projectId });
  const createPipelineRoute = buildRoute(AppRoute.PIPELINE_CREATE, { projectId });
  const updatePipelineRoute = buildRoute(AppRoute.PIPELINE_UPDATE, { projectId, pipelineId });

  const isCreateProject = !!useMatch(createProjectRoute);
  const isUpdateProject = !!useMatch(updateProjectRoute);
  const isCreatePipeline = !!useMatch(createPipelineRoute);
  const isUpdatePipeline = !!useMatch(updatePipelineRoute);

  return (
    <CustomerLayout
      title={projectId ? 'Edit Project' : 'Create Project'}
    >
      <Card>
        <Box display="flex">
          <Box>
            <Suspense fallback={<LoadingProjectConfigurationMenu />}>
              <ProjectConfigurationMenu projectId={projectId} />
            </Suspense>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flexGrow={1}>
            {(isCreateProject || isUpdateProject) && (
              <Suspense fallback={<LoadingProjectEditor />}>
                <ProjectEditor
                  projectId={projectId}
                  onProjectCreated={(project) => goto(AppRoute.PROJECT_UPDATE, { projectId: project.id })}
                />
              </Suspense>
            )}
            {(isCreatePipeline || isUpdatePipeline) && (
              <Suspense fallback={<LoadingPipelineEditor />}>
                <PipelineEditor
                  projectId={projectId as string}
                  pipelineId={pipelineId}
                  onPipelineDeleted={() => goto(AppRoute.PROJECT_UPDATE, { projectId })}
                  onPipelineCreated={
                    (pipeline) => goto(AppRoute.PIPELINE_UPDATE, { projectId, pipelineId: pipeline.id })
                  }
                />
              </Suspense>
            )}
          </Box>
        </Box>
      </Card>
    </CustomerLayout>
  );
};
