import { ChangeEvent, ReactElement } from 'react';
import useStateRef from 'react-usestateref';
import { CreateLambdaFunctionRequest } from '@localstack/types';
import {
  FormControl, FormLabel, RadioGroup,
  FormControlLabel, Radio, Box, Divider, Grid, MenuItem, TextField, Typography,
  Link,
} from '@mui/material';
import { DEFAULT_KMS_ROUTES, DEFAULT_S3_ROUTES, LAMBDA_HOT_RELOAD_BUCKET_NAME } from '@localstack/constants';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker, ControlledDropzone } from '../../../../form';

const CODE_SOURCE = {
  HOT_RELOAD: 'hot-reloading',
  S3: 's3',
  UPLOAD: 'upload',
};

const PACKAGE_TYPE = {
  ZIP: 'Zip',
  IMAGE: 'Image',
};

export type LambdaFunctionCreateFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateLambdaFunctionRequest) => unknown;
}

export const LambdaFunctionCreateForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: LambdaFunctionCreateFormProps): ReactElement => {
  const [codeSource, setCodeSource, codeSourceRef] = useStateRef(CODE_SOURCE.S3);
  const [selectedPackageType, setSelectedPackageType, selectedPackageTypeRef] = useStateRef(PACKAGE_TYPE.ZIP);
  const [, setHotReloadPath, hotReloadPathRef] = useStateRef('');

  const submitHandler = async (data: CreateLambdaFunctionRequest) => {
    if (selectedPackageTypeRef.current === PACKAGE_TYPE.ZIP && codeSourceRef.current === CODE_SOURCE.UPLOAD) {
      const blobbedZip = data.Code.ZipFile as Blob;
      const buffer = await blobbedZip.arrayBuffer();
      return onSubmit({ ...data, Code: { ...data.Code, ZipFile: buffer } });
    }
    if (selectedPackageTypeRef.current === PACKAGE_TYPE.ZIP && codeSourceRef.current === CODE_SOURCE.HOT_RELOAD) {
      return onSubmit({ ...data, Code: { S3Bucket: LAMBDA_HOT_RELOAD_BUCKET_NAME, S3Key: hotReloadPathRef.current } });
    }
    return onSubmit(data);
  };

  return <>
    <MagicForm
      schema={getSchema('Lambda')}
      formId={formId}
      entry="CreateFunctionRequest"
      loading={loading}
      onSubmit={(data: CreateLambdaFunctionRequest) => submitHandler({ ...data, PackageType: selectedPackageType })}
      fieldConditions={{
        '^Code$': !(selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.HOT_RELOAD),
        '^Code\\.ImageUri': selectedPackageType === PACKAGE_TYPE.IMAGE,
        '^Code\\.ZipFile': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.UPLOAD,
        '^Code\\.S3Bucket': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
        '^Code\\.S3Key': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
        '^Code\\.S3ObjectVersion': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.S3,
        '^EphemeralStorage': false,
        '^PackageType$': false,
      }}
      fieldsOrder={['PackageType']}
      injectedFields={{
        '^Handler$': selectedPackageType === PACKAGE_TYPE.ZIP ? {
          'position': 'after',
          'element':
            <Grid item xs={12}>
              <Box>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  // eslint-disable-next-line
                  onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setCodeSource((e.target as any).value)}>
                  <FormLabel component="legend">Code Source</FormLabel>
                  <RadioGroup row value={codeSource} color="primary">
                    <FormControlLabel value={CODE_SOURCE.S3} control={<Radio color="primary" />} label="S3 Bucket" />
                    <FormControlLabel value={CODE_SOURCE.UPLOAD} control={<Radio color="primary" />}
                      label="Upload ZIP File"
                    />
                    <FormControlLabel value={CODE_SOURCE.HOT_RELOAD}
                      control={<Radio color="primary" />} label={
                        <Box display='flex' alignItems='center' columnGap={1}>
                          <span>Hot Reload</span>
                          {' '}
                          <Typography fontSize={20}>✨</Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>,
        } : undefined,
        '^FunctionName$': {
          position: 'before',
          element:
            <>
              <Grid item md={6} sm={12}>
                <Typography variant='subtitle1'>Package Type</Typography>
                <Typography variant="caption">
                  The type of deployment package. Set to Image for container image and set to Zip for .zip file archive.
                </Typography>
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  label='Package Type'
                  onChange={(e) => setSelectedPackageType(e.target.value)}
                  defaultValue={PACKAGE_TYPE.ZIP}
                >
                  {Object.values(PACKAGE_TYPE).map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" orientation="horizontal" />
              </Grid>
            </>
          ,
        },
        '^Description$': selectedPackageType === PACKAGE_TYPE.ZIP && codeSource === CODE_SOURCE.HOT_RELOAD ? {
          position: 'before',
          element:
            <>
              <Grid item md={6} sm={12}>
                <Typography variant='subtitle1'>Lambda function directory</Typography>
                <Typography variant="caption">
                  Path to the directory with your Lambda functions for hot reloading code changes. <br />
                  <Link
                    href="https://docs.localstack.cloud/user-guide/lambda-tools/hot-reloading/"
                    underline='none' fontWeight='bold' target="_blank"
                  >How does this work?</Link>
                </Typography>
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label='/path/to/lambda-functions-folder'
                  required
                  onChange={(e) => setHotReloadPath(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" orientation="horizontal" />
              </Grid>
            </>,
        } : undefined,
      }}
      defaultValues={{
        Handler: 'handler.handler',
      }}
      externalFields={{
        '^Code.ZipFile$': (control, fieldName) => (
          <ControlledDropzone
            name={fieldName}
            control={control}
            rules={{ required: 'Zip File is required' }}
          />
        ),
        '^Code.S3Bucket$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client='S3' method='listBuckets' arrayKeyName='Buckets' property='Name'
            fieldName={fieldName} entityName='S3 Bucket'
            creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),

        '^KMSKeyArn$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
            fieldName={fieldName} entityName='KMS Key'
            creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
      }}
    />
  </>;
};
