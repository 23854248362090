import { ReactElement } from 'react';
import { EventBridgePipe } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface EventBridgePipesTableProps {
  selectable?: boolean;
  loading?: boolean;
  pipes: EventBridgePipe[];
  onViewPipe?: (model: EventBridgePipe) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const EventBridgePipesTable = ({
  pipes,
  selectable = true,
  loading,
  onViewPipe,
  onSelect,
}: EventBridgePipesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Pipes')}
    entry="Pipe"
    idAttribute="Name"
    rows={pipes}
    selectable={selectable}
    onSelect={onSelect}
    order={['Name', 'Source', 'Target']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewPipe && onViewPipe(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
