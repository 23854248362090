import { ReactElement } from 'react';
import { Chip, useTheme } from '@mui/material';

export interface LevelLabelProps {
  level: string;
}

export const LevelLabel = ({ level }: LevelLabelProps): ReactElement => {
  const theme = useTheme();

  const statusColors = {
    'debug': [theme.palette.success.main, theme.palette.success.contrastText],
    'info': [theme.palette.info.main, theme.palette.info.contrastText],
    'warning': [theme.palette.warning.main, theme.palette.success.contrastText],
    'error': [theme.palette.error.main, theme.palette.success.contrastText],
    'critical': [theme.palette.error.main, theme.palette.success.contrastText],
  };

  const normalizedLevel = level.toLowerCase();

  return (
    <Chip
      label={normalizedLevel}
      size="small"
      sx={{
        backgroundColor: statusColors[normalizedLevel as keyof typeof statusColors][0],
        color: statusColors[normalizedLevel as keyof typeof statusColors][1],
      }}
    />
  );
};
