import { ReactElement } from 'react';
import { Add as AddIcon, Edit as EditIcon, DeleteForever as DeleteIcon } from '@mui/icons-material';
import { useApiGetter, ProjectsService, formatDate, useRoutes, useApiEffect, buildRoute } from '@localstack/services';
import { ConfirmableButton, CopyIconButton, NavLink } from '@localstack/ui';

import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Skeleton, IconButton, Button, CardHeader,
} from '@mui/material';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

export const Projects = (): ReactElement => {
  const { goto } = useRoutes();
  const { data: projects, isLoading } = useApiGetter(ProjectsService, 'listProjects', []);

  const { deleteProject } = useApiEffect(ProjectsService, ['deleteProject'], { revalidate: ['listProjects'] });

  return (
    <CustomerLayout
      title="Projects"
    >
      <Card>
        <CardHeader
          action={
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={() => goto(AppRoute.PROJECT_CREATE)}
            >
              Create Project
            </Button>
          }
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project ID</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell># Pipelines</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                  </TableRow>
                )}
                {(projects ?? []).map((project) => (
                  <TableRow key={project.id}>
                    <TableCell>
                      <NavLink to={buildRoute(AppRoute.PROJECT, { projectId: project.id })}>
                        {project.id}
                      </NavLink>
                      <CopyIconButton
                        textToCopy={project.id ?? ''}
                        tooltipTitle="Copy Project ID"
                        iconFontSize="small"
                      />
                    </TableCell>
                    <TableCell>{project.name}</TableCell>
                    <TableCell>{(project.pipeline_ids ?? []).length}</TableCell>
                    <TableCell>{project.created ? formatDate(project.created) : 'N/A'}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => goto(AppRoute.PROJECT_UPDATE, { projectId: project.id })}
                      >
                        <EditIcon />
                      </IconButton>
                      <ConfirmableButton
                        componentType="IconButton"
                        title="Are you sure you want to delete this project?"
                        text="You will be no longer able to access project pipelines and analytics!"
                        onClick={() => deleteProject(project.id)}
                      >
                        <DeleteIcon />
                      </ConfirmableButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </CustomerLayout>
  );
};
