import { ReactElement } from 'react';
import { Chip, useTheme } from '@mui/material';

export interface StatusLabelProps {
  status: string;
}

export const StatusLabel = ({ status }: StatusLabelProps): ReactElement => {
  const theme = useTheme();

  const statusColors = {
    'started': [theme.palette.info.main, theme.palette.info.contrastText],
    'passed': [theme.palette.success.main, theme.palette.success.contrastText],
    'failed': [theme.palette.error.main, theme.palette.success.contrastText],
    'running': [theme.palette.info.main, theme.palette.success.contrastText],
    'skipped': [theme.palette.warning.main, theme.palette.success.contrastText],
  };

  return (
    <Chip
      label={status}
      size="small"
      sx={{
        backgroundColor: statusColors[status as keyof typeof statusColors][0],
        color: statusColors[status as keyof typeof statusColors][1],
      }}
    />
  );
};
