import { ReactElement, useCallback, useState } from 'react';

import { Grid, Box, Button, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useParams } from 'react-router-dom';

import { LoadingFragment } from '@localstack/ui';
import { CloudPodsService, useApiGetter, useAwsEffect, useAwsGetter } from '@localstack/services';

import { ExternalLink } from '@localstack/constants';

import { BaseLayout } from '~/layouts';

import { retrieveAuthToken } from '~/util/storage';

import { AlertLocalStackRunning, AlertLocalStackNotRunning } from '../Launchpad/components';
import { PodCard } from '../Launchpad/components/PodCard';

const useStyles = makeStyles(() => ({
  gridItem: {
    gap: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const CloudPodsLoad = (): ReactElement => {
  const [logOutput, setLogOutput] = useState('');
  const [isInjected, setIsInjected] = useState(false);
  const classes = useStyles();

  // health endpoint to see if the container is running
  const { data: health, isLoading: isHealthLoading, hasError: hasHealthError } = useAwsGetter(
    'LocalStack',
    'getHealth',
    [],
    { silentErrors: true },
  );

  const { name } = useParams<'name'>() as { name: string };
  const { version } = useParams<'version'>() as { version: string };

  // fetch pod data from platform
  const { data: pod, isLoading: isPodRetrieving } =
  useApiGetter(
    CloudPodsService,
    'getCloudPod',
    [name],
    {
      enable: !!name,
    });

  const podVersion = pod?.versions?.find(
    (auxVersion) => auxVersion.version === Number(version));

  // CloudPodMetadata object to throw into PodCard
  const podMetadata = {
    name,
    localstack_ext_version: podVersion?.localstack_version || '',
    services: podVersion?.services || [],
    size: `${podVersion?.storage_size}` || '0',
    pro: true,
    moto_ext_version: 'dummy',
    url: '',
  };

  // call the LocalStack endpoint that initiate the load logic
  const { loadPod, isLoading: isInjectingPod, hasError: hasInjectError } = useAwsEffect(
    'LocalStack',
    ['loadPod'],
    { silentErrors: true },
  );

  const onInject = useCallback(async () => {
    const authToken = retrieveAuthToken();
    setLogOutput('Loading pod...');
    await loadPod(name, Number(version), authToken?.token);
    setIsInjected(true);
  }, []);

  return (
    <BaseLayout documentTitle={`Load ${name}`} hideNavigation>
      <Grid container
        alignItems='center' direction='column'
        style={{ margin: '3rem 0', flexWrap: 'nowrap' }}
      >

        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography variant="h2">{`Load "${name}" version ${version}`}</Typography>

          {/* Check if LocalStack is running */}
          <Typography variant="h5" color='textSecondary'>
            Download and load this pod into a running LocalStack instance
          </Typography>
          <LoadingFragment loading={isHealthLoading} variant='card' size={1} height={65}>
            {hasHealthError
              ? AlertLocalStackNotRunning
              : AlertLocalStackRunning(health?.version)
            }
          </LoadingFragment>
          {/* Check if LocalStack is running */}

          <LoadingFragment variant='card' size={1} height={315}
            loading={isHealthLoading || isPodRetrieving}
          >
            {podMetadata &&
          <PodCard
            podsMetadata={podMetadata}
            onInject={onInject}
            isInjecting={isInjectingPod}
            isInjected={isInjected && !isInjectingPod}
            injectError={hasInjectError}
            logOutput={logOutput}
          />
            }
            {!hasHealthError}
          </LoadingFragment>
        </Grid>

        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography style={{ marginTop: '5rem' }} variant='h4'>What are LocalStack Cloud Pods?</Typography>
          <Typography color='textSecondary'>
            LocalStack Cloud Pods are a mechanism that allows you to take a snapshot of the state in your
            current LocalStack instance, persist it to a storage backend, and easily share it with your team members.
            With the LocalStack Cloud Pods Launchpad, we further allow you to easily load
            your cloud pods into your LocalStack instance.
          </Typography>
          <Box>
            <Button variant='contained' color='primary'
              href={ExternalLink.DOCS_CLOUD_PODS} target='_blank'
            >
              Learn More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BaseLayout>
  );
};
