import { ReactElement, ReactNode, Suspense } from 'react';
import { Card, CardHeader, CardMedia } from '@mui/material';

import { ErrorBoundary } from './ErrorBoundary';

export interface ProjectStatisticsCardProps {
  title: ReactNode;
  avatar: ReactElement;
  children: ReactElement;
  suspenseFallback: ReactElement;
  errorBoundaryFallback?: ReactElement;
}

export const ProjectStatisticsCard = (
  { title, avatar, suspenseFallback, errorBoundaryFallback, children }: ProjectStatisticsCardProps,
): ReactElement => (
  <Card>
    <CardHeader title={title} avatar={avatar} />
    <CardMedia>
      <ErrorBoundary fallback={errorBoundaryFallback}>
        <Suspense fallback={suspenseFallback}>
          {children}
        </Suspense>
      </ErrorBoundary>
    </CardMedia>
  </Card>
);
