import { Fragment, ReactElement } from 'react';
import { useTheme } from '@mui/material';

import { EnrichedStepWithLevel } from '../types';

export interface DataGridLevelDotProps {
  step: EnrichedStepWithLevel;
}

export const DataGridLevelDot = ({ step }: DataGridLevelDotProps): ReactElement => {
  //       a
  //     -----    a = horizontal margin between neighboring dots
  //      \  |    b = vertical distance between dots (row height if centered)
  //   c   \ | b  c = sqrt(a^2 + b^2)
  //        \|    alpha = c <. a = arccos(a/c) * 180/pi

  const theme = useTheme();

  const a = 10;
  const b = 36;
  const c = Math.sqrt(a ** 2 + b ** 2);
  const lineWidth = 2;
  const dotSize = 10;

  const alpha = 90 + Math.acos(a/c) * 180 / Math.PI;
  const parentalChildStatesWithoutThisStep = step.parentalLastChild.slice(0, -1);

  const color = step.state === 'failed' ? theme.palette.error.contrastText : theme.palette.primary.main;

  const bottomRightLink = (
    <div
      style={{
        transform: `rotate(${alpha}deg)`,
        width: `${lineWidth}px`,
        backgroundColor: color,
        height: `${c/2}px`,
        bottom: `-${c/2 - dotSize/2}px`,
        right: `${lineWidth / 2}px`,
        position: 'absolute',
      }}
    />
  );

  const topLeftLink = (
    <div
      style={{
        transform: `rotate(${alpha}deg)`,
        width: `${lineWidth}px`,
        backgroundColor: color,
        height: `${c/2}px`,
        top: `-${c/2 - dotSize/2}px`,
        right: `${dotSize/2 + lineWidth/2}px`,
        position: 'absolute',
      }}
    />
  );

  const bottomLink = (
    <div
      style={{
        width: `${lineWidth}px`,
        backgroundColor: color,
        height: `${c/2}px`,
        bottom: `-${c/2 - dotSize/2}px`,
        right: `${dotSize/2 - lineWidth/2}px`,
        position: 'absolute',
      }}
    />
  );

  const topLink = (
    <div
      style={{
        width: `${lineWidth}px`,
        backgroundColor: color,
        height: `${c/2}px`,
        top: `-${c/2 - dotSize/2}px`,
        right: `${dotSize/2 - lineWidth/2}px`,
        position: 'absolute',
      }}
    />
  );

  const VerticalLink = ({ level }: { level: number }) => (
    <div
      style={{
        width: `${lineWidth}px`,
        backgroundColor: color,
        height: `${c * 2}px`, // compensate DataGrid's centering
        top: `-${c/2}px`,
        left: `${a * level + dotSize/2 - lineWidth/2}px`,
        position: 'absolute',
      }}
    />
  );

  return (
    <div style={{ position: 'relative' }}>
      {(step.level > 0 && step.isFirstChild) && (
        <>{topLeftLink}</>
      )}
      {step.children.length > 0 && (
        <>{bottomRightLink}</>
      )}
      {!step.isFirstChild && (
        <>{topLink}</>
      )}
      {(step.level > 0 && !step.isLastChild) && (
        <>{bottomLink}</>
      )}
      {parentalChildStatesWithoutThisStep.map((isParentLastChild, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>{!isParentLastChild && <VerticalLink level={i} />}</Fragment>
      ))}
      <div
        style={{
          width: `${dotSize}px`,
          height: `${dotSize}px`,
          borderRadius: '50%',
          backgroundColor: color,
          marginLeft: `${step.level * a}px`,
        }}
      />
    </div>
  );
};
